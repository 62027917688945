import {Suspense, useState} from "react";
import {Col, Container, Form, Row, Spinner, Table} from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import CustomModal from "../../../components/common/CustomModal";
import Btn from "../../../components/admin/Btn";
import {useQuery} from "@tanstack/react-query";
import {apiClient} from "../../../reactQuery/api";
import {useEffect} from "react"
import NewAdminPagination from "../../../components/admin/NewPagination";
import {Link} from "react-router-dom";

export default function CouponLogs() {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const [modal, setModal] = useState({ showModal: false });
  const [couponId, setCouponId] = useState(undefined);
  const [couponLogs, setCouponLogs] = useState([]);

  const { data: defaultUsersQueryData, refetch } = useQuery(
      ["coupon-logs", page, couponId],
      () =>
          apiClient({
            url: "/coupon-logs",
            params: {
              page,
              size,
              ...(couponId && { couponId }),
            },
          }),
      {
        onSuccess: (res) => {},
        onError: (err) => alert(err.message),
        enabled: false,
        suspense: false,
        keepPreviousData: true,
        refetchOnMount: "always",
        refetchOnReconnect: "always",
        refetchOnWindowFocus: "always",
      }
  );

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (defaultUsersQueryData?.data?.empty) {
      setPage(0);
    }

    if (defaultUsersQueryData?.data?.totalPages) {
      setTotalPages(defaultUsersQueryData.data.totalPages);
    } else {
      setTotalPages(1);
    }

    if (defaultUsersQueryData?.data?.totalElements) {
      setTotalElements(defaultUsersQueryData.data.totalElements);
    } else {
      setTotalElements(0);
    }

    if (defaultUsersQueryData?.data?.content?.length > 0) {
      setCouponLogs(defaultUsersQueryData.data.content);
    } else {
      setCouponLogs([]);
    }
  }, [defaultUsersQueryData]);
  const onClickSearch = () => {
    setPage(0);
    refetch();
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">교환권 변경내역</h1>
              </Col>
            </Row>
            <Row className="py-1 align-items-center">
              <Col
                  xs={"auto"}
                  className="px-3 py-1 ms-2 rounded-5 text-white"
                  style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElements}</h5>
              </Col>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "620px" }} className="mx-auto">
                <Row className="py-1 align-items-center">
                  <Col xs={12} className="mb-3">
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">교환권 id</h5>
                      </Col>
                      <Col>
                        <Form.Control
                            onChange={(e)=> setCouponId(e.target.value)}
                            type="text"
                            id="couponId"
                            aria-describedby="passwordHelpBlock"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Col xs={12} lg={"auto"}>
                  <Row className="align-items-center justify-content-center filter-btnbox">
                    <Col xs={"auto"}>
                      <Btn
                          type="API"
                          btntext="조회 하기"
                          onClick={() => onClickSearch()}
                      />
                    </Col>
                  </Row>
                </Col>
              </div>
            </div>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr>
                    <th>가맹점명</th>
                    <th>상태</th>
                    <th>교환권id</th>
                    {/*<th>구매자 이름</th>*/}
                    <th>교환권 번호</th>
                    <th>액면가</th>
                    <th>잔액</th>
                    {/*<th>결제 가격</th>*/}
                    <th>상품명</th>
                    <th>변경시각</th>
                  </tr>
                </thead>
                <tbody style={{ position: "relative" }}>
                {couponLogs.map((item, index) => (
                    <tr key={item.logId} className="board-row-except">
                      <td>{item.storeName}</td>
                      <td>{item.logType.description}</td>
                      {/*<td>{buyerName}</td>*/}
                      <td>{item.couponId}</td>
                      <td>
                        <Link to={`/admin/coupon/coupons/${item.couponId}`}>{item.couponNumber}</Link>
                      </td>
                      <td>{item.facePrice}</td>
                      <td>{item.balance}</td>
                      {/*<td>{price}</td>*/}
                      <td>{item.productName}</td>
                      <td>{item.createdAt?.replace("T", " ").substring(0, 16)}</td>
                    </tr>
                ))}
                </tbody>
              </Table>
              <NewAdminPagination
                  // totalPages={pageable.totalPages}
                  pageNationSize={10}
                  // page={pageable.page * 1}
                  // handlePage={movePage}
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                  // request={request}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {modal.showModal && (
        <CustomModal
          callbackOfOk={modal.callbackOfOk}
          textOfHeader={modal.textOfHeader}
          textOfBody={modal.textOfBody}
          setModal={setModal}
        />
      )}
    </>
  );
}
