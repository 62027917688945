import { Link } from "react-router-dom";
import usePayments from "../../hooks/payment/usePayments";
import {Button} from "react-bootstrap";

export default function PaymentBoard({
  page,
  showingDate,
  setTotalPage,
  setTotalElements,
}) {
  const { payments } = usePayments({
    showingDate,
    page,
    setTotalPage,
    setTotalElements,
  });

  const mapedPayments = payments.content.map((payment) => {
    const {
      paymentId,
      orderNumber,
      payType,
      status,
      buyerName,
      priceInfo,
      completedAt,
        canceledAt,
        productName,
        storeName,

    } = payment;

      function renderETCByType(statusCode, productPaymentId){
          if(statusCode==1){
              return <td><Button onClick={ () => { handleCouponCancelClick(productPaymentId)}} variant="outline-secondary" >
                  취소하기
              </Button></td>
          }else{
              return <td style={{ minWidth: "5rem" }}></td>
          }

      }
      const handleCouponCancelClick = () => {

          const _reason = prompt("취소 사유를 입력해주세요.");
          if(_reason){
            cancelCouponMutate({ reason });
          }

      };

    return (
      <tr key={paymentId} className="board-row-except">
        <td>
          {/* <Link to={`/admin/payment/payments/${orderNumber}`}> */}
          {orderNumber}
          {/* </Link> */}
        </td>
        <td>{productName}</td>
        <td>{storeName}</td>
        <td>{payType?.description}</td>
        <td>{status.description}</td>
        <td>{buyerName}</td>
        <td>{priceInfo.price}</td>
        <td>{completedAt?.replace("T", " ").substring(0, 16)}</td>
        <td>{canceledAt?.replace("T", " ").substring(0, 16)}</td>

      </tr>
    );
  });

  return mapedPayments;
}
