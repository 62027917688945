import React, { Suspense, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { apiClient } from "../../reactQuery/api";
import { useQuery } from "@tanstack/react-query";
import SimplePagination from "../admin/SimplePagination";
import ProductList from "./ProductList";

const RegisterModal = ({ show, closeModal, onClickSave = () => {} }) => {
  const [currentTab, setCurrentTab] = useState("category");
  const [category, setCategory] = useState("");
  const [searchKeyword, setSearchKeyword] = useState({
    type: "",
    keyword: "",
  });
  const [tmpSearchKeyword, setTmpSearchKeyword] = useState({
    type: "storeName",
    keyword: "",
  });
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [pageInfo, setPageInfo] = useState({ totalElements: 0, totalPages: 0 });
  const categoryRef = useRef();
  const [selectedTarget, setSelectedTarget] = useState([]);

  const onClickCheckAll = () => {
    if (selectedTarget.length < products.length) {
      setSelectedTarget(products);
    } else {
      setSelectedTarget([]);
    }
  };

  useQuery(
    ["getProducts", searchKeyword.type, searchKeyword.keyword, page],
    async () => {
      const res = await apiClient({
        url: `/products`,
        method: "get",
        params: {
          page,
          size: 5,
          ...(searchKeyword.type === "storeName" && {
            storeName: searchKeyword.keyword,
          }),
        },
      });
      return res.data;
    },
    {
      onSuccess: (res) => {
        if (!res) return;
        const { content, totalElements, totalPages } = res;
        setProducts(content);
        setPageInfo({ totalElements, totalPages });
      },
      onError: (err) => console.log(err),
      enabled: Boolean(searchKeyword.type) && Boolean(searchKeyword.keyword),
    }
  );

  const onClickSearchProducts = () => {
    setSearchKeyword(tmpSearchKeyword);
  };

  const tabs = [
    {
      value: "category",
      title: "카테고리 생성",
      component: (
        <Col>
          <Form.Group className="Formbox">
            <Form.Label>카테고리명 입력</Form.Label>
            <Form.Control
              placeholder="카테고리 이름을 입력해주세요."
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
              ref={categoryRef}
            />
          </Form.Group>
        </Col>
      ),
    },
    {
      value: "product",
      title: "상품 등록",
      component: (
        <Col>
          <Form.Group
            className="Formbox"
            style={{ display: "flex", gap: "0.4rem" }}
          >
            <Form.Select
              style={{ flex: 0.16 }}
              onChange={(e) =>
                setTmpSearchKeyword((prev) => ({
                  ...prev,
                  type: e.target.value,
                }))
              }
              value={tmpSearchKeyword.type}
            >
              <option value={"storeName"}>가맹점 명</option>
              <option value={"productName"}>상품 명</option>
            </Form.Select>
            <Form.Control
              placeholder={`${
                tmpSearchKeyword.type === "storeName" ? "가맹점명" : "상품명"
              }을 입력해주세요.`}
              value={tmpSearchKeyword.keyword}
              onChange={(e) =>
                setTmpSearchKeyword((prev) => ({
                  ...prev,
                  keyword: e.target.value,
                }))
              }
              required
              style={{ flex: 0.69 }}
            />
            <Button
              variant="primary"
              onClick={onClickSearchProducts}
              style={{ flex: 0.15 }}
            >
              조회
            </Button>
          </Form.Group>
          <div className="TableStyle mt-4">
            <Table
              style={{ verticalAlign: "middle" }}
              className="mb-4 text-center"
            >
              <thead>
                <tr>
                  <th>
                    <Form.Check
                      type="checkbox"
                      checked={
                        Boolean(products.length) &&
                        selectedTarget.length === products.length
                      }
                      onChange={onClickCheckAll}
                    />
                  </th>
                  <th>가맹점명</th>
                  <th>상품명</th>
                  <th>액면가(원가)</th>
                  <th>판매가</th>
                </tr>
              </thead>
              <tbody style={{ position: "relative" }}>
                <Suspense
                  fallback={
                    <tr style={{ height: "250px" }}>
                      <Spinner
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          marginLeft: "-18px",
                        }}
                        animation="border"
                      />
                    </tr>
                  }
                >
                  {products?.length > 0 ? (
                    <ProductList
                      data={products}
                      onClickCheck={(row) => {
                        if (
                          selectedTarget.findIndex(
                            (target) => target.productId === row.productId
                          ) !== -1
                        ) {
                          setSelectedTarget((prev) =>
                            prev.filter(
                              (target) => target.productId !== row.productId
                            )
                          );
                        } else {
                          setSelectedTarget((prev) => [...prev, row]);
                        }
                      }}
                      checked={(id) =>
                        selectedTarget.findIndex(
                          (target) => target.productId === id
                        ) !== -1
                      }
                    />
                  ) : (
                    <tr>
                      <td colSpan={7} style={{ padding: "4rem" }}>
                        일치하는 상품이 없습니다.
                      </td>
                    </tr>
                  )}
                </Suspense>
              </tbody>
            </Table>
            <SimplePagination
              currentPage={page}
              totalPage={pageInfo.totalPages}
              setPage={setPage}
            />
          </div>
        </Col>
      ),
    },
  ];

  return (
    <Modal show={show} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>카테고리 등록</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs activeKey={currentTab} onSelect={(key) => setCurrentTab(key)}>
          {tabs.map((tab, index) => (
            <Tab eventKey={tab.value} key={index} title={tab.title}>
              {tab.component}
            </Tab>
          ))}
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            if (!category) {
              alert("생성할 카테고리를 입력해주세요.");
              setCurrentTab("category");
              return categoryRef.current.focus();
            }
            onClickSave({
              categoryName: category,
              products: selectedTarget,
            });
          }}
          style={{ margin: "0 auto" }}
        >
          저장
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RegisterModal;
