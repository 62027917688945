import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";

const TableRow = ({
  onClickManage = () => {},
  serverId,
  serverName,
  serverDescription,
  store,
  tableType,
  onClickCheck = () => {},
  checked,
}) => {
  return (
    <tr className="board-row-except">
      {tableType === "delete" && (
        <td>
          <Form.Check
            type="checkbox"
            checked={checked}
            onChange={onClickCheck}
          />
        </td>
      )}
      <td>{serverId ?? "-"}</td>
      <td>{serverName ?? "-"}</td>
      <td>{serverDescription ?? "-"}</td>
      <td>{store?.storeName}</td>
      {tableType === "default" && (
        <td>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              e.stopPropagation();
              onClickManage();
            }}
            style={{ wordBreak: "keep-all" }}
          >
            관리
          </button>
        </td>
      )}
    </tr>
  );
};

const DoorSystemBoard = ({
  data,
  onClickManage = () => {},
  tableType,
  onClickCheck = () => {},
  checked,
}) => {
  return data.map((row) => (
    <TableRow
      key={row.serverId}
      {...row}
      onClickManage={() => onClickManage(row.serverId)}
      tableType={tableType}
      onClickCheck={() => onClickCheck(row.serverId)}
      checked={checked(row.serverId)}
    />
  ));
};

export default DoorSystemBoard;
