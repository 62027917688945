import { Link, useParams, useSearchParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { PrintConsole } from "../../../function/common";
import { Alert, Col, Container, Form, Row, Table } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import adminApi from "../../../function/apiConfig";
import Btn from "../../../components/admin/Btn";
import CustomModal from "../../../components/common/CustomModal";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../../reactQuery/api";

export default function StoreDetail() {
  const initData = {
    id: "",
    status: { description: "" },
    acceptStatus: { description: "" },
    ownerName: "",
    storePhoneNumber: "",
    storeName: "",
    businessName: "",
    createdAt: "",
    taxEmail: "",
    domain: "",
    storeAddress: {
      zipCode: "",
      address: "",
      addressDetail: "",
    },
    businessAddress: {
      zipCode: "",
      address: "",
      addressDetail: "",
    },
    city: "",
    businessClassType: "",
    businessClass: "",
    businessHours: "",
    holiday: "",
    originLabelling: "",
    grade: "",
    reviewCount: "",
    latitude: "",
    longitude: "",
    logoUrl: "",
    businessNumber: "",
    hasBusinessRegistration: "",
    hasBankCopy: "",
    storeAcceptHistories: [
      {
        id: "",
        status: "",
        admin_memo: "",
        createdAt: "",
      },
    ],
    settleAccount: {
      settleAccountNumber: "",
      settleAccountOwner: "",
      settleBankCode: { korName: "" },
    },
    storeSettlementManagement: {
      settleManagerEmail: "",
      settleManagerName: "",
      settleManagerPhoneNumber: "",
    },
  };
  const [modal, setModal] = useState({ showModal: false });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initData);

  const [Params] = useSearchParams();
  var Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push({ [entry[0]]: entry[1] });
  }
  const { id } = useParams();
  function getStore() {
    adminApi({
      url: "/stores/" + id,
      method: "get",
      customOption: { errorPopup: false },
    })
      .then(function (response) {
        console.log(response);
        setData(response);
      })
      .catch(function (error) {});
  }

  function openAcceptStoreModal() {
    const modalContent = {
      callbackOfOk: acceptStore,
      textOfBody: "해당 가맹점을 승인하시겠습니까?",
      showModal: true,
    };
    setModal(modalContent);
  }

  function acceptStore() {
    adminApi({
      url: "/stores/" + id + "/examine",
      method: "post",
      data: { acceptStatus: "ACCEPTED" },
    })
      .then(function (response) {
        alert("승인 완료하였습니다.");
        getStore();
      })
      .catch(function (error) {});
  }

  function openRefuseStoresModal() {
    const modalContent = {
      callbackOfOk: refuseStores,
      textOfBody: "해당 가맹점을 거절하시겠습니까?",
      showModal: true,
    };
    setModal(modalContent);
  }

  function refuseStores() {
    let reason = prompt("거절 사유를 입력해 주세요");
    if (!reason) {
      return;
    }

    adminApi({
      url: "/stores/" + id + "/examine",
      method: "post",
      data: { acceptStatus: "REFUSED", adminMemo: reason },
    })
      .then(function (response) {
        alert("거절 완료하였습니다.");
        getStore();
      })
      .catch(function (error) {});
  }
  function openDownloadModal(type) {
    const modalContent = {
      callbackOfOk: "",
      textOfBody: "",
      showModal: true,
    };
    if (type === "businessRegistration") {
      Object.assign(modalContent, {
        callbackOfOk: downLoadBusinessRegistration,
        textOfBody: "사업자 등록증을 다운로드 하시겠습니까?",
      });
    } else if (type === "bankCopy") {
      Object.assign(modalContent, {
        callbackOfOk: downLoadBankCopy,
        textOfBody: "통장 사본을 다운로드 하시겠습니까?",
      });
    } else {
      alert("타입 연동을 해주세요");
      return;
    }
    setModal(modalContent);
  }

  function downLoadBusinessRegistration() {
    if (!data.hasBusinessRegistration) {
      alert("업로드된 사업자 등록증이 없습니다.");
      return;
    }
    adminApi({
      url: "/stores/" + id + "/business-registration",
      method: "get",
      customOption: { fileDownLoad: true },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const blob = new Blob([response.data]);
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.style.display = "none";
        link.download = getFileName(response);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(function (error) {});
  }

  function downLoadBankCopy() {
    if (!data.hasBankCopy) {
      alert("업로드된 통장사본이없습니다.");
      return;
    }
    adminApi({
      url: "/stores/" + id + "/bank-copy",
      method: "get",
      customOption: { fileDownLoad: true },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        const blob = new Blob([response.data]);
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.style.display = "none";
        link.download = getFileName(response);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(function (error) {});
  }
  function getFileName(response) {
    const disposition = response.headers["content-disposition"];

    const fileName = decodeURI(
      disposition
        .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
        .replace(/['"]/g, "")
    );
    return fileName;
  }

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    //함수 호출
    getStore();

    //데이터 로딩 완료
    setLoading(false);
  }, []);

  const [businessRegistration, setBusinessRegistration] = useState({
    file: null,
    url: "",
  });
  const [bankCopy, setBankCopy] = useState({ file: null, url: "" });

  const onClickUpload = async ({ target: { files } }) => {
    const file = files[0];
    let url = "";
    if (files) {
      url = await getFileUrl(file);
    }
    return { url, file };
  };

  const getFileUrl = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const updateBusinessRegistrationMutation = useMutation(
    async ({ storeId, businessRegistration }) => {
      const formData = new FormData();
      if (businessRegistration) {
        formData.append("businessRegistration", businessRegistration);
      } else {
        return;
      }
      return apiClient({
        url: `/stores/${storeId}/business-registration`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => res.data);
    },
    {
      onSuccess: (res) => {
        setBusinessRegistration({ file: null, url: "" });
        alert("사업자등록증 수정 완료!");
      },
    }
  );
  const onClickUpdateBusinessRegistration = () => {
    if (updateBusinessRegistrationMutation.isLoading) return;
    const confirm = window.confirm("정말 수정하시겠습니까?");
    if (!confirm) {
      alert("사업자등록증 수정이 취소되었습니다.");
    }
    updateBusinessRegistrationMutation.mutate({
      storeId: id,
      businessRegistration: businessRegistration?.file ?? null,
    });
  };

  const updateBankCopyMutation = useMutation(
    async ({ storeId, bankCopy }) => {
      const formData = new FormData();
      if (bankCopy) {
        formData.append("bankCopy", bankCopy);
      } else {
        return;
      }
      return apiClient({
        url: `/stores/${storeId}/bank-copy`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => res.data);
    },
    {
      onSuccess: (res) => {
        setBankCopy({ file: null, url: "" });
        alert("통장사본 수정 완료!");
      },
    }
  );
  const onClickUpdateBankCopy = () => {
    if (updateBankCopyMutation.isLoading) return;
    const confirm = window.confirm("정말 수정하시겠습니까?");
    if (!confirm) {
      alert("통장사본 수정이 취소되었습니다.");
    }
    updateBankCopyMutation.mutate({
      storeId: id,
      bankCopy: bankCopy?.file ?? null,
    });
  };

  if (loading) return <></>;

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">가맹점 상세정보</h1>
              </Col>
            </Row>

            <div className="ContentBox Formbox mt-4">
              <Row className="p-1">
                {/* 2개형 */}
                <Col md={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storeName">
                    <Form.Label>
                      매장명 <span className="PointText"> * </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="매장명을 입력하세요."
                      value={data.storeName}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="status">
                    <Form.Label>
                      운영상태 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="운영상태를 입력하세요."
                      value={data.status.description}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      승인상태 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="승인상태를 입력하세요."
                      value={data.acceptStatus.description}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="p-1">
                {/* 3개형 */}
                <Col md={{ span: 6 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storePhoneNumber">
                    <Form.Label>
                      {" "}
                      매장 전화번호 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="매장 전화번호를 입력하세요."
                      value={data.storePhoneNumber}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 6 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storeName">
                    <Form.Label>
                      {" "}
                      매장 등록일 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="매장 등록일을 입력하세요."
                      value={data.createdAt.replace("T", " ").substring(0, 16)}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="p-1">
                {/* 3개형 */}
                <Col md={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      {" "}
                      가맹점 우편번호 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="가맹점 우편번호를 입력하세요."
                      value={data.storeAddress.zipCode}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      {" "}
                      가맹점 주소 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="가맹점 주소를 입력하세요."
                      value={data.storeAddress.address}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storePhoneNumber">
                    <Form.Label>
                      {" "}
                      가맹점 상세 주소 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="가맹점 상세 주소를 입력하세요."
                      value={data.storeAddress.addressDetail}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="p-1">
                {/* 3개형 */}
                <Col md={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      {" "}
                      사업자등록번호 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="사업자등록번호를 입력하세요."
                      value={data.businessNumber}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      {" "}
                      대표자명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="대표자명을 입력하세요."
                      value={data.ownerName}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      {" "}
                      세금계산서 수신 이메일{" "}
                      <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="세금계산서 수신 이메일을 입력하세요."
                      value={data.taxEmail || ""}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="p-1">
                {/* 3개형 */}
                <Col md={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      {" "}
                      사업자등록상 우편번호 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="사업자등록상 우편번호를 입력하세요."
                      value={
                        (data.businessAddress &&
                          data.businessAddress.zipCode) ||
                        ""
                      }
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      {" "}
                      사업자등록상 주소 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="사업자등록상 주소를 입력하세요."
                      value={
                        (data.businessAddress &&
                          data.businessAddress.address) ||
                        ""
                      }
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storePhoneNumber">
                    <Form.Label>
                      {" "}
                      사업자등록상 상세 주소{" "}
                      <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="사업자등록상 상세 주소를 입력하세요."
                      value={
                        (data.businessAddress &&
                          data.businessAddress.addressDetail) ||
                        ""
                      }
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="p-1">
                {/* 3개형 */}
                <Col md={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      {" "}
                      정산 은행 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="정산 은행을 입력하세요."
                      value={
                        (data.settleAccount &&
                          data.settleAccount.settleBankCode.korName) ||
                        ""
                      }
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      {" "}
                      정산 계좌주 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="정산 계좌주를 입력하세요."
                      value={
                        (data.settleAccount &&
                          data.settleAccount.settleAccountOwner) ||
                        ""
                      }
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col mdvvvv={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="storePhoneNumber">
                    <Form.Label>
                      {" "}
                      정산 계좌번호 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="정산 계좌번호를 입력하세요."
                      value={
                        (data.settleAccount &&
                          data.settleAccount.settleAccountNumber) ||
                        ""
                      }
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="p-1">
                {/* 3개형 */}
                <Col md={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="acceptStatus">
                    <Form.Label>
                      {" "}
                      정산 담당자 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={data.storeSettlementManagement?.settleManagerName}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="ownerName">
                    <Form.Label>
                      {" "}
                      정산 담당자 연락처 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={
                        data.storeSettlementManagement?.settleManagerPhoneNumber
                      }
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col mdvvvv={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="storePhoneNumber">
                    <Form.Label>
                      {" "}
                      정산 담당자 이메일 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={data.storeSettlementManagement?.settleManagerEmail}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="p-1 mb-5">
                {/* 2개형 */}
                <Col md={{ span: 6 }} className="pb-4 btn-py">
                  <Btn
                    type="API"
                    btntext="사업자등록증 다운로드"
                    onClick={() => openDownloadModal("businessRegistration")}
                  />
                </Col>
                <Col md={{ span: 6 }} className="pb-4 btn-py">
                  <Btn
                    type="API"
                    btntext="통장사본 다운로드"
                    onClick={() => openDownloadModal("bankCopy")}
                  />
                </Col>
              </Row>
              <Row className="p-1 mb-5">
                <Form.Group className="xs-12" controlId="status">
                  <Form.Label>
                    사업자등록증 수정 <span className="PointText">*</span>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      onClickUpload(e).then(({ url, file }) =>
                        setBusinessRegistration({ url, file })
                      )
                    }
                  />
                </Form.Group>
                <Btn
                  disabled={businessRegistration?.file === null}
                  type="API"
                  btntext="사업자등록증 수정"
                  onClick={() => onClickUpdateBusinessRegistration()}
                />
              </Row>
              <Row className="p-1 mb-5">
                <Form.Group className="xs-12" controlId="status">
                  <Form.Label>
                    통장사본 수정 <span className="PointText">*</span>
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      onClickUpload(e).then(({ url, file }) =>
                        setBankCopy({ url, file })
                      )
                    }
                  />
                </Form.Group>
                <Btn
                  disabled={bankCopy?.file === null}
                  type="API"
                  btntext="통장사본 수정"
                  onClick={() => onClickUpdateBankCopy()}
                />
              </Row>

              {data.acceptStatus.description === "승인요청" && (
                <Row className="p-1">
                  {/* 2개형 */}
                  {/*<Col md={{ span: 6 }} className="pb-4">*/}
                  {/*  <Btn*/}
                  {/*    type="API"*/}
                  {/*    btntext="승인하기"*/}
                  {/*    onClick={() => openAcceptStoreModal()}*/}
                  {/*  />*/}
                  {/*</Col>*/}
                  {/*<Col md={{ span: 6 }} className="pb-4">*/}
                  {/*  <Btn*/}
                  {/*    type="API"*/}
                  {/*    btntext="거절하기"*/}
                  {/*    onClick={() => openRefuseStoresModal()}*/}
                  {/*  />*/}
                  {/*</Col>*/}
                </Row>
              )}
              <Row>
                <Col>
                  <h4 className="fw-bold">승인 내역</h4>
                </Col>
              </Row>
              <Row>
                <Col className="TableStyle mt-4  mb-5 text-center">
                  <Table style={{ verticalAlign: "middle" }} className="mb-4">
                    <thead>
                      <tr>
                        <th>구분</th>
                        <th>어드민 메모</th>
                        <th>일자</th>
                        <th>비고</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* data 값으로 루프 */}
                      {data.storeAcceptHistories.map((item, index) => (
                        <tr key={item.id}>
                          <td>{item.status.description}</td>
                          <td>{item.admin_memo}</td>
                          <td>
                            {item.createdAt.replace("T", " ").substring(0, 16)}
                          </td>
                          <td style={{ minWidth: "5rem" }}></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 className="fw-bold">직원 리스트</h4>
                </Col>
              </Row>
              <Row>
                <Col className="TableStyle mt-4 text-center">
                  <Table style={{ verticalAlign: "middle" }} className="mb-4">
                    <thead>
                      <tr>
                        <th>로그인 아이디</th>
                        <th>이름</th>
                        <th>전화번호</th>
                        <th>이메일</th>
                        <th>상태</th>
                        <th>가입일</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>test</td>
                        <td>테스트</td>
                        <td>000-0000-0000</td>
                        <td>test@test.com</td>
                        <td>회원가입</td>
                        <td>2022-00-00 12:00</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      {modal.showModal && (
        <CustomModal
          callbackOfOk={modal.callbackOfOk}
          textOfHeader={modal.textOfHeader}
          textOfBody={modal.textOfBody}
          setModal={setModal}
        />
      )}
    </>
  );
}
