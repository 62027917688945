import { Spinner } from "react-bootstrap";

export default function CustomSpinner({ type }) {
  if (type === "backdrop") {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          height: "100%",
          opacity: 0.5,
          backgroundColor: "black",
        }}
      >
        <Spinner
          animation="border"
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            marginLeft: "-18px",
          }}
        />
      </div>
    );
  }

  return (
    <Spinner
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        marginLeft: "-18px",
      }}
      animation="border"
    />
  );
}
