import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import { useNavigate } from "react-router-dom";
import KioskInfoForm from "../../../components/kiosk/KioskInfoForm";

const CreateKiosk = () => {
  const navigate = useNavigate();
  const [managerIdType, setManagerIdType] = useState("NEW");
  const [managerData, setManagerData] = useState({
    id: "",
    password: "",
    rePassword: "",
  });
  const [data, setData] = useState({
    kioskId: "",
    installLocation: "",
    installDate: "",
    installManager: "",
    kioskStatus: "CLOSE",
  });

  const onChangeData = (value, target) => {
    setData((prev) => ({ ...prev, [target]: value }));
  };

  const onChangeManagerData = (value, target) => {
    setManagerData((prev) => ({ ...prev, [target]: value }));
  };

  const onClickSave = () => {
    if (!managerData.id) return alert("관리자 id를 입력해주세요.");
    if (managerIdType === "NEW") {
      if (!managerData.password)
        return alert("관리자 비밀번호를 입력해주세요.");
      if (!(managerData.rePassword === managerData.password))
        return alert("관리자 비밀번호를 확인해주세요.");
    }
    if (!data.kioskId) return alert("키오스크 Mac id를 입력해주세요.");
    if (!data.installLocation) return alert("설치 위치를 입력해주세요.");
    if (!data.installDate) return alert("설치일을 입력해주세요.");
    if (!data.installManager) return alert("설치 담당자를 입력해주세요.");
    console.log(managerIdType, managerData, data);
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={2} className="LeftBg px-0">
          <LeftMenu />
        </Col>
        <Col className="RightBg px-5">
          <Row className="pb-3 pt-4 row">
            <Col>
              <h1 className="fw-bold">통합 키오스크 관리</h1>
            </Col>
          </Row>
          <Row className="pb-3 pt-4 row">
            <Col>
              <h2 className="fw-bold">관리 추가</h2>
            </Col>
          </Row>
          <h4 className="fw-medium">01. 관리 계정</h4>
          <Col
            style={{
              background: "var(--bs-gray-200)",
              padding: "1rem 2rem",
              marginBottom: "1.6rem",
              borderRadius: "1rem",
            }}
          >
            <Col
              xs={8}
              style={{
                gap: "1rem",
                flexDirection: "column",
                display: "flex",
                marginBottom: "1rem",
              }}
            >
              <Row style={{ display: "flex" }}>
                <Form.Check
                  style={{
                    width: "fit-content",
                  }}
                  type="radio"
                >
                  <Form.Check.Input
                    type="radio"
                    style={{
                      fontSize: "14px",
                      marginRight: "8px",
                    }}
                    value={"NEW"}
                    checked={managerIdType === "NEW"}
                    onChange={() => setManagerIdType("NEW")}
                  />
                  <Form.Label>신규 생성</Form.Label>
                </Form.Check>
                <Form.Check
                  style={{
                    width: "fit-content",
                  }}
                  type="radio"
                >
                  <Form.Check.Input
                    type="radio"
                    style={{
                      fontSize: "14px",
                      marginRight: "8px",
                    }}
                    value={"EXIST"}
                    checked={managerIdType === "EXIST"}
                    onChange={() => setManagerIdType("EXIST")}
                  />
                  <Form.Label>기존 계정 사용</Form.Label>
                </Form.Check>
              </Row>
              <Form.Group className="Formbox">
                <Form.Label>관리자 id</Form.Label>
                <Form.Control
                  placeholder="관리자 id를 입력해주세요."
                  value={managerData.id}
                  onChange={(e) => onChangeManagerData(e.target.value, "id")}
                  required
                />
              </Form.Group>
              {managerIdType === "NEW" && (
                <Row style={{ display: "flex" }}>
                  <Col>
                    <Form.Group className="Formbox">
                      <Form.Label>비밀번호</Form.Label>
                      <Form.Control
                        placeholder="비밀번호를 입력해주세요."
                        value={managerData.password}
                        onChange={(e) =>
                          onChangeManagerData(e.target.value, "password")
                        }
                        required
                        type="password"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="Formbox">
                      <Form.Label>비밀번호 확인</Form.Label>
                      <Form.Control
                        placeholder="비밀번호를 한번 더 입력해주세요."
                        value={managerData.rePassword}
                        onChange={(e) =>
                          onChangeManagerData(e.target.value, "rePassword")
                        }
                        required
                        type="password"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Col>
          </Col>
          <h4 className="fw-medium">01. 키오스크</h4>
          <Col
            style={{
              background: "var(--bs-gray-200)",
              padding: "1rem 2rem",
              marginBottom: "1.6rem",
              borderRadius: "1rem",
            }}
          >
            <KioskInfoForm data={data} onChangeData={onChangeData} />
          </Col>
          <Row
            className="py-5 pb-5 align-items-center justify-content-center"
            style={{ gap: "1.2rem" }}
          >
            <Col
              style={{
                flex: 0,
                flexShrink: 0,
                wordBreak: "keep-all",
                padding: 0,
              }}
            >
              <button
                type="button"
                className="btn btn-default"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(-1);
                }}
                style={{
                  padding: "4px 32px",
                }}
              >
                취소
              </button>
            </Col>
            <Col
              style={{
                flex: 0,
                flexShrink: 0,
                wordBreak: "keep-all",
                padding: 0,
              }}
            >
              <button
                type="submit"
                className="btn btn-default"
                style={{
                  padding: "4px 32px",
                  marginRight: "1rem",
                }}
                onClick={onClickSave}
              >
                저장
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateKiosk;
