import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {apiClient} from "../../reactQuery/api";
import {Table} from "react-bootstrap";
import adminApi from "../../function/apiConfig";
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";



export default function DisplayList({categoryCode}) {
    const [displays, setDisplays] = useState([]);

    const { data: defaultDisplayQueryData, refetch } = useQuery(
        ['display',categoryCode],
        () =>
            apiClient({
                url: "/stores-display-categories/"+categoryCode+"/stores",
            }),
        {
            onSuccess: (res) => {},
            onError: (err) => alert(err.message),
            enabled: false,
            suspense: false,
            keepPreviousData: true,
            refetchOnMount: "always",
            refetchOnReconnect: "always",
            refetchOnWindowFocus: "always",
        }
    );

    const clickAddStore = ()=>{
        const _storeId = prompt("store_id를 입력해주세요");

        if (_storeId == null) {
        } else {
            if (_storeId == "") {
                alert("올바른 store_id를 입력해주세요");
                return;
            }
            adminApi({
                url: "/stores-display-categories/"+categoryCode+"/stores/"+_storeId,
                method: "post",
            })
                .then(function (response) {
                    alert("추가가 완료 되었습니다..");
                    refetch();
                })
                .catch(function (error) {

                });
        }

    }

    const saveOrdering = ()=>{
        const _result = window.confirm("순서를 저장 하시겠습니까?");

        if (_result) {
            adminApi({
                url: "/stores-display-categories/"+categoryCode+"/stores",
                method: "put",
                data: { priorities: displays.map((value,index)=>({'storeId':value.store.id,'priority':index}))},
            })
            .then(function (response) {
                alert("순서가 변경되었습니다.");
                refetch();
            })
            .catch(function (error) {

            });
        }

    }

    const clickDeleteStore = (_storeId)=>{
        const _result = window.confirm("정말 삭제하시겠습니까?");

        if (_result){

            adminApi({
                url: "/stores-display-categories/"+categoryCode+"/stores/"+_storeId,
                method: "delete",
            })
                .then(function (response) {
                    alert("삭제가 완료 되었습니다..");
                    refetch();
                })
                .catch(function (error) {

                });
        }

    }

    const onDragEnd = (result)=>{
        console.log(result);
        // alert('드래그 놓았음')
        if(!result.destination) {
            return;
        }
        console.log(result.source)
        const items = reorder(
            displays,
            result.source.index,
            result.destination.index
        );
        setDisplays(items)

    }

    const reorder =  (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        refetch();
    }, [categoryCode]);

    useEffect(() => {

        if (defaultDisplayQueryData?.data) {
            setDisplays(defaultDisplayQueryData.data);
        } else {
            setDisplays([]);
        }
    }, [defaultDisplayQueryData]);

    const grid = 8;
    const getItemStyle = (draggableStyle, isDragging) => ({
        // some basic styles to make the items look a bit nicer(아이템을 보기 좋게 만드는 몇 가지 기본 스타일)
        userSelect: 'none',
        // padding: grid * 2,
        // marginBottom: grid,

        // change background colour if dragging(드래깅시 배경색 변경)
        background: isDragging ? 'lightgreen' : 'grey',

        // styles we need to apply on draggables(드래그에 필요한 스타일 적용)
        ...draggableStyle
    });

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? 'lightblue' : 'lightgrey',
        // padding: grid,
        width: '100%'
    });


    const mappedDisplays = displays.map((item) => {
        const {
            id,
            storeName,
            status,
            domain,
        } = item.store;
        const {priority} = item.priority;
        return (
            <tr key={id} className="board-row-except">
                <td
                    style={{
                        maxWidth: "8rem",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}
                >{id}</td>
                <td
                    style={{
                        maxWidth: "8rem",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}
                >
                    <Link to={"/admin/store/stores/" + id}>{storeName}</Link>
                </td>
                <td>{status.name}</td>
                <td style={{ maxWidth: "16rem", wordBreak: "break-all" }}>
                    <a
                        href={`${process.env.REACT_APP_USER_URL}user/${domain}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        바로가기
                    </a>
                </td>
                <td style={{ maxWidth: "16rem", wordBreak: "break-all" }}>
                    <button className={'btn btn-primary'} onClick={() => clickDeleteStore(id)}>제거하기</button>
                </td>
            </tr>
        );
    });

    return (
        <>
            <button className={'btn btn-primary'} onClick={() => clickAddStore()}>가맹점 추가 하기</button>
            <button className={'btn btn-primary'} onClick={() => saveOrdering()}>가맹점 순서 저장</button>

            <ul>
                <li style={{'float':'left'}}>가맹점id</li>
                <li style={{'float':'left'}}>가맹점명</li>
                <li style={{'float':'left'}}>운영 상태</li>
                <li style={{'float':'left'}}>스토어 바로가기</li>
                <li style={{'float':'left'}}>리스트 삭제</li>
            </ul>
            <br/>

            <div >
                    {/*{mappedDisplays}*/}
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {/*<div style={{ position: "relative", width:'100%' }}>*/}
                                <div>
                                {displays && displays.map( (item, index) => (
                                    <Draggable
                                        key={item.store.id}

                                        draggableId={String(item.store.id)}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <tr className="board-row-except"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    provided.draggableProps.style,
                                                    snapshot.isDragging
                                                )}
                                            >
                                                <td
                                                    style={{
                                                        maxWidth: "8rem",
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >{item.store.id}</td>
                                                <td
                                                    style={{
                                                        maxWidth: "8rem",
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    <Link to={"/admin/store/stores/" + item.store.id}>{item.store.storeName}</Link>
                                                </td>
                                                <td>{item.store.status.name}</td>
                                                <td style={{ maxWidth: "16rem", wordBreak: "break-all" }}>
                                                    <a
                                                        href={`${process.env.REACT_APP_USER_URL}user/${item.store.domain}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        바로가기
                                                    </a>
                                                </td>
                                                <td style={{ maxWidth: "16rem", wordBreak: "break-all" }}>
                                                    <button className={'btn btn-primary'} onClick={() => clickDeleteStore(item.store.id)}>제거하기</button>
                                                </td>
                                            </tr>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                </div>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>


            </div>

        </>
    );
}
