const Td = ({ children }) => {
  return (
    <td
      style={{
        wordBreak: "keep-all",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </td>
  );
};

export default function ProductChainBoard({ data }) {
  const mappedOrderInfo = data.map((store) => {
    const {
      chainProductId = 0,
      sellChannel = {
        code: "",
        description: "",
        name: "",
      },
      product = {
        productId: 0,
        name: "",
        description: "",
        price: 0,
        storeSalePrice: 0,
        productType: {
          code: "",
          description: "",
          name: "",
        },
        representImageUrl: "",
        status: {
          code: "",
          description: "",
          name: "",
        },
        storeName: "",
        expireDateAdd: "",
        totalCount: 0,
        rewardPrice: 0,
        useReward: false,
        createdAt: "",
      },
    } = store;

    return (
      <tr key={chainProductId} className="board-row-except">
        <Td>{chainProductId}</Td>
        <Td>{sellChannel?.name}</Td>
        <Td>{product.storeName}</Td>
        <Td>{product.productId}</Td>
        <Td>{product.name}</Td>
        <Td>{product.productType?.description}</Td>
        <Td>{product.expireDateAdd}</Td>
        <Td>{product.totalCount ? product.totalCount : " "}</Td>
        <Td>{product.useReward ? "O" : "X"}</Td>
      </tr>
    );
  });

  return mappedOrderInfo;
}
