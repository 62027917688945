import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import Btn from "../../../components/admin/Btn";
import LeftMenu from "../../../components/admin/LeftMenu";
import adminApi from "../../../function/apiConfig";
import NewAdminPagination from "../../../components/admin/NewPagination";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../reactQuery/api";

export default function UserBoard() {
  //TODO 페이지 이동시 2번 렌더링 하는것 잡아야함.
  //TODO state를 묶어야 될듯함..

  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const [userName, setUserName] = useState(undefined);
  const [userId, setUserId] = useState(undefined);
  const [users, setUsers] = useState([]);
  const onChangeUserName = (e) => {
    setUserName(e.target.value);
  };
  const onChangeUserId = (e) => {
    setUserId(e.target.value);
  };
  const { data: defaultUsersQueryData, refetch } = useQuery(
    ["users", page, userName, userId],
    () =>
      apiClient({
        url: "/users",
        params: {
          page,
          size,
          ...(userName && { userName }),
          ...(userId && { userId }),
        },
      }),
    {
      onSuccess: (res) => {},
      onError: (err) => alert(err.message),
      enabled: false,
      suspense: false,
      keepPreviousData: true,
      refetchOnMount: "always",
      refetchOnReconnect: "always",
      refetchOnWindowFocus: "always",
    }
  );

  useEffect(() => {
    refetch();
  }, [page]);

  // useQuery의 onSuccess에서 처리하지 않고 useEffect 내에서 처리하는 이유는
  // Suspense 때문입니다!
  // 혹시 궁금하시다면, `https://velog.io/@kjwsx23/React-React-Query-%EC%99%80-setState`
  useEffect(() => {
    if (defaultUsersQueryData?.data?.empty) {
      setPage(0);
    }

    if (defaultUsersQueryData?.data?.totalPages) {
      setTotalPages(defaultUsersQueryData.data.totalPages);
    } else {
      setTotalPages(1);
    }

    if (defaultUsersQueryData?.data?.totalElements) {
      setTotalElements(defaultUsersQueryData.data.totalElements);
    } else {
      setTotalElements(0);
    }

    if (defaultUsersQueryData?.data?.content?.length > 0) {
      setUsers(defaultUsersQueryData.data.content);
    } else {
      setUsers([]);
    }
  }, [defaultUsersQueryData]);

  const onClickSearch = () => {
    setPage(0);
    refetch();
  };
  // if (isLoading) return <></>;

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">회원 목록</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              {/* 2개형 */}
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElements}</h5>
              </Col>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "620px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">이름</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={
                            (e) => onChangeUserName(e)
                            // setRequest({ ...request, userName: e.target.value })
                          }
                          type="text"
                          id="userName"
                          aria-describedby="passwordHelpBlock"
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">아이디</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={
                            (e) => onChangeUserId(e)
                            // setRequest({ ...request, userName: e.target.value })
                          }
                          type="text"
                          id="userId"
                          aria-describedby="passwordHelpBlock"
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} lg={"auto"}>
                    <Row className="align-items-center justify-content-center filter-btnbox">
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="조회 하기"
                          // onClick={() => inquire()}
                          onClick={() => onClickSearch()}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr>
                    <th>유저 아이디</th>
                    <th>로그인 아이디</th>
                    <th>이름</th>
                    <th>전화번호</th>
                    <th>이메일</th>
                    <th>상태</th>
                    <th>가입일</th>
                  </tr>
                </thead>
                <tbody>
                  {/* data 값으로 루프 */}
                  {users.map((item, index) => (
                    <tr key={item.id}>
                      <td>{item.userId}</td>
                      <td>
                        {/*<Link to={'/admin/store/stores/'+item.id}>*/}
                        {item.loginId}
                        {/*</Link>*/}
                      </td>
                      <td>{item.name}</td>
                      <td>{item.phoneNumber}</td>
                      <td>{item.email}</td>
                      <td>{item.status.description}</td>
                      <td>
                        {item.createdAt.replace("T", " ").substring(0, 16)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <NewAdminPagination
                // totalPages={pageable.totalPages}
                pageNationSize={10}
                // page={pageable.page * 1}
                // handlePage={movePage}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                // request={request}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
