import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import LeftMenu from "../../../../components/admin/LeftMenu";
import NewAdminPagination from "../../../../components/admin/NewPagination";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../../reactQuery/api";
import PhysicalCardBoard from "../../../../components/product/physicalCard/PhysicalCardBoard";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../../components/common/CustomModal";

export default function PhysicalCard() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const [data, setData] = useState([]);
  const [linkModal, setLinkModal] = useState(false);
  const [modalKeywordType, setModalKeywordType] = useState("STORE_NAME");
  const [modalKeyword, setModalKeyword] = useState("");
  const [modalStores, setModalStores] = useState({
    stores: [],
    totalElements: 0,
    totalPages: 0,
    page: 0,
    selectedStoreId: null,
    targetBulk: null,
  });

  const { data: defaultQueryData, refetch } = useQuery(
    ["physicalCardBulk"],
    () =>
      apiClient({
        url: "/card-bulks",
        params: {
          page,
          size,
        },
      }),
    {
      onSuccess: (res) => {},
      onError: (err) => alert(err.message),
      enabled: false,
      suspense: false,
      keepPreviousData: true,
      refetchOnMount: "always",
      refetchOnReconnect: "always",
      refetchOnWindowFocus: "always",
    }
  );

  const { refetch: searchStore } = useQuery(
    ["physicalCardStoreLink", modalKeyword, modalKeywordType],
    () =>
      apiClient({
        url: `/stores`,
        params: {
          page: modalStores.page,
          size: 5,
          ...(modalKeywordType === "STORE_NAME" && { storeName: modalKeyword }),
          ...(modalKeywordType === "OWNER_NAME" && { ownerName: modalKeyword }),
        },
        method: "get",
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        const { content, totalPages, totalElements, number } = res;
        setModalStores((prev) => ({
          ...prev,
          stores: content,
          totalPages,
          totalElements,
          page: number,
        }));
      },
      enabled: false,
    }
  );

  const linkStoreMutation = useMutation(
    () =>
      apiClient({
        url: `/card-bulks/${modalStores.targetBulk?.id}/store/${modalStores.selectedStoreId}`,
        method: "post",
      }).then((res) => res.data),
    {
      onSuccess: () => {
        alert("연동이 완료되었습니다.");
        refetch();
      },
      onError: (err) => {
        alert(err);
      },
      onSettled: () => {
        setModalStores({
          stores: [],
          totalElements: 0,
          totalPages: 0,
          page: 0,
          selectedStoreId: null,
          targetBulk: null,
        });
        setModalKeywordType("STORE_NAME");
        setModalKeyword("");
      },
    }
  );

  useEffect(() => {
    searchStore();
  }, [modalStores.page]);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (defaultQueryData?.data?.empty) {
      setPage(0);
    }

    if (defaultQueryData?.data?.totalPages) {
      setTotalPages(defaultQueryData.data.totalPages);
    } else {
      setTotalPages(1);
    }

    if (defaultQueryData?.data?.totalElements) {
      setTotalElements(defaultQueryData.data.totalElements);
    } else {
      setTotalElements(0);
    }

    if (defaultQueryData?.data?.content?.length > 0) {
      setData(defaultQueryData.data.content);
    } else {
      setData([]);
    }
  }, [defaultQueryData]);

  const onClickLink = (row) => {
    setModalStores((prev) => ({ ...prev, targetBulk: row }));
    setLinkModal(true);
  };

  const onClickLinkStore = () => {
    if (linkStoreMutation.isLoading) return;
    linkStoreMutation.mutate();
  };

  return (
    <>
      <Container fluid>
        <Row style={{ flexWrap: "nowrap" }}>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">실물카드 관리</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElements}</h5>
              </Col>
              <Col xs={"auto"} style={{ marginLeft: "auto" }}>
                <Button
                  onClick={() =>
                    navigate(`/admin/product/physical-card/create`)
                  }
                >
                  실물카드 생성
                </Button>
              </Col>
            </Row>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{
                  verticalAlign: "middle",
                }}
                className="mb-4 text-center"
                responsive
              >
                <thead>
                  <tr>
                    <th>id</th>
                    <th>이름</th>
                    <th>설명</th>
                    <th>생성일시</th>
                    <th>가맹점 명 / 가맹점 id</th>
                    <th>발급 매수</th>
                    <th>잔여 수량</th>
                    <th>카드 이미지</th>
                    <th>삭제 여부</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="text-center" style={{ position: "relative" }}>
                  <PhysicalCardBoard data={data} onClickLink={onClickLink} />
                </tbody>
              </Table>
              <NewAdminPagination
                pageNationSize={10}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              />
            </div>
          </Col>
        </Row>
        {linkModal && (
          <CustomModal
            callbackOfOk={onClickLinkStore}
            textOfHeader={"실물카드 가맹점 연동"}
            textOfBody={
              <Col>
                <Row>
                  <p>
                    연동할 가맹점을 사장님 성함 혹은 가맹점 명으로 검색하세요
                  </p>
                </Row>
                <Row>
                  <Col>
                    <Form.Select
                      onChange={(e) => setModalKeywordType(e.target.value)}
                    >
                      {[
                        {
                          label: "가맹점 명",
                          value: "STORE_NAME",
                        },
                        {
                          label: "사장님 성함",
                          value: "OWNER_NAME",
                        },
                      ].map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Control
                      value={modalKeyword}
                      onChange={(e) => setModalKeyword(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Button onClick={() => searchStore()}>검색</Button>
                  </Col>
                </Row>
                <Row>
                  <Table
                    style={{ verticalAlign: "middle" }}
                    className="mb-4 text-center"
                  >
                    <thead>
                      <tr>
                        <th>상점 id</th>
                        <th>상점 명</th>
                        <th>사장님 성함</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {modalStores.stores &&
                        modalStores.stores.map((store) => (
                          <tr
                            key={store.id}
                            style={{
                              background:
                                modalStores.selectedStoreId === store.id
                                  ? "var(--bs-table-striped-bg)"
                                  : "transparent",
                              border:
                                modalStores.selectedStoreId === store.id
                                  ? "var(--bs-btn-border-color)"
                                  : "transparent",
                            }}
                          >
                            <td>{store.id}</td>
                            <td>{store.storeName}</td>
                            <td>{store.ownerName}</td>
                            <td>
                              <Button
                                onClick={() =>
                                  setModalStores((prev) => ({
                                    ...prev,
                                    selectedStoreId: store.id,
                                  }))
                                }
                              >
                                선택
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <NewAdminPagination
                    totalPages={modalStores.totalPages}
                    pageNationSize={10}
                    page={modalStores.page}
                    setPage={(page) =>
                      setModalStores((prev) => ({ ...prev, page }))
                    }
                    setTotalPages={(totalPages) =>
                      setModalStores((prev) => ({ ...prev, totalPages }))
                    }
                  />
                </Row>
              </Col>
            }
            setModal={setLinkModal}
            messageOfOk="연동하기"
            messageOfClose="취소"
          />
        )}
      </Container>
    </>
  );
}
