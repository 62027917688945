import BottomBoard from "./BottomBoard";
import MidBoard from "./MidBoard";
import SummaryBoard from "./SummaryBoard";

export default function ContentBoard({
  summarys,
  isSummary,
  timeUnit,
  firstTitle,
  firstChart,
  secondTitle,
  secondChart,
  thirdTitle,
  thirdChart,
  isMid,
  isRePurchase,
}) {
  return (
    <>
      {!isSummary && <SummaryBoard summarys={summarys} timeUnit={timeUnit} />}
      {/* <MidBoard
        firstTitle={firstTitle}
        firstChart={firstChart}
        secondTitle={secondTitle}
        secondChart={secondChart}
        isMid={isMid}
      /> */}
      {/* {isMid ? (
        <BottomBoard
          secondTitle={secondTitle}
          secondChart={secondChart}
          isMid={isMid}
          isRePurchase={isRePurchase}
        />
      ) : (
        <BottomBoard thirdTitle={thirdTitle} thirdChart={thirdChart} />
      )} */}
    </>
  );
}
