import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export default function CustomModal({
  callbackOfClose = () => {},
  callbackOfOk = () => {},
  messageOfClose = "닫힘",
  messageOfOk = "확인",
  textOfHeader = "알림",
  textOfBody = "메세지",
  showModal = false,
  setModal,
}) {
  //가져다 슬때 state
  // const modal={
  //     callbackOfClose:"",
  //     callbackOfOk:"",
  //     textOfHeader:"",
  //     textOfBody:"",
  //     showModal:true,
  // }

  const [show, setShow] = useState(true);

  function clickCloseButton() {
    callbackOfClose();
    closeModal();
  }

  function clickOkButton() {
    callbackOfOk();
    closeModal();
  }

  function closeModal() {
    setShow(false);
    setModal(false);
  }

  return (
    <>
      <Modal show={show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{textOfHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{whiteSpace:'pre-line'}}>{textOfBody}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => clickCloseButton()}>
            {messageOfClose}
          </Button>
          <Button variant="primary" onClick={() => clickOkButton()}>
            {messageOfOk}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
