/**
 * getDateType 함수는 날짜 타입에 따라서 날짜를 계산하여 문자열로 반환합니다.
 * @param {string} type - 날짜 타입을 지정합니다. 가능한 값은 다음과 같습니다:
 *   - "YESTERDAY": 어제의 날짜
 *   - "RECENT_7": 최근 7일간의 날짜
 *   - "RECENT_14": 최근 14일간의 날짜
 *   - "RECENT_30": 최근 30일간의 날짜
 *   - "THIS_WEEK_FIRST": 이번 주의 첫날
 *   - "LAST_WEEK_FIRST": 지난 주의 첫날
 *   - "LAST_WEEK_LAST": 지난 주의 마지막 날
 *   - "THIS_MONTH_FIRST": 이번 달의 첫날
 *   - "LAST_MONTH_FIRST": 지난 달의 첫날
 *   - "LAST_MONTH_LAST": 지난 달의 마지막 날
 *   - "THIS_YEAR_FIRST": 올해의 첫날
 * @returns {string} 계산된 날짜를 "YYYY-MM-DD" 형식의 문자열로 반환합니다.
 */
const getDateType = (type) => {
  const date = new Date();
  let result;

  if (type === "YESTERDAY") {
    const date2 = date.getTime() - 1 * 24 * 60 * 60 * 1000;
    date.setTime(date2);
  } else if (type === "RECENT_7") {
    const date2 = date.getTime() - 7 * 24 * 60 * 60 * 1000;
    date.setTime(date2);
  } else if (type === "RECENT_14") {
    const date2 = date.getTime() - 14 * 24 * 60 * 60 * 1000;
    date.setTime(date2);
  } else if (type === "RECENT_30") {
    const date2 = date.getTime() - 30 * 24 * 60 * 60 * 1000;
    date.setTime(date2);
  } else if (type === "THIS_WEEK_FIRST") {
    const date2 = new Date().setDate(date.getDate() - date.getDay() + 1);
    date.setTime(date2);
  } else if (type === "LAST_WEEK_FIRST") {
    const date2 = new Date().setDate(date.getDate() - date.getDay() - 7 + 1);
    date.setTime(date2);
  } else if (type === "LAST_WEEK_LAST") {
    const date2 = new Date().setDate(date.getDate() - date.getDay() - 1 + 1);
    date.setTime(date2);
  } else if (type === "THIS_MONTH_FIRST") {
    const date2 = new Date(date.getFullYear(), date.getMonth(), 1);
    date.setTime(date2);
  } else if (type === "LAST_MONTH_FIRST") {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastMonth = new Date(
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 1)
    );
    const date2 = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);
    date.setTime(date2);
  } else if (type === "LAST_MONTH_LAST") {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastMonth = new Date(
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 1)
    );
    date.setTime(lastMonth);
  } else if (type === "THIS_YEAR_FIRST") {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    date.setTime(firstDayOfYear);
  }

  result =
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2);
  return result;
};

export default getDateType;
