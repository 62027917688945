import React, { useState } from "react";
import { Col, Form, FormLabel } from "react-bootstrap";

const selectOptions = [
  {
    value: "file",
    label: "파일",
  },
  {
    value: "link",
    label: "링크",
  },
];

const KioskImageManagement = () => {
  const [introData, setIntroData] = useState({
    type: "file",
    value: "",
  });
  const [logoData, setLogoData] = useState({
    type: "file",
    value: "",
  });

  return (
    <div>
      <Col style={{ marginBottom: "2.4rem" }}>
        <FormLabel>
          인트로 화면 (인트로 화면을 등록하지 않으면 바로 메인 화면이
          노출됩니다.)
        </FormLabel>
        <Form.Group
          className="Formbox"
          style={{ display: "flex", gap: "0.4rem" }}
        >
          <Form.Select
            style={{ flex: 0.2 }}
            onChange={(e) =>
              setIntroData((prev) => ({
                ...prev,
                type: e.target.value,
              }))
            }
            value={introData.type}
          >
            {selectOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
          <Form.Control
            placeholder={
              "이미지 및 영상 파일을 업로드해주세요. (jpg,png,mp4 파일)"
            }
            value={introData.value}
            onChange={(e) =>
              setIntroData((prev) => ({
                ...prev,
                value: e.target.value,
              }))
            }
            required
            style={{ flex: 0.8 }}
          />
        </Form.Group>
      </Col>
      <Col>
        <FormLabel>상단 로고</FormLabel>
        <Form.Group
          className="Formbox"
          style={{ display: "flex", gap: "0.4rem" }}
        >
          <Form.Select
            style={{ flex: 0.2 }}
            onChange={(e) =>
              setLogoData((prev) => ({
                ...prev,
                type: e.target.value,
              }))
            }
            value={logoData.type}
          >
            {selectOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
          <Form.Control
            placeholder={
              "이미지 및 영상 파일을 업로드해주세요. (jpg,png,mp4 파일)"
            }
            value={logoData.value}
            onChange={(e) =>
              setLogoData((prev) => ({
                ...prev,
                value: e.target.value,
              }))
            }
            required
            style={{ flex: 0.8 }}
          />
        </Form.Group>
      </Col>
    </div>
  );
};

export default KioskImageManagement;
