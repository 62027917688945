import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../reactQuery/api";
import { queryKeys } from "../../reactQuery/constants";

export function useCouponDetail({ couponId }) {
  const { data: coupon } = useQuery(
    [queryKeys.couponDetail, couponId],
    async () => {
      const { data } = await apiClient({
        url: `/coupons/${couponId}`,
        method: "get",
      });

      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return { coupon };
}
