import React, { Suspense, useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import SimplePagination from "../../../components/admin/SimplePagination";
import { useNavigate } from "react-router-dom";
import NoticeBoard from "../../../components/notice/NoticeBoard";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../reactQuery/api";
import { queryClient } from "../../../reactQuery/queryClient";

const ManageNotice = () => {
  const [page, setPage] = useState(0);
  const [pageInfo, setPageInfo] = useState({ totalElements: 0, totalPages: 0 });
  const [data, setData] = useState([]);
  const [type, setType] = useState("default");
  const [deleteTarget, setDeleteTarget] = useState([]);
  const navigate = useNavigate();

  const goToCreate = () => {
    navigate("/admin/notices/create");
  };

  const goToManage = (id) => {
    navigate(`/admin/notices/detail/${id}`);
  };

  const deleteNoticeMutation = useMutation(
    async ({ noticeId }) =>
      await apiClient({
        url: `/notice/${noticeId}`,
        method: "delete",
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        if (!res) return;
      },
    }
  );

  const onClickDeleteNotice = (e) => {
    e.stopPropagation();
    if (type === "default") {
      setType("delete");
      return;
    }
    if (deleteTarget.length < 1) {
      alert("삭제할 공지사항을 선택해주세요.");
      return;
    }
    deleteTarget.forEach((target) => {
      deleteNoticeMutation.mutate({ noticeId: target.id });
    });
    queryClient.fetchQuery(["notices", page, pageInfo]);
  };

  const onClickCheckAll = () => {
    if (deleteTarget.length < data.length) {
      setDeleteTarget(data.map((row) => row.id));
    } else {
      setDeleteTarget([]);
    }
  };

  useQuery(
    ["notices", page, pageInfo],
    async () =>
      await apiClient({
        url: `/notices`,
        method: "get",
        params: {
          page,
          size: 10,
        },
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        if (!res) return;
        setData(res.content);
        setPageInfo({
          totalElements: res.totalElements,
          totalPages: res.totalPages,
        });
      },
      staleTime: 0,
    }
  );

  return (
    <Container fluid>
      <Row>
        <Col xs={2} className="LeftBg px-0">
          <LeftMenu />
        </Col>
        <Col className="RightBg px-5">
          <Row className="pb-3 pt-4 row">
            <Col>
              <h1 className="fw-bold">공지사항 관리</h1>
            </Col>
          </Row>

          <Row
            className="py-1 align-items-center justify-content-end"
            style={{ gap: "1.2rem" }}
          >
            <Col
              style={{
                flex: 0,
                flexShrink: 0,
                wordBreak: "keep-all",
                padding: 0,
              }}
            >
              {type === "default" && (
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={(e) => {
                    e.stopPropagation();
                    goToCreate();
                  }}
                  style={{
                    padding: "4px 32px",
                  }}
                >
                  추가
                </button>
              )}
              {type === "delete" && (
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={(e) => {
                    e.stopPropagation();
                    setType("default");
                    if (Boolean(deleteTarget.length)) {
                      setDeleteTarget([]);
                    }
                  }}
                  style={{
                    padding: "4px 32px",
                  }}
                >
                  취소
                </button>
              )}
            </Col>
            <Col
              style={{
                flex: 0,
                flexShrink: 0,
                wordBreak: "keep-all",
                padding: 0,
              }}
            >
              <button
                type="button"
                className="btn btn-default"
                onClick={onClickDeleteNotice}
                style={{
                  padding: "4px 32px",
                  marginRight: "1rem",
                }}
              >
                삭제
              </button>
            </Col>
          </Row>

          <div className="ContentBox TableStyle mt-4">
            <Table
              style={{ verticalAlign: "middle" }}
              className="mb-4 text-center"
            >
              <thead>
                <tr>
                  {type === "delete" && (
                    <th>
                      <Form.Check
                        type="checkbox"
                        checked={deleteTarget.length === data.length}
                        onChange={onClickCheckAll}
                      />
                    </th>
                  )}
                  <th>공지사항 id</th>
                  <th>제목</th>
                  <th>내용</th>
                  <th>노출 플랫폼</th>
                  <th>생성일시</th>
                  <th>수정일시</th>
                  <th>조회수</th>
                  {type === "default" && <th>관리</th>}
                </tr>
              </thead>
              <tbody style={{ position: "relative" }}>
                <Suspense
                  fallback={
                    <tr style={{ height: "250px" }}>
                      <Spinner
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          marginLeft: "-18px",
                        }}
                        animation="border"
                      />
                    </tr>
                  }
                >
                  {data?.length > 0 ? (
                    <NoticeBoard
                      data={data}
                      onClickManage={(id) => goToManage(id)}
                      tableType={type}
                      onClickCheck={(id, status) => {
                        if (
                          deleteTarget.findIndex(
                            (target) => target.id === id
                          ) !== -1
                        ) {
                          setDeleteTarget((prev) =>
                            prev.filter((target) => target.id !== id)
                          );
                        } else {
                          setDeleteTarget((prev) => [...prev, { id, status }]);
                        }
                      }}
                      checked={(id) =>
                        deleteTarget.findIndex((target) => target.id === id) !==
                        -1
                      }
                    />
                  ) : (
                    <tr>
                      <td colSpan={7} style={{ padding: "4rem" }}>
                        등록된 공지사항이 없습니다
                      </td>
                    </tr>
                  )}
                </Suspense>
              </tbody>
            </Table>
            <SimplePagination
              currentPage={page}
              totalPage={pageInfo.totalPages}
              setPage={setPage}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ManageNotice;
