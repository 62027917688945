import { useMutation } from "@tanstack/react-query";
import QRCode from "qrcode";

export function useQRDownload() {
  const { mutate: mutateQR } = useMutation(
    async ({ domain }) => {
      const storeUrl = `${process.env.REACT_APP_USER_URL}user/${domain}`;
      const data = await QRCode.toDataURL(storeUrl, {
        quality: 1.0,
        color: {
          light: "#0000",
        },
      });

      return { data, domain };
    },
    {
      onSuccess: ({ data, domain }) => {
        const link = document.createElement("a");

        link.href = data;
        link.style.display = "none";
        link.download = domain + "_qrcode.png";

        document.body.appendChild(link);

        link.click();
        link.remove();
      },
      onError: (error) => {
        alert(error);
      },
    }
  );

  return { mutateQR };
}
