import { useQuery } from "@tanstack/react-query";
import { transformYYYYMMDD } from "../../function/date";
import { apiClient } from "../../reactQuery/api";

export function useCoupons(isEnabled, setEnabled, searchState, page) {
  const { storeName, productName, status, issueDateStart, issueDateEnd, couponNumber } =
    searchState;

  const { data: coupons } = useQuery(
    [
      storeName,
      productName,
      status,
      transformYYYYMMDD(issueDateStart),
      transformYYYYMMDD(issueDateEnd),
      couponNumber,
      String(page),
    ],
    async () => {
      const { data } = await apiClient({
        url: "/coupons",
        method: "get",
        params: {
          storeName,
          productName,
          status,
          issueDateStart: transformYYYYMMDD(issueDateStart),
          issueDateEnd: transformYYYYMMDD(issueDateEnd),
          couponNumber,
          page,
        },
      });

      return data;
    },
    {
      onSuccess: () => {
        setEnabled(false);
      },
      onError: () => {
        setEnabled(false);
      },
      isEnabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      // keepPreviousData: true,
    }
  );

  return { coupons };
}
