import { useState, useReducer, Suspense, useEffect, useCallback } from "react";
import { Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Btn from "../../../components/admin/Btn";
import LeftMenu from "../../../components/admin/LeftMenu";
import CustomModal from "../../../components/common/CustomModal";
import adminApi from "../../../function/apiConfig";
import { ko } from "date-fns/esm/locale";
import DatePicker from "react-datepicker";
import DetailPagination from "../../../components/admin/DetailPagination";
import { couponReducer } from "../../../reducers/coupon/couponReducer";
import CouponBoard from "../../../components/coupon/CouponBoard";
import { transformYYYYMMDD } from "../../../function/date";
import "react-datepicker/dist/react-datepicker.css"

const initialCouponState = {
  storeName: "",
  productName: "",
  issueDateStart: new Date(),
  issueDateEnd: new Date(),
  status: "",
  couponNumber:"",
};

export default function Coupons() {
  const [modal, setModal] = useState({ showModal: false });

  const navigate = useNavigate();
  const location = useLocation();
  const [isEnabled, setIsEnabled] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalElement, setTotalElement] = useState(0);
  const [couponState, couponDispatch] = useReducer(
    couponReducer,
    initialCouponState
  );
  const [searchState, searchDispatch] = useReducer(
    couponReducer,
    initialCouponState
  );

  const setPageStatus = useCallback(() => {
    const [
      _,
      page,
      issueDateStart,
      issueDateEnd,
      storeName,
      productName,
      status,
      couponNumber,
    ] = location.search
      .replace(
        /\?page=|&issueDateStart=|&issueDateEnd=|&storeName=|&productName=|&status=|&couponNumber=/g,
        " "
      )
      .split(" ");

    if (!page) {
      setPage(0);
      searchDispatch({ type: "changed_storeName", storeName: "" });
      searchDispatch({ type: "changed_productName", productName: "" });
      searchDispatch({ type: "changed_status", status: "" });
      searchDispatch({ type: "changed_startDate", issueDateStart: new Date() });
      searchDispatch({ type: "changed_endDate", issueDateEnd: new Date() });
      searchDispatch({ type: "changed_couponNumber", couponNumber:"" });
      return;
    }

    setPage(page);
    searchDispatch({
      type: "changed_storeName",
      storeName: decodeURI(storeName),
    });
    searchDispatch({
      type: "changed_productName",
      productName: decodeURI(productName),
    });
    searchDispatch({
      type: "changed_status",
      status: decodeURI(status),
    });
    searchDispatch({
      type: "changed_startDate",
      startDate: decodeURI(issueDateStart),
    });
    searchDispatch({
      type: "changed_endDate",
      endDate: decodeURI(issueDateEnd),
    });
    searchDispatch({
      type: "changed_couponNumber",
      couponNumber: decodeURI(couponNumber),
    });
  }, [setPage, location]);

  useEffect(() => {
    setPageStatus();
  }, [setPageStatus]);

  const getNavigateStr = (state, page = 0) => {
    return `?page=${page}&issueDateStart=${transformYYYYMMDD(
      state.issueDateStart
    )}&issueDateEnd=${transformYYYYMMDD(state.issueDateEnd)}&storeName=${
      state.storeName
    }&productName=${state.productName}&status=${state.status}&couponNumber=${state.couponNumber}`;
  };

  const handleSearchBtnClick = () => {
    searchDispatch({
      type: "changed_storeName",
      storeName: couponState.storeName,
    });
    searchDispatch({
      type: "changed_productName",
      productName: couponState.productName,
    });
    searchDispatch({
      type: "changed_status",
      status: couponState.status,
    });
    searchDispatch({
      type: "changed_startDate",
      startDate: couponState.issueDateStart,
    });
    searchDispatch({
      type: "changed_endDate",
      endDate: couponState.issueDateEnd,
    });
    searchDispatch({
      type: "changed_couponNumber",
      couponNumber: couponState.couponNumber,
    });
    navigate(getNavigateStr(couponState));
  };

  const handleInputKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }

    handleSearchBtnClick();
  };

  const handlePageMove = (page = 0) => {
    navigate(getNavigateStr(searchState, page));
  };

  function openDownloadModal() {
    const modalContent = {
      callbackOfOk: () => downloadExcel(),
      textOfBody: "인지세용 엑셀를 다운로드 하시겠습니까?",
      showModal: true,
    };
    setModal(modalContent);
  }

  function downloadExcel() {
    let issueDateStart;
    let issueDateEnd;

    issueDateStart = prompt("월 초일을 입력해주세요 ex)22-10-01");
    if (!issueDateStart) return;
    issueDateEnd = prompt("월 말일을 입력해주세요 ex)22-10-31");
    if (!issueDateEnd) return;

    let requestParams = {
      issueDateStart: issueDateStart,
      issueDateEnd: issueDateEnd,
    };

    adminApi({
      url: "/coupons/excel",
      params: requestParams,
      method: "get",
      customOption: { fileDownLoad: true },
      responseType: "arraybuffer",
    })
      .then(function (response) {
        console.log(response);
        const blob = new Blob([response.data]);
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.style.display = "none";
        link.download = getFileName(response);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(function (error) {});
  }

  function getFileName(response) {
    const disposition = response.headers["content-disposition"];

    const fileName = decodeURI(
      disposition
        .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
        .replace(/['"]/g, "")
    );
    return fileName;
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">교환권 목록</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElement}</h5>
              </Col>
              <Row className="p-1 justify-content-end">
                <Col xs={"auto"} className="btn-black">
                  <Btn
                    type="API"
                    btntext="인지세용 엑셀 다운"
                    onClick={() => openDownloadModal()}
                  />
                </Col>
              </Row>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "620px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">가맹점명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={({ target }) =>
                            couponDispatch({
                              type: "changed_storeName",
                              storeName: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">교환권번호</h5>
                      </Col>
                      <Col>
                        <Form.Control
                            onChange={({ target }) =>
                                couponDispatch({
                                  type: "changed_couponNumber",
                                  couponNumber: target.value,
                                })
                            }
                            onKeyDown={handleInputKeyDown}
                            type="text"
                            id="couponNumber"
                            aria-describedby="passwordHelpBlock"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">상품명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={({ target }) =>
                            couponDispatch({
                              type: "changed_productName",
                              productName: target.value,
                            })
                          }
                          onKeyDown={handleInputKeyDown}
                          type="text"
                          id="ownerName"
                          aria-describedby="passwordHelpBlock"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="p-1 align-items-center justify-content-center">
                  <Col xs={12} className="mb-4">
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">교환권 상태</h5>
                      </Col>
                      <Col>
                        <Form
                          className="form-radio"
                          onChange={({ target }) =>
                            couponDispatch({
                              type: "changed_status",
                              status: target.value,
                            })
                          }
                        >
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`} className="radiobox">
                              <Form.Check
                                defaultChecked={couponState.status === ""}
                                inline
                                label="전체"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-1`}
                                value=""
                              />
                              <Form.Check
                                defaultChecked={
                                  couponState.status === "NOT_USED"
                                }
                                inline
                                label="미사용"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-2`}
                                value="NOT_USED"
                              />
                              <Form.Check
                                defaultChecked={couponState.status === "USED"}
                                inline
                                label="사용"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-3`}
                                value="USED"
                              />
                              <Form.Check
                                defaultChecked={
                                  couponState.status === "CANCELED"
                                }
                                inline
                                label="결제취소"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-4`}
                                value="CANCELED"
                              />
                            </div>
                          ))}
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={"auto"}>
                    <Row className="p-1 align-items-center justify-content-center mb-4">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold"> 발행일</h5>
                      </Col>
                      <Col xs={"auto"}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={couponState.issueDateStart}
                          onChange={(date) => {
                            couponDispatch({
                              type: "changed_startDate",
                              startDate: date,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={"auto"}>
                        <h5 className="mb-0"> ~ </h5>
                      </Col>
                      <Col xs={"auto"}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={couponState.issueDateEnd}
                          onChange={(date) =>
                            couponDispatch({
                              type: "changed_endDate",
                              endDate: date,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={"auto"}>
                    <Row className="align-items-center justify-content-center filter-btnbox">
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="조회 하기"
                          onClick={handleSearchBtnClick}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr>
                    <th>교환권 id</th>
                    <th>교환권 번호</th>
                    <th>가맹점명</th>
                    <th>결제채널</th>
                    <th>상태</th>
                    <th>타입</th>
                    {/*<th>구매자 이름</th>*/}
                    <th>액면가</th>
                    <th>잔액</th>
                    <th>매출</th>
                    <th>정산</th>
                    <th>수수료</th>
                    {/*<th>결제 가격</th>*/}
                    <th>상품명</th>
                    <th>발행일자</th>
                  </tr>
                </thead>
                <tbody style={{ position: "relative" }}>
                  <Suspense
                    fallback={
                      <tr style={{ height: "250px" }}>
                        <Spinner
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            marginLeft: "-18px",
                          }}
                          animation="border"
                        />
                      </tr>
                    }
                  >
                    <CouponBoard
                      isEnabled={isEnabled}
                      setIsEnabled={setIsEnabled}
                      setTotalElement={setTotalElement}
                      setTotalPage={setTotalPage}
                      searchState={searchState}
                      page={page}
                    />
                  </Suspense>
                </tbody>
              </Table>
              <DetailPagination
                currentPage={page}
                totalPage={totalPage}
                onPageMove={handlePageMove}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {modal.showModal && (
        <CustomModal
          callbackOfOk={modal.callbackOfOk}
          textOfHeader={modal.textOfHeader}
          textOfBody={modal.textOfBody}
          setModal={setModal}
        />
      )}
    </>
  );
}
