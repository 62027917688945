import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { apiClient } from "../../reactQuery/api";

export function useSignUp() {
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    async (auth) => {
      const { name, loginId, password } = auth;

      return await apiClient({
        url: "/auth/signup",
        method: "post",
        data: {
          loginId,
          password,
          name,
        },
      });
    },
    {
      onSuccess: () => {
        navigate("/admin/login");
      },
      onError: ({ response }) => {
        const { data } = response;
        const { errorDetails } = data;

        alert(`${errorDetails[0].reason}`);
      },
    }
  );

  return { mutate, isLoading };
}
