import { Button, Nav, Pagination } from "react-bootstrap";

// interface PaginationProps {
//   page: number;
// }

function NewAdminPagination({
  pageNationSize,
  page,
  setPage,
  totalPages,
  setTotalPages,
  handlePage,
  request,
}) {
  // const startPage = Math.floor(page / pageNationSize) * pageNationSize;

  // function movePage(page) {
  //   handlePage({ ...request, page: page });
  // }

  // function prevPage(page) {
  //   if (page >= 0) {
  //     handlePage({ ...request, page: page });
  //   }
  // }

  // function nextPage(page) {
  //   if (page < totalPages) {
  //     handlePage({ ...request, page: page });
  //   }
  // }

  // let items = [];
  // for (let number = 1; number <= totalPages; number++) {
  //   let pageNum = Math.floor((number - 1) / pageNationSize) * pageNationSize;
  //   if (pageNum == startPage) {
  //     items.push(
  //       <Pagination.Item
  //         key={number}
  //         className={number === page * 1 + 1 ? "page on" : "page"}
  //         onClick={() => movePage(number - 1)}
  //       >
  //         {number}
  //       </Pagination.Item>
  //     );
  //   }
  // }

  const startPageNumer = page ? page - (page % pageNationSize) + 1 : 1;
  const pageNumbers = [];
  for (
    let number = startPageNumer;
    number <= startPageNumer + (pageNationSize - 1) && number <= totalPages;
    number++
  ) {
    pageNumbers.push(number);
  }

  return (
    <>
      {totalPages ? (
        <Pagination className="align-items-center">
          {/* <Pagination.First
            className="PaginationFirst"
            onClick={() => prevPage(0)}
          />
          <Pagination.Prev
            className="PaginationPrev"
            onClick={() => prevPage(page - 1)}
          /> */}
          <Pagination.First
            className="PaginationFirst"
            onClick={() => setPage(0)}
          />
          <Pagination.Prev
            className="PaginationPrev"
            onClick={() =>
              setPage((prev) => {
                if (prev) {
                  return prev - 1;
                }
                return 0;
              })
            }
          />

          {/* {Array(totalPages).fill().filter((v,i) => i+startPage<totalPages).map((_, i)=> (
              <Pagination.Item className={'Page Page' + (startPage + i+1)} key={startPage + i} onClick={() => movePage(startPage+i)}>{startPage + i+1}</Pagination.Item>

          ))} */}

          {/* {items} */}
          {pageNumbers.map((num) => (
            <Pagination.Item
              key={num}
              className={num === page + 1 ? "page on" : "page"}
              onClick={() => setPage(num - 1)}
            >
              {num}
            </Pagination.Item>
          ))}

          {/* <Pagination.Next
            className="PaginationNext"
            onClick={() => nextPage(page + 1)}
          />
          <Pagination.Last
            className="PaginationLast"
            onClick={() => prevPage(totalPages - 1)}
          /> */}
          <Pagination.Next
            className="PaginationNext"
            onClick={() =>
              setPage((prev) => {
                if (prev === totalPages - 1) {
                  return prev;
                }
                return prev + 1;
              })
            }
          />
          <Pagination.Last
            className="PaginationLast"
            onClick={() => setPage(totalPages - 1)}
          />
        </Pagination>
      ) : null}
    </>
  );
}

export default NewAdminPagination;
