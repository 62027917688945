import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import Btn from "../../../components/admin/Btn";
import CustomModal from "../../../components/common/CustomModal";
import useStores from "../../../hooks/store/useStores";
import SimplePagination from "../../../components/admin/SimplePagination";
import { useLogoDownload } from "../../../hooks/store/useLogoDownload";
import { useQRDownload } from "../../../hooks/store/useQRDownload";
import { useExcelDownload } from "../../../hooks/excel/useExcelDownload";
import StoreBoard from "../../../components/store/StoreBoard";
import { Suspense, useReducer, useEffect, useState, useCallback } from "react";
import { storeReducer } from "../../../reducers/store/storeReducer";
import DetailPagination from "../../../components/admin/DetailPagination";
import { transformYYYYMMDD } from "../../../function/date";
import adminApi from "../../../function/apiConfig";

const initialStoreState = {
  storeName: "",
  ownerName: "",
  storeStatus: "",
  storeAcceptStatus: "",
  billingType: "",
  billingSubjectType: "",
  billingStatus: "",
};

export default function Stores() {
  const [modal, setModal] = useState({ showModal: false });
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalElement, setTotalElement] = useState(0);
  const [storeState, storeDispatch] = useReducer(
    storeReducer,
    initialStoreState
  );
  const [searchState, searchDispatch] = useReducer(
    storeReducer,
    initialStoreState
  );
  const { mutateExcel } = useExcelDownload("get");
  const { mutateLogo } = useLogoDownload();
  const { mutateQR } = useQRDownload();

  const setPageStatus = useCallback(() => {
    const [
      _,
      page,
      storeStatus,
      storeAcceptStatus,
      storeName,
      ownerName,
      billingType,
      billingSubjectType,
      billingStatus,
    ] = location.search
      .replace(
        /\?page=|&storeStatus=|&storeAcceptStatus=|&storeName=|&ownerName=|&billingType=|&billingSubjectType=|&billingStatus=/g,
        " "
      )
      .split(" ");

    if (!page) {
      setPage(0);
      searchDispatch({ type: "changed_storeName", storeName: "" });
      searchDispatch({ type: "changed_ownerName", ownerName: "" });
      searchDispatch({
        type: "changed_status",
        storeStatus: "",
      });
      searchDispatch({
        type: "changed_acceptStatus",
        storeAcceptStatus: "",
      });
      searchDispatch({
        type: "changed_billingType",
        billingType: "",
      });
      searchDispatch({
        type: "changed_billingSubjectType",
        billingSubjectType: "",
      });
      searchDispatch({
        type: "changed_billingStatus",
        billingStatus: "",
      });
      return;
    }

    setPage(page);
    searchDispatch({
      type: "changed_storeName",
      storeName: decodeURI(storeName),
    });
    searchDispatch({
      type: "changed_ownerName",
      ownerName: decodeURI(ownerName),
    });
    searchDispatch({
      type: "changed_status",
      storeStatus: decodeURI(storeStatus),
    });
    searchDispatch({
      type: "changed_acceptStatus",
      storeAcceptStatus: decodeURI(storeAcceptStatus),
    });
    searchDispatch({
      type: "changed_billingType",
      billingType: decodeURI(billingType),
    });
    searchDispatch({
      type: "changed_billingSubjectType",
      billingSubjectType: decodeURI(billingSubjectType),
    });
    searchDispatch({
      type: "changed_billingStatus",
      billingStatus: decodeURI(billingStatus),
    });
  }, [setPage, location]);

  useEffect(() => {
    setPageStatus();
  }, [setPageStatus]);

  // if (!stores) {
  //   return null;
  // }

  // const inquireStore = () => {
  //   const { storeStatus, storeAcceptStatus, storeName, ownerName } =
  //     storeSearchParams;

  //   navigate(
  //     `?page=0&storeStatus=${storeStatus}&storeAcceptStatus=${storeAcceptStatus}&storeName=${storeName}&ownerName=${ownerName}`
  //   );
  // };

  // function openDownloadModal() {
  //   const modalContent = {
  //     callbackOfOk: () =>
  //       downloadExcel({
  //         storeStatus: storeStatus,
  //         storeAcceptStatus: storeAcceptStatus,
  //       }),
  //     textOfBody: "엑셀를 다운로드 하시겠습니까?",
  //     showModal: true,
  //   };
  //   setModal(modalContent);
  // }
  // function downloadExcel(params) {
  //   const defaultParams = {
  //     page: 0,
  //     size: 10,
  //     storeStatus: "",
  //     storeAcceptStatus: "",
  //   };
  //   let requestParams = Object.assign({}, defaultParams, params);
  //   adminApi({
  //     url: "/stores/excel",
  //     params: requestParams,
  //     method: "get",
  //     customOption: { fileDownLoad: true },
  //     responseType: "arraybuffer",
  //   })
  //     .then(function (response) {
  //       console.log(response);
  //       const blob = new Blob([response.data]);
  //       const fileUrl = window.URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = fileUrl;
  //       link.style.display = "none";
  //       link.download = getFileName(response);
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //     })
  //     .catch(function (error) {});
  // }
  // function getFileName(response) {
  //   const disposition = response.headers["content-disposition"];

  //   const fileName = decodeURI(
  //     disposition
  //       .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
  //       .replace(/['"]/g, "")
  //   );
  //   return fileName;
  // }

  const tableHeaders = [
    [
      { label: "store id", rowSpan: 2 },
      { label: "가맹점명", rowSpan: 2 },
      { label: "대표명", rowSpan: 2 },
      { label: "운영 상태", rowSpan: 2 },
      { label: "승인 상태", rowSpan: 2 },
      { label: "계좌 승인 상태", rowSpan: 2 },
      { label: "외부 API 연동", rowSpan: 2 },
      { label: "수수료", rowSpan: 2 },
      { label: "개설일", rowSpan: 2 },
      { label: "빌링 정보", colSpan: 7 },
      { label: "가맹점 설정 관리", rowSpan: 2 },
    ],
    [
      {
        label: "청구 상태",
        style: { wordBreak: "keep-all", whiteSpace: "nowrap" },
      },
      {
        label: "최초 청구일",
        style: { wordBreak: "keep-all", whiteSpace: "nowrap" },
      },
      {
        label: "계약 금액",
        style: { wordBreak: "keep-all", whiteSpace: "nowrap" },
      },
      {
        label: "청구 타입",
        style: { wordBreak: "keep-all", whiteSpace: "nowrap" },
      },
      {
        label: "납입 주체",
        style: { wordBreak: "keep-all", whiteSpace: "nowrap" },
      },
      {
        label: "정보 입력",
        style: { wordBreak: "keep-all", whiteSpace: "nowrap" },
      },
      {
        label: "빌링 기록",
        style: { wordBreak: "keep-all", whiteSpace: "nowrap" },
      },
    ],
  ];

  const handleExcelDownBtnClick = () => {
    const {
      storeStatus,
      storeAcceptStatus,
      ownerName,
      storeName,
      billingType,
      billingSubjectType,
      billingStatus,
    } = searchState;
    const modalContent = {
      callbackOfOk: () =>
        mutateExcel({
          url: "/stores/excel",
          params: {
            ...(ownerName && { ownerName }),
            ...(storeName && { storeName }),
            ...(storeStatus && { storeStatus }),
            ...(storeAcceptStatus && { storeAcceptStatus }),
            ...(billingType && { billingType }),
            ...(billingSubjectType && { billingSubjectType }),
            ...(billingStatus && { billingStatus }),
          },
        }),
      textOfBody: "엑셀을 다운로드 하시겠습니까?",
      showModal: true,
    };

    setModal(modalContent);
  };

  const getNavigateStr = (state, page = 0) => {
    return `?page=${page}&storeStatus=${state.storeStatus}&storeAcceptStatus=${state.storeAcceptStatus}&storeName=${state.storeName}&ownerName=${state.ownerName}&billingType=${state.billingType}&billingSubjectType=${state.billingSubjectType}&billingStatus=${state.billingStatus}`;
  };

  const handleSearchBtnClick = () => {
    searchDispatch({
      type: "changed_storeName",
      storeName: storeState.storeName,
    });
    searchDispatch({
      type: "changed_ownerName",
      ownerName: storeState.ownerName,
    });
    searchDispatch({
      type: "changed_status",
      storeStatus: storeState.storeStatus,
    });
    searchDispatch({
      type: "changed_acceptStatus",
      storeAcceptStatus: storeState.storeAcceptStatus,
    });
    searchDispatch({
      type: "changed_billingType",
      billingType: storeState.billingType,
    });
    searchDispatch({
      type: "changed_billingSubjectType",
      billingSubjectType: storeState.billingSubjectType,
    });
    searchDispatch({
      type: "changed_billingStatus",
      billingStatus: storeState.billingStatus,
    });
    navigate(getNavigateStr(storeState));
  };

  const handlePageMove = (page = 0) => {
    navigate(getNavigateStr(searchState, page));
  };

  const handleLogoDownBtnClick = (domain, logoUrl) => {
    mutateLogo({
      domain,
      logoUrl,
    });
  };

  const handleQRDownBtnClick = (domain) => {
    mutateQR(domain);
  };

  const handleInputKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }

    handleSearchBtnClick();
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">가맹점 목록 ver1</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElement}</h5>
              </Col>
              <Row className="p-1 justify-content-end position-relative">
                <Col
                  style={{
                    color: "#ff0000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: 0,
                  }}
                >
                  *가맹점별 발행 한도, 허용된 기능 등은{" "}
                  <strong>[가맹점 설정 관리]</strong>의 <strong>'관리'</strong>
                  버튼을 통해 확인 및 수정 가능합니다.*
                </Col>
                <Col xs={"auto"} className="btn-black" style={{ zIndex: 1 }}>
                  <Btn
                    type="API"
                    btntext="엑셀 다운"
                    onClick={handleExcelDownBtnClick}
                  />
                </Col>
              </Row>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "820px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">가맹점명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          defaultValue={searchState.storeName}
                          onChange={({ target }) =>
                            storeDispatch({
                              type: "changed_storeName",
                              storeName: target.value,
                            })
                          }
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="mb-3">
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">사장님명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={({ target }) =>
                            storeDispatch({
                              type: "changed_ownerName",
                              ownerName: target.value,
                            })
                          }
                          type="text"
                          id="ownerName"
                          aria-describedby="passwordHelpBlock"
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="p-1 align-items-center justify-content-center">
                  {/* 매장 운영 상태 */}
                  <Col xs={12} className="mb-4">
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">운영 상태</h5>
                      </Col>
                      <Col>
                        <Form
                          className="form-radio"
                          onChange={({ target }) =>
                            storeDispatch({
                              type: "changed_status",
                              storeStatus: target.value,
                            })
                          }
                        >
                          <div key={`inline-radio`} className="radiobox">
                            {storeStatuses.map(({ label, value }, index) => (
                              <Form.Check
                                key={`filter1-${index}`}
                                defaultChecked={label === "전체"}
                                inline
                                label={label}
                                name="Filter-1"
                                type={"radio"}
                                id={`inline-radio-${index + 1}`}
                                value={value}
                              />
                            ))}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  {/* 매장 승인 상태 */}
                  <Col xs={12} className="mb-4">
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">승인 상태</h5>
                      </Col>
                      <Col>
                        <Form
                          className="form-radio"
                          onChange={({ target }) =>
                            storeDispatch({
                              type: "changed_acceptStatus",
                              storeAcceptStatus: target.value,
                            })
                          }
                        >
                          <div key={`inline-radio`} className="radiobox d-flex">
                            {storeAcceptStatuses.map(
                              ({ label, value }, index) => (
                                <Form.Check
                                  key={`filter2-${index}`}
                                  defaultChecked={label === "전체"}
                                  inline
                                  label={label}
                                  name="Filter-2"
                                  type={"radio"}
                                  id={`inline2-radio-${index + 1}`}
                                  value={value}
                                />
                              )
                            )}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  {/* 청구 타입 */}
                  <Col xs={12} className="mb-4">
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">청구 타입</h5>
                      </Col>
                      <Col>
                        <Form
                          className="form-radio"
                          onChange={({ target }) =>
                            storeDispatch({
                              type: "changed_billingType",
                              billingType: target.value,
                            })
                          }
                        >
                          <div key={`inline-radio`} className="radiobox d-flex">
                            {billingTypes.map(({ label, value }, index) => (
                              <Form.Check
                                key={`filter3-${index}`}
                                defaultChecked={label === "전체"}
                                inline
                                label={label}
                                name="Filter-3"
                                type={"radio"}
                                id={`inline3-radio-${index + 1}`}
                                value={value}
                              />
                            ))}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  {/* 청구 납입 주체 */}
                  <Col xs={12} className="mb-4">
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">납입 주체</h5>
                      </Col>
                      <Col>
                        <Form
                          className="form-radio"
                          onChange={({ target }) =>
                            storeDispatch({
                              type: "changed_billingSubjectType",
                              billingSubjectType: target.value,
                            })
                          }
                        >
                          <div key={`inline-radio`} className="radiobox d-flex">
                            {billingSubjectTypes.map(
                              ({ label, value }, index) => (
                                <Form.Check
                                  key={`filter4-${index}`}
                                  defaultChecked={label === "전체"}
                                  inline
                                  label={label}
                                  name="Filter-4"
                                  type={"radio"}
                                  id={`inline4-radio-${index + 1}`}
                                  value={value}
                                />
                              )
                            )}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  {/* 청구 상태 */}
                  <Col xs={12} className="mb-4">
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">청구 상태</h5>
                      </Col>
                      <Col>
                        <Form
                          className="form-radio"
                          onChange={({ target }) =>
                            storeDispatch({
                              type: "changed_billingStatus",
                              billingStatus: target.value,
                            })
                          }
                        >
                          <div key={`inline-radio`} className="radiobox d-flex">
                            {billingStatuses.map(({ label, value }, index) => (
                              <Form.Check
                                key={`filter5-${index}`}
                                defaultChecked={label === "전체"}
                                inline
                                label={label}
                                name="Filter-5"
                                type={"radio"}
                                id={`inline5-radio-${index + 1}`}
                                value={value}
                              />
                            ))}
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={"auto"}>
                    <Row className="align-items-center justify-content-center filter-btnbox">
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="조회 하기"
                          onClick={handleSearchBtnClick}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4"
                responsive
              >
                <thead className="text-center">
                  {tableHeaders.map((rows, index) => {
                    return (
                      <tr key={index}>
                        {rows.map((row, colIndex) => {
                          return (
                            <th
                              key={`row${index}-col${colIndex}`}
                              rowSpan={row.rowSpan ?? 1}
                              colSpan={row.colSpan ?? 1}
                              style={row?.style ?? null}
                            >
                              {row.label}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>

                <tbody className="text-center" style={{ position: "relative" }}>
                  <Suspense
                    fallback={
                      <tr style={{ height: "250px" }}>
                        <Spinner
                          as={"td"}
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            marginLeft: "-18px",
                          }}
                          animation="border"
                        />
                      </tr>
                    }
                  >
                    <StoreBoard
                      setTotalElement={setTotalElement}
                      setTotalPage={setTotalPage}
                      searchState={searchState}
                      page={page}
                      onLogoDownBtnClick={handleLogoDownBtnClick}
                      onQRDownBtnClick={handleQRDownBtnClick}
                      setModal={setModal}
                    />
                  </Suspense>
                </tbody>
              </Table>
              <DetailPagination
                currentPage={page}
                totalPage={totalPage}
                onPageMove={handlePageMove}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {modal.showModal && (
        <CustomModal
          callbackOfOk={modal.callbackOfOk}
          textOfHeader={modal.textOfHeader}
          textOfBody={modal.textOfBody}
          setModal={setModal}
        />
      )}
    </>
  );
}

// 매장 운영 상태
const storeStatuses = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "운영중",
    value: "OPEN",
  },
  {
    label: "운영중단",
    value: "PAUSE",
  },
  {
    label: "심사중",
    value: "UNDER_REVIEW ",
  },
  {
    label: "가맹점탈퇴",
    value: "CLOSED",
  },
];

// 매장 승인 상태
const storeAcceptStatuses = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "승인",
    value: "ACCEPTED",
  },
  {
    label: "승인요청",
    value: "REQUEST",
  },
  {
    label: "작성중",
    value: "WRITING",
  },
  {
    label: "거절",
    value: "REFUSED",
  },
];

// 청구 방식
const billingTypes = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "해당 없음",
    value: "NONE",
  },
  {
    label: "월 주기",
    value: "MONTHLY",
  },
  {
    label: "연 주기",
    value: "YEARLY",
  },
];

// 납입 주체 타입
const billingSubjectTypes = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "해당 없음",
    value: "NONE",
  },
  {
    label: "직접 납입",
    value: "OWN",
  },
  {
    label: "그룹",
    value: "GROUP",
  },
];

// 청구 상태
const billingStatuses = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "청구 해당없음",
    value: "NONE",
  },
  {
    label: "청구 중",
    value: "ON",
  },
  {
    label: "청구 정지",
    value: "OFF",
  },
  {
    label: "청구 일시중지",
    value: "PAUSE",
  },
];

{
  /* <tr>
                    <th rowSpan={2}>store id</th>
                    <th rowSpan={2}>가맹점명</th>
                    <th rowSpan={2}>대표명</th>
                    <th rowSpan={2}>운영 상태</th>
                    <th rowSpan={2}>승인 상태</th>
                    <th rowSpan={2}>계좌 승인 상태</th>
                    <th rowSpan={2}>외부 API 연동</th>
                    <th>팝빌 연동</th>
                    <th>토스 연동</th>
                    <th>상품 목록</th>
                    <th>로고 다운로드</th>
                    <th>스토어 바로가기</th>
                    <th>QR다운로드</th>
                    <th rowSpan={2}>수수료</th>
                    <th rowSpan={2}>개설일</th>
                    <th>총 발행한도</th>
                    <th>1회당 발행한도</th>
                    <th>멤버십 당 보유한도</th>
                    <th colSpan={5}>빌링 정보</th>
                    <th rowSpan={2}>가맹점 설정 관리</th>
                    <th>주문형</th>
                    <th>패스방식</th>
                    <th>추가 알림톡 사용여부</th>
                    <th>자진발급</th>
                    <th>현장발행</th>
                    <th>대량발행</th>
                    <th>예약상품</th>
                    <th>출입관제</th>
                    <th>메뉴 주문 자동취소</th>
                    <th>키즈카페 솔루션</th>
                    <th>프로모션 코드</th>
                    <th>후불결제방식</th>

                    <th>테스트 유무</th>
                  </tr>
                  <tr>
                    <th style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
                      청구 상태
                    </th>
                    <th style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
                      최초 청구일
                    </th>
                    <th style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
                      계약 금액
                    </th>
                    <th style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
                      청구 타입
                    </th>
                    <th style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}>
                      납입 주체
                    </th>
                  </tr> */
}
