import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const KioskInfoForm = ({
  data = {},
  onChangeData = () => {},
  refs = null,
  isEdit,
}) => {
  return (
    <Col
      xs={8}
      style={{ gap: "1rem", flexDirection: "column", display: "flex" }}
    >
      {isEdit && (
        <Form.Group className="Formbox">
          <Form.Label>관리자 id</Form.Label>
          <Form.Control
            placeholder="관리자 id를 입력해주세요."
            value={data.managerId}
            onChange={(e) => onChangeData(e.target.value, "managerId")}
            required
            disabled
          />
        </Form.Group>
      )}
      <Form.Group className="Formbox">
        <Form.Label>키오스크 Mac id</Form.Label>
        <Form.Control
          placeholder="키오스크 Mac id를 입력해주세요."
          value={data.kioskId}
          onChange={(e) => onChangeData(e.target.value, "kioskId")}
          required
          disabled={isEdit ? true : false}
        />
      </Form.Group>
      <Form.Group className="Formbox">
        <Form.Label>설치 위치</Form.Label>
        <Form.Control
          placeholder="예) BIFC 1층 구내식당 앞"
          value={data.installLocation}
          onChange={(e) => onChangeData(e.target.value, "installLocation")}
          required
        />
      </Form.Group>
      <Form.Group className="Formbox">
        <Form.Label>설치일</Form.Label>
        <Form.Control
          type="date"
          placeholder="예) 2023-09-20"
          value={data.installDate}
          onChange={(e) => onChangeData(e.target.value, "installDate")}
          required
        />
      </Form.Group>
      <Form.Group className="Formbox">
        <Form.Label>설치 담당자</Form.Label>
        <Form.Control
          placeholder="설치 담당자 이름을 입력해주세요."
          value={data.installManager}
          onChange={(e) => onChangeData(e.target.value, "installManager")}
          required
        />
      </Form.Group>
      <Form.Group className="Formbox">
        <Form.Label>운영 상태</Form.Label>
        <Row style={{ display: "flex" }}>
          <Form.Check
            style={{
              width: "fit-content",
            }}
            type="radio"
          >
            <Form.Check.Input
              type="radio"
              style={{
                fontSize: "14px",
                marginRight: "8px",
              }}
              value={"OPEN"}
              checked={data.kioskStatus === "OPEN"}
              onChange={() => onChangeData("OPEN", "kioskStatus")}
            />
            <Form.Label>운영중</Form.Label>
          </Form.Check>
          <Form.Check
            style={{
              width: "fit-content",
            }}
            type="radio"
          >
            <Form.Check.Input
              type="radio"
              style={{
                fontSize: "14px",
                marginRight: "8px",
              }}
              value={"CLOSE"}
              checked={data.kioskStatus === "CLOSE"}
              onChange={() => onChangeData("CLOSE", "kioskStatus")}
            />
            <Form.Label>운영안함</Form.Label>
          </Form.Check>
        </Row>
      </Form.Group>
    </Col>
  );
};

export default KioskInfoForm;
