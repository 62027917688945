import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { apiClient } from "../../reactQuery/api";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";

const useExportExcel = ({
  sheetColumn = [],
  sheetName,
  exportTargetName = "result",
  dataFormatter,
  customFormatter,
  apiUrl,
  params,
  apiTypePost,
}) => {
  const workSheetName = sheetName ?? exportTargetName ?? "result";

  const { mutate: exportExcel, isLoading: exportLoading } = useMutation(
    async () => {
      if (apiTypePost) {
        return await apiClient({
          url: apiUrl,
          params,
          method: "post",
        }).then((res) => res.data);
      }
      return await apiClient({
        url: apiUrl,
        params,
        method: "get",
      }).then((res) => res.data);
    },
    {
      onSuccess: async (data) => {
        if (!data) return;
        const result = dataFormatter ? dataFormatter(data) : data;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(workSheetName);
        worksheet.columns = sheetColumn;
        const keyValue = sheetColumn.map((col) => col.key);
        for (let row of result) {
          let tempObj = {};
          keyValue.forEach(
            (key) =>
              (tempObj[key] = customFormatter
                ? customFormatter(row, key)
                : row[key])
          );
          worksheet.addRow(tempObj);
        }
        const mimeType = {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        };
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], mimeType);
        saveAs(
          blob,
          `${exportTargetName}_${moment().format("YYYY.MM.DD")}.xlsx`
        );
      },
      onError: (err) => alert(err.message),
    }
  );

  return { exportExcel, exportLoading };
};

export default useExportExcel;
