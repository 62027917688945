import moment from "moment";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import adminApi from "../../../function/apiConfig";

const Td = ({ children }) => {
  return (
    <td
      style={{
        wordBreak: "keep-all",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </td>
  );
};

export default function StoreGroupBoard({
  data,
  onClickStoreList = () => {},
  refetch,
}) {
  const navigate = useNavigate();
  const mappedCardInfo = data.map((card) => {
    const {
      storeGroupId = 0,
      hqStore = {
        storeId: 0,
        storeName: "",
      },
      storeList = [],
      feeRate = 0,
      desc = null,
      logImageUrl = null,
      name = "",
      useEntireMembershipQuery = false,
      useMenuSync = false,
    } = card;

    function renderRadioByStatus({ status, onClick }) {
      return (
        <>
          <Form.Check
            checked={status}
            style={{ cursor: "pointer" }}
            onChange={() => onClick()}
          />
        </>
      );
    }

    const onClickEntireMembership = () => {
      const confirm = window.confirm(
        `${name} 그룹 전체 멤버십 열람 기능 ${
          !useEntireMembershipQuery ? "사용하도록" : "사용하지 않도록"
        } 변경 하시겠습니까?`
      );
      if (confirm) {
        adminApi
          .put(`store-group/${storeGroupId}/store-group-function`, {
            storeGroupFunctionType: "ENTIRE_MEMBERSHIP_QUERY",
            use: !useEntireMembershipQuery,
          })
          .then(function (response) {
            alert(
              `${name} 그룹 전체 멤버십 열람 기능 ${
                response?.useEntireMembershipQuery
                  ? "사용하도록"
                  : "사용하지 않도록"
              } 변경 되었습니다.`
            );
            refetch();
          })
          .catch(() => {
            alert("에러가 발생했습니다. 개발팀에 문의해주세요.");
          });
      }
      return;
    };

    const onClickMenuSync = () => {
      const menuConfirm = window.confirm(
        `${name} 그룹 메뉴 동기화 기능 ${
          !useMenuSync ? "사용하도록" : "사용하지 않도록"
        } 변경 하시겠습니까?`
      );
      if (menuConfirm) {
        adminApi
          .put(`store-group/${storeGroupId}/store-group-function`, {
            storeGroupFunctionType: "MENU_SYNC",
            use: !useMenuSync,
          })
          .then(function (response) {
            console.log(response, "response");
            alert(
              `${name} 그룹 메뉴 동기화 기능 ${
                response?.useMenuSync
                  ? "사용하도록"
                  : "사용하지 않도록"
              } 변경 되었습니다.`
            );
            refetch();
          })
          .catch(() => {
            alert("에러가 발생했습니다. 개발팀에 문의해주세요.");
          });
      }
    };

    return (
      <tr key={storeGroupId} className="board-row-except">
        <Td>{storeGroupId}</Td>
        <Td>{name ?? "-"}</Td>
        <Td>{desc ?? "-"}</Td>
        <Td>{hqStore ? hqStore.storeName : "-"}</Td>
        {/* <Td>{storeList ? storeList.length : "-"}</Td> */}
        {/* <Td>{logImageUrl ? logImageUrl : "-"}</Td> */}
        <Td>{feeRate ?? "-"}</Td>
        <Td>
          {renderRadioByStatus({
            status: useEntireMembershipQuery,
            onClick: onClickEntireMembership,
          })}
        </Td>
        {/* {storeList.length > 0 && ( */}
        {/* <Button
            onClick={() =>
              onClickStoreList({ storeGroupId, storeList, groupName: name })
            }
          >
            개별 권한 수정
          </Button> */}
        {/* )} */}
        <Td>
          {renderRadioByStatus({
            status: useMenuSync,
            onClick: onClickMenuSync,
          })}
        </Td>
        <Td>
          {storeGroupId && (
            <Button
              onClick={() =>
                navigate(`/admin/store/group/detail?id=${storeGroupId}`)
              }
            >
              상세보기
            </Button>
          )}
        </Td>
      </tr>
    );
  });

  return mappedCardInfo;
}
