import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../reactQuery/api";
import { queryKeys } from "../../reactQuery/constants";

const POST_SIZE = 10;

export function useAdmins({ page, setPageInfo }) {
  const { data: admins } = useQuery(
    [queryKeys.admin, String(page)],
    async () => {
      const { data } = await apiClient({
        url: `/admins`,
        params: {
          page,
          size: POST_SIZE,
        },
      });

      return data;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        setPageInfo({
          totalElements: response.totalElements,
          totalPages: response.totalPages,
        });
      },
    }
  );

  return { admins };
}
