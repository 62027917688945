import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import LeftMenu from "../../../components/admin/LeftMenu";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../reactQuery/api";
import StoreSearchModal from "../../../components/store/StoreSearchModal";
import { set } from "date-fns";

const getFileUrl = (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
};

const useIntersectionObserver = ({
  threshold = 0.1,
  hasNextPage,
  fetchNextPage,
}) => {
  const [target, setTarget] = useState(null);
  const observerCallback = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
    },
    [fetchNextPage, hasNextPage]
  );

  useEffect(() => {
    if (!target) return;
    const observer = new IntersectionObserver(observerCallback, { threshold });

    observer.observe(target);

    return () => observer.unobserve(target);
  }, [observerCallback, threshold, target]);

  return { setTarget };
};

const StoreGroupDetail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get("id") ?? null;
  const isEdit = Boolean(groupId);

  const [storeSearchModal, setStoreSearchModal] = useState({
    show: false,
    callback: () => {},
  });
  const [hqStore, setHqStore] = useState({ storeName: "", id: 0 });
  const [storeGroupName, setStoreGroupName] = useState("");
  const [storeGroupDescription, setStoreGroupDescription] = useState("");
  const [storeGroupType, setStoreGroupType] = useState("UNION");
  const [chainStoreList, setChainStoreList] = useState([]);
  const [feeRate, setFeeRate] = useState("");
  const [image, setImage] = useState({ file: null, url: "" });
  const [deleteChainStoreList, setDeleteChainStoreList] = useState([]);
  const [useEntireMembershipQuery, setUseEntireMembershipQuery] =
    useState(true);
  const [useMenuSync, setUseMenuSync] = useState(true);

  const [storesKeyword, setStoresKeyword] = useState("");
  const [keywordType, setKeywordType] = useState("storeName");
  // console.log(chainStoreList);
  // 수정일때 기존 정보 불러오기

  const groupType = [
    {
      label: "연합 (UNION)",
      value: "UNION",
      code: 1,
    },
    {
      label: "프렌차이즈 (FRANCHISE)",
      value: "FRANCHISE",
      code: 2,
    },
    {
      label: "워케이션 (WORCATION)",
      value: "WORCATION",
      code: 3,
    },
  ];

  const {
    isLoading: detailQueryLoading,
    refetch: detailQueryRefetch,
    data: detailQueryData = { storeList: [] },
  } = useQuery(
    ["storeGroupDetailQuery", groupId],
    () =>
      apiClient({
        url: `/store-group/search/${groupId}`,
        method: "get",
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        const {
          hqStore,
          description,
          feeRate,
          logImageUrl,
          name,
          storeList,
          type,
          useEntireMembershipQuery,
          useMenuSync,
        } = res;
        setHqStore(hqStore);
        setStoreGroupName(name);
        setStoreGroupDescription(description);
        setChainStoreList(
          storeList
            .filter((store) => !store.isDeleted)
            .map((store) => ({
              ...store,
              ...store.store,
              id: store.store.storeId,
            }))
        );
        setStoreGroupType(type.name);
        setFeeRate(feeRate);
        setImage({ url: logImageUrl });
        setUseEntireMembershipQuery(useEntireMembershipQuery);
        setUseMenuSync(useMenuSync);
      },
      enabled: false,
    }
  );

  const onClickSetChainStoreList = (store) => {
    if (isEdit) {
      if (
        detailQueryData.storeList
          .filter((chainStore) => !chainStore.isDeleted)
          .findIndex((chainStore) => chainStore.store.storeId === store.id) ===
        -1
      ) {
        if (
          chainStoreList.findIndex(
            (chainStore) => chainStore.id === store.id
          ) === -1
        ) {
          setChainStoreList((prev) => [...prev, { ...store, isNew: true }]);
        } else {
          setChainStoreList((prev) =>
            prev.filter((chainStore) => chainStore.id !== store.id)
          );
        }
      } else {
        if (
          deleteChainStoreList.findIndex(
            (chainStore) => chainStore.id === store.id
          ) === -1
        ) {
          setDeleteChainStoreList((prev) => [...prev, store]);
        }
        if (
          chainStoreList.findIndex(
            (chainStore) => chainStore.id === store.id
          ) !== -1
        ) {
          setChainStoreList((prev) =>
            prev.filter((chainStore) => chainStore.id !== store.id)
          );
        }
      }
    } else {
      if (
        chainStoreList.findIndex((chainStore) => chainStore.id === store.id) ===
        -1
      ) {
        setChainStoreList((prev) => [...prev, store]);
      } else {
        setChainStoreList(
          chainStoreList.filter((chainStore) => chainStore.id !== store.id)
        );
      }
    }
  };

  const onClickUpload = async ({ target: { files } }) => {
    const file = files[0];
    let url = "";
    if (files) {
      url = await getFileUrl(file);
    }
    return { url, file };
  };

  const createGroupMutation = useMutation(
    async ({
      image,
      hqStoreId,
      chainStoreIdList,
      storeGroupName,
      storeGroupDescription,
      type,
      feeRate,
      useEntireMembershipQuery,
      useMenuSync,
    }) => {
      const formData = new FormData();
      formData.append(
        "request",
        new Blob(
          [
            JSON.stringify({
              hqStoreId,
              chainStoreIdList,
              storeGroupName,
              storeGroupDescription,
              type,
              feeRate,
              useEntireMembershipQuery,
              useMenuSync,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
      if (image) {
        formData.append("image", image);
      }
      return apiClient({
        url: "/store-group/create",
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => res.data);
    },
    {
      onSuccess: (res) => {
        if (!res) return;
        alert("그룹이 생성되었습니다.");
        navigate("/admin/store/group", { replace: true });
      },
    }
  );

  const updateGroupMutation = useMutation(
    async ({
      storeGroupId,
      image,
      hqStoreId,
      newChainStoreList,
      deleteChainStoreList,
      storeGroupName,
      storeGroupDescription,
      feeRate,
      useEntireMembershipQuery,
      useMenuSync,
    }) => {
      const formData = new FormData();
      formData.append(
        "request",
        new Blob(
          [
            JSON.stringify({
              storeGroupId,
              hqStoreId,
              newChainStoreList,
              deleteChainStoreList,
              storeGroupName,
              storeGroupDescription,
              feeRate,
              useEntireMembershipQuery,
              useMenuSync,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );
      if (image) {
        formData.append("image", image);
      }
      return apiClient({
        url: "/store-group/update",
        method: "put",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => res.data);
    },
    {
      onSuccess: (res) => {
        if (!res) return;
        alert("그룹이 수정되었습니다.");
        navigate("/admin/store/group", { replace: true });
      },
    }
  );

  const onClickSubmit = () => {
    if (isEdit) {
      if (updateGroupMutation.isLoading) return;
      const { storeId: hqStoreId } = hqStore;
      const newChainStoreIdList = chainStoreList
        .filter((store) => store.isNew)
        .map((store) => store.id);
      const deleteChainStoreIdList = deleteChainStoreList.map(
        (store) => store.id
      );
      const requestImage = image?.file ?? image?.url ?? null;
      updateGroupMutation.mutate({
        image: requestImage,
        hqStoreId: hqStore?.storeId ?? hqStore.id ?? hqStoreId,
        newChainStoreList: newChainStoreIdList,
        deleteChainStoreList: deleteChainStoreIdList,
        storeGroupName,
        storeGroupDescription,
        feeRate,
        storeGroupId: groupId,
        useEntireMembershipQuery,
        useMenuSync,
      });
      return;
    }
    if (createGroupMutation.isLoading) return;
    const { id: hqStoreId } = hqStore;
    const chainStoreIdList = chainStoreList.map((chainStore) => chainStore.id);
    const requestImage = image?.file ?? null;
    createGroupMutation.mutate({
      image: requestImage,
      hqStoreId,
      chainStoreIdList,
      storeGroupName,
      storeGroupDescription,
      type: storeGroupType,
      feeRate,
      useEntireMembershipQuery,
      useMenuSync,
    });
  };

  const {
    data: storesData = { pages: [] },
    fetchNextPage: fetchStoresNextPage,
    hasNextPage: hasStoresNextPage,
  } = useInfiniteQuery(
    ["getAllStoresQuery", isEdit],
    async ({ pageParam = 0 }) =>
      await apiClient({
        url: "/stores",
        params: {
          storeStatus: "OPEN",
          storeAcceptStatus: "ACCEPTED",
          ...(keywordType === "storeName" && { storeName: storesKeyword }),
          ...(keywordType === "ownerName" && { storeName: storesKeyword }),
          page: pageParam,
          size: 10,
        },
      }).then((res) => res.data),
    {
      select: (data) => {
        return {
          ...data,
          pageParams: data.pageParams,
        };
      },
      getNextPageParam: (lastPage) => {
        if (
          !lastPage ||
          typeof lastPage.number !== "number" ||
          typeof lastPage.totalPages !== "number"
        ) {
          console.error("Invalid lastPage structure:", lastPage);
          return undefined;
        }
        const currentPageNumber = lastPage.number;
        const totalPages = lastPage.totalPages;
        const nextPageNumber = currentPageNumber + 1;
        return nextPageNumber < totalPages ? nextPageNumber : undefined;
      },
      enabled: Boolean(!isEdit),
    }
  );

  const {
    data: addableStoresData = { pages: [] },
    fetchNextPage: fetchAddableStoresNextPage,
    hasNextPage: hasAddableStoresNextPage,
  } = useInfiniteQuery(
    ["getAddableStoresQuery", isEdit],
    async ({ pageParam = 0 }) =>
      await apiClient({
        url: `/store-group/${groupId}/addable`,
        params: {
          page: pageParam,
          size: 10,
        },
      }).then((res) => {
        return {
          ...res.data,
          content: res.data.content.map((store) => ({
            ...store,
            id: store.storeId,
          })),
        };
      }),
    {
      select: (data) => {
        return {
          ...data,
          pageParams: data.pageParams,
        };
      },
      // getNextPageParam: (lastPage, pages) => {
      //   const nextPage = pages?.length;
      //   return lastPage?.data?.length === 0 ? undefined : nextPage;
      // },
      getNextPageParam: (lastPage) => {
        const currentPageNumber = lastPage.number;
        const totalPages = lastPage.totalPages;
        const nextPageNumber = currentPageNumber + 1;
        return nextPageNumber < totalPages ? nextPageNumber : undefined;
      },
      enabled: Boolean(isEdit),
    }
  );

  const { setTarget } = useIntersectionObserver({
    hasNextPage: isEdit ? hasAddableStoresNextPage : hasStoresNextPage,
    fetchNextPage: isEdit ? fetchAddableStoresNextPage : fetchStoresNextPage,
  });

  const data = isEdit ? addableStoresData : storesData;

  useEffect(() => {
    if (groupId) {
      detailQueryRefetch();
    }
  }, [groupId]);

  if (isEdit && detailQueryLoading) {
    return <></>;
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h2 className="fw-bold">
                  {isEdit ? "가맹점 그룹 수정" : "가맹점 그룹 생성"}
                </h2>
              </Col>
            </Row>
            {!isEdit || !hqStore.storeName ? (
              <Col>
                <Row className="mt-3 mb-4 align-items-end">
                  <Col>
                    <Form.Label>
                      그룹 헤더 가맹점 <span className="PointText"> * </span>
                    </Form.Label>
                    <Form.Control
                      readOnly
                      placeholder="검색을 통해 그룹 헤더 가맹점을 선택해주세요."
                      value={hqStore.storeName}
                    />
                  </Col>
                  <Col>
                    <Button
                      onClick={() =>
                        setStoreSearchModal({
                          show: true,
                          callback: (store) => setHqStore(store),
                        })
                      }
                      type="button"
                    >
                      검색
                    </Button>
                    {isEdit && (
                      <Button
                        onClick={() => setHqStore(detailQueryData.hqStore)}
                        type="button"
                        className="mx-2"
                      >
                        취소
                      </Button>
                    )}
                  </Col>
                </Row>
                <Col className="mt-3 mb-4 align-items-end">
                  <Form.Label>
                    그룹 타입 <span className="PointText"> * </span>
                  </Form.Label>
                  <Form.Select
                    value={storeGroupType}
                    onChange={(e) => {
                      setStoreGroupType(e.target.value);
                    }}
                    style={{ width: "fit-content" }}
                  >
                    {groupType.map((status) => (
                      <option key={status.value} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
                <Col md={{ span: 4 }} className="pb-4">
                  <Row
                    style={{
                      padding: "8px 4px",
                      width: "fit-content",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setUseEntireMembershipQuery(!useEntireMembershipQuery);
                      // onClickSetChainStoreList(row);
                    }}
                  >
                    <Col md={{ span: 1 }}>
                      <Form.Check
                        type="checkbox"
                        checked={useEntireMembershipQuery}
                        readOnly
                      />
                    </Col>
                    <Col>그룹원 멤버십 전체공유</Col>
                  </Row>
                  <Row
                    style={{
                      padding: "8px 4px",
                      width: "fit-content",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setUseMenuSync(!useMenuSync);
                      // onClickSetChainStoreList(row);
                    }}
                  >
                    <Col md={{ span: 1 }}>
                      <Form.Check
                        type="checkbox"
                        checked={useMenuSync}
                        readOnly
                      />
                    </Col>
                    <Col>하위 가맹점 동기화 허용</Col>
                  </Row>
                </Col>
              </Col>
            ) : (
              <Row className="p-1">
                {/* 2개형 */}
                <Col md={{ span: 3 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="storeName">
                    <Form.Label>
                      그룹 아이디 <span className="PointText"> * </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="그룹 헤더 가맹점 아이디"
                      value={groupId}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 4 }} className="pb-4">
                  <Form.Group className="xs-12" controlId="status">
                    <Form.Label>
                      그룹 헤더 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="그룹 헤더 가맹점 명"
                      value={hqStore.storeName}
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 4 }} className="pb-4">
                  <Row>
                    <Button
                      onClick={() => setHqStore({ storeName: "", id: 0 })}
                    >
                      헤더 재설정
                    </Button>
                  </Row>
                  <Row className="mt-2">
                    <Button onClick={() => setHqStore(detailQueryData.hqStore)}>
                      초기화
                    </Button>
                  </Row>
                </Col>
                <Form.Group className="xs-12" controlId="status">
                  <Form.Label>
                    그룹 타입 <span className="PointText">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="그룹 헤더 가맹점 명"
                    value={storeGroupType}
                    readOnly={true}
                  />
                </Form.Group>
                {/* 여기 */}
                <Col md={{ span: 4 }} className="pb-4">
                  <Row
                    style={{
                      padding: "8px 4px",
                      width: "fit-content",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setUseEntireMembershipQuery(!useEntireMembershipQuery);
                      // onClickSetChainStoreList(row);
                    }}
                  >
                    <Col md={{ span: 1 }}>
                      <Form.Check
                        type="checkbox"
                        checked={useEntireMembershipQuery}
                        readOnly
                      />
                    </Col>
                    <Col>그룹원 멤버십 전체공유</Col>
                  </Row>
                  <Row
                    style={{
                      padding: "8px 4px",
                      width: "fit-content",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setUseMenuSync(!useMenuSync);
                      // onClickSetChainStoreList(row);
                    }}
                  >
                    <Col md={{ span: 1 }}>
                      <Form.Check
                        type="checkbox"
                        checked={useMenuSync}
                        readOnly
                      />
                    </Col>
                    <Col>하위 가맹점 동기화 허용</Col>
                  </Row>
                </Col>
                {/* <Form.Check
                
                  type="checkbox"
                  // checked={
                  //   chainStoreList.findIndex(
                  //     (chainStore) =>
                  //       chainStore.id === row.id
                  //   ) !== -1
                  // }
                  checked={useEntireMembershipQuery}
                  readOnly
                />
                <Form.Check
                  type="checkbox"
                  // checked={
                  //   chainStoreList.findIndex(
                  //     (chainStore) =>
                  //       chainStore.id === row.id
                  //   ) !== -1
                  // }
                  checked={useMenuSync}
                  readOnly
                /> */}
              </Row>
            )}
            <Row className="p-1">
              {/* 2개형 */}
              <Col md={{ span: 7 }} className="pb-4">
                <Form.Group className="xs-12" controlId="storeName">
                  <Form.Label>
                    그룹 명 <span className="PointText"> * </span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="그룹명을 입력하세요."
                    value={storeGroupName}
                    onChange={(e) => setStoreGroupName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="p-1">
              <Col md={{ span: 7 }} className="pb-4">
                <Form.Group className="xs-12" controlId="status">
                  <Form.Label>
                    그룹 설명 <span className="PointText">*</span>
                  </Form.Label>
                  <Form.Control
                    as={"textarea"}
                    rows={5}
                    type="text"
                    placeholder="그룹 설명을 입력하세요."
                    value={storeGroupDescription}
                    onChange={(e) => setStoreGroupDescription(e.target.value)}
                    style={{ resize: "none" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="p-1">
              <Col md={{ span: 7 }} className="pb-4">
                <Form.Group className="xs-12" controlId="status">
                  <Form.Label>수수료율 (예: 0.15 = 15%)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="추가적으로 적용할 수수료율이 있으면 소수점 표기로 입력해주세요."
                    value={feeRate}
                    onChange={(e) => setFeeRate(e.target.value)}
                    style={{ resize: "none" }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="p-1">
              <Col md={{ span: 7 }} className="pb-4">
                {groupId && image.url ? (
                  <Row>
                    <Row>현재 로고 이미지</Row>
                    <Row>
                      <img src={image.url} alt="groupLogo" />
                    </Row>
                    <Row className="mt-4">
                      <Button onClick={() => setImage({ file: null, url: "" })}>
                        변경하기
                      </Button>
                    </Row>
                  </Row>
                ) : (
                  <Form.Group className="xs-12" controlId="status">
                    <Form.Label>
                      그룹 로고 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) =>
                        onClickUpload(e).then(({ url, file }) =>
                          setImage({ url, file })
                        )
                      }
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row className="p-1">
              <Col md={{ span: 7 }} className="pb-4">
                <Form.Group className="xs-12" controlId="status">
                  <Form.Label>추가 가능한 그룹 가맹점 리스트</Form.Label>
                  <Row
                    style={{
                      height: 200,
                      overflowY: "auto",
                      position: "relative",
                      background: "#fff",
                    }}
                  >
                    {/* {data?.pages?.length > 0 && (
                      <Form.Control
                        placeholder="가맹점 명으로 검색 가능합니다."
                        style={{
                          position: "sticky",
                          top: 0,
                        }}
                      />
                    )} */}
                    {data?.pages?.length > 0 && (
                      <Row
                        style={{
                          borderBottom: `2px solid rgb(200, 200, 200)`,
                          padding: "8px 4px",
                          position: "sticky",
                          top: 0,
                          background: "#fff",
                        }}
                      >
                        <Col md={{ span: 2 }}>선택</Col>
                        <Col>store ID</Col>
                        <Col>가맹점 명</Col>
                        {/* <Col>대표자 명</Col> */}
                      </Row>
                    )}
                    {data?.pages?.length > 0 &&
                      data?.pages?.[0]?.content?.length > 0 &&
                      data.pages.map(
                        (rows = { content: [], number: 1 }, index) => {
                          const rowIndex = index;
                          return (
                            <Fragment key={index}>
                              {rows?.content?.map((row, index) => {
                                const isFirst = rowIndex === 0 && index === 0;
                                return (
                                  <Row
                                    key={index}
                                    style={{
                                      borderTop: `1px solid ${
                                        isFirst
                                          ? "transparent"
                                          : "rgb(200, 200, 200)"
                                      }`,
                                      padding: "8px 4px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onClickSetChainStoreList(row);
                                    }}
                                  >
                                    <Col md={{ span: 2 }}>
                                      <Form.Check
                                        type="checkbox"
                                        checked={
                                          chainStoreList.findIndex(
                                            (chainStore) =>
                                              chainStore.id === row.id
                                          ) !== -1
                                        }
                                        readOnly
                                      />
                                    </Col>
                                    <Col>{row.id}</Col>
                                    <Col>{row.storeName}</Col>
                                    {/* <Col>{row.ownerName}</Col> */}
                                  </Row>
                                );
                              })}
                            </Fragment>
                          );
                        }
                      )}
                    {data?.pages?.length > 0 &&
                      data.pages?.[data.pages.length - 1]?.totalElements >
                        data.pages?.[data.pages.length - 1]?.content
                          ?.length && <div ref={setTarget} />}
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row className="p-1">
              <Col md={{ span: 7 }} className="pb-4">
                <Form.Group className="xs-12" controlId="status">
                  <Form.Label>
                    추가된 그룹 가맹점 리스트 (가맹점 선택 시, 리스트에서 제거)
                  </Form.Label>
                  <Col
                    style={{
                      height: 200,
                      background: "#fff",
                      overflowY: "auto",
                      overflowX: "hidden",
                      position: "relative",
                    }}
                  >
                    {/* {data?.pages?.length > 0 && (
                      <Form.Control
                        placeholder="가맹점 명으로 검색 가능합니다."
                        style={{
                          position: "sticky",
                          top: 0,
                        }}
                      />
                    )} */}
                    <Row
                      style={{
                        borderBottom: `2px solid rgb(200, 200, 200)`,
                        padding: "8px 4px",
                        position: "sticky",
                        top: 0,
                        background: "#fff",
                      }}
                    >
                      <Col>store ID</Col>
                      <Col>가맹점 명</Col>
                      {/* <Col>대표자 명</Col> */}
                    </Row>
                    {chainStoreList.map((row, index) => {
                      const isFirst = index === 0;
                      return (
                        <Row
                          key={index}
                          style={{
                            borderTop: `1px solid ${
                              isFirst ? "transparent" : "rgb(200, 200, 200)"
                            }`,
                            padding: "8px 4px",
                          }}
                          onClick={() => onClickSetChainStoreList(row)}
                        >
                          <Col>{row.id}</Col>
                          <Col>{row.storeName}</Col>
                          {/* <Col>{row.ownerName}</Col> */}
                        </Row>
                      );
                    })}
                  </Col>
                </Form.Group>
              </Col>
              <Row className="p-1">
                <Col md={{ span: 2 }} style={{ marginLeft: "auto" }}>
                  <Button>돌아가기</Button>
                </Col>
                <Col>
                  <Button onClick={onClickSubmit}>
                    {isEdit ? "수정하기" : "생성하기"}
                  </Button>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
      {storeSearchModal.show && (
        <StoreSearchModal
          onClickAction={(store) => storeSearchModal.callback(store)}
          //   onClickCancel={() => setStoreSearchModal(false)}
          setModal={setStoreSearchModal}
        />
      )}
    </>
  );
};

export default StoreGroupDetail;
