import React, { Suspense, useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../reactQuery/api";
import LeftMenu from "../../../components/admin/LeftMenu";
import SimplePagination from "../../../components/admin/SimplePagination";
import DoorSystemBoard from "../../../components/doorSystem/DoorSystemBoard";
import { queryClient } from "../../../reactQuery/queryClient";
import { alertError, getErrorMessage } from "../../../services/axios";

const ManageDoorSystem = () => {
  const [page, setPage] = useState(0);
  const [pageInfo, setPageInfo] = useState({ totalElements: 0, totalPages: 0 });
  const [data, setData] = useState([]);
  const [type, setType] = useState("default");
  const [deleteTarget, setDeleteTarget] = useState([]);
  const navigate = useNavigate();

  const goToCreate = () => {
    navigate("/admin/doorSystems/create");
  };

  const goToManage = (id) => {
    navigate(`/admin/doorSystems/detail/${id}`);
  };

  //   삭제 뮤테이션
  const deleteMutation = useMutation(
    async ({ serverIds }) => {
      try {
        for (let i = 0; i < serverIds.length; i++) {
          await apiClient({
            url: "/door-system",
            method: "delete",
            data: {
              serverId: serverIds[i],
            },
          })
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              throw new Error(
                getErrorMessage(err) + `서버 ID : ${serverIds[i]}`
              );
            });
        }
      } catch (err) {
        alertError(err);
      }
    },
    {
      onSuccess: (res) => {
        if (!res) return;
        alertError("선택한 서버가 모두 삭제 완료되었습니다.");
        queryClient.invalidateQueries({
          queryKey: ["doorSystemList", page, pageInfo],
        });
      },
    }
  );

  const onClickDeleteDoorSystem = async (e) => {
    e.stopPropagation();
    if (type === "default") {
      setType("delete");
      return;
    }
    if (deleteTarget.length < 1) {
      alert("삭제할 서버를 선택해주세요.");
      return;
    }
    await deleteMutation.mutateAsync({
      serverIds: deleteTarget.map((target) => target.id),
    });
  };

  const onClickCheckAll = () => {
    if (deleteTarget.length < data.length) {
      setDeleteTarget(data.map((row) => ({ id: row.serverId })));
    } else {
      setDeleteTarget([]);
    }
  };

  useQuery(
    ["doorSystemList", page, pageInfo],
    async () =>
      await apiClient({
        url: `/door-system`,
        method: "get",
        params: {
          page,
          size: 10,
        },
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        if (!res) return;
        const { content, totalElements, totalPages } = res;
        setData(content);
        setPageInfo({ totalElements, totalPages });
      },
      staleTime: 0,
    }
  );

  return (
    <Container fluid>
      <Row>
        <Col xs={2} className="LeftBg px-0">
          <LeftMenu />
        </Col>
        <Col className="RightBg px-5">
          <Row className="pb-3 pt-4 row">
            <Col>
              <h1 className="fw-bold">출입관제 서버 관리</h1>
            </Col>
          </Row>

          <Row
            className="py-1 align-items-center justify-content-end"
            style={{ gap: "1.2rem" }}
          >
            <Col
              style={{
                flex: 0,
                flexShrink: 0,
                wordBreak: "keep-all",
                padding: 0,
              }}
            >
              {type === "default" && (
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={(e) => {
                    e.stopPropagation();
                    goToCreate();
                  }}
                  style={{
                    padding: "4px 32px",
                  }}
                >
                  추가
                </button>
              )}
              {type === "delete" && (
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={(e) => {
                    e.stopPropagation();
                    setType("default");
                    if (Boolean(deleteTarget.length)) {
                      setDeleteTarget([]);
                    }
                  }}
                  style={{
                    padding: "4px 32px",
                  }}
                >
                  취소
                </button>
              )}
            </Col>
            <Col
              style={{
                flex: 0,
                flexShrink: 0,
                wordBreak: "keep-all",
                padding: 0,
              }}
            >
              <button
                type="button"
                className="btn btn-default"
                onClick={onClickDeleteDoorSystem}
                style={{
                  padding: "4px 32px",
                  marginRight: "1rem",
                }}
              >
                삭제
              </button>
            </Col>
          </Row>

          <div className="ContentBox TableStyle mt-4">
            <Table
              style={{ verticalAlign: "middle" }}
              className="mb-4 text-center"
            >
              <thead>
                <tr>
                  {type === "delete" && (
                    <th>
                      <Form.Check
                        type="checkbox"
                        checked={deleteTarget.length === data.length}
                        onChange={onClickCheckAll}
                      />
                    </th>
                  )}
                  <th>서버 id</th>
                  <th>서버 명</th>
                  <th>설명</th>
                  <th>가맹점 명</th>
                  {type === "default" && <th>관리</th>}
                </tr>
              </thead>
              <tbody style={{ position: "relative" }}>
                <Suspense
                  fallback={
                    <tr style={{ height: "250px" }}>
                      <Spinner
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          marginLeft: "-18px",
                        }}
                        animation="border"
                      />
                    </tr>
                  }
                >
                  {data?.length > 0 ? (
                    <DoorSystemBoard
                      data={data}
                      onClickManage={(id) => goToManage(id)}
                      tableType={type}
                      onClickCheck={(id, status) => {
                        if (
                          deleteTarget.findIndex(
                            (target) => target.id === id
                          ) !== -1
                        ) {
                          setDeleteTarget((prev) =>
                            prev.filter((target) => target.id !== id)
                          );
                        } else {
                          setDeleteTarget((prev) => [...prev, { id, status }]);
                        }
                      }}
                      checked={(id) =>
                        deleteTarget.findIndex((target) => target.id === id) !==
                        -1
                      }
                    />
                  ) : (
                    <tr>
                      <td colSpan={5} style={{ padding: "4rem" }}>
                        등록된 출입관제 서버가 없습니다
                      </td>
                    </tr>
                  )}
                </Suspense>
              </tbody>
            </Table>
            <SimplePagination
              currentPage={page}
              totalPage={pageInfo.totalPages}
              setPage={setPage}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ManageDoorSystem;
