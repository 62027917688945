import { useState } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import Btn from "../../../components/admin/Btn";
import { useSignUp } from "../../../hooks/signup/useSignUp";

export default function SignUp() {
  const [auth, setAuth] = useState({
    name: "",
    loginId: "",
    password: "",
    passwordConfirm: "",
  });
  const { mutate } = useSignUp();

  const handleAuthChange = ({ target }) => {
    setAuth((prev) => ({ ...prev, [target.id]: target.value }));
  };

  const handleSignUpBtnClick = () => {
    const { name, loginId, password, passwordConfirm } = auth;

    if (!name) {
      return alert("이름을 입력해주세요.");
    }

    if (!loginId) {
      return alert("아이디를 입력해주세요.");
    }

    if (!password) {
      return alert("비밀번호를 입력해주세요.");
    }

    if (!passwordConfirm) {
      return alert("확인 비밀번호를 입력해주세요.");
    }

    if (password !== passwordConfirm) {
      return alert("비밀번호가 일치하지 않습니다.");
    }

    mutate(auth);
  };

  return (
    <>
      <Container
        fluid
        className="d-flex align-items-center g-0"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="mx-auto p-3"
          style={{ width: "90%", maxWidth: "320px" }}
        >
          <Row style={{ margin: "auto" }} className="g-0">
            <div style={{ width: "100%", textAlign: "center" }}>
              <Image
                style={{
                  width: "100%",
                  maxWidth: "100px",
                  marginBottom: "4rem",
                }}
                src={
                  "https://www.flexdaycdn.net/public/images/static/login/new-logo.png"
                }
              ></Image>
            </div>
          </Row>

          <Row>
            <Col>
              <div className="loginBox">
                <Form.Control
                  id="name"
                  type="text"
                  className="inputType mb-3"
                  placeholder="이름을 입력해주세요."
                  value={auth.name}
                  onChange={handleAuthChange}
                />
                <Form.Control
                  id="loginId"
                  type="text"
                  className="inputType mb-3"
                  placeholder="아이디를 입력해주세요."
                  value={auth.id}
                  onChange={handleAuthChange}
                />

                <Form.Control
                  id="password"
                  type="password"
                  className="inputType mb-3"
                  placeholder="비밀번호를 입력해주세요."
                  value={auth.password}
                  onChange={handleAuthChange}
                />

                <Form.Control
                  id="passwordConfirm"
                  type="password"
                  className="inputType mb-3"
                  placeholder="확인 비밀번호를 입력해주세요."
                  value={auth.passwordConfirm}
                  onChange={handleAuthChange}
                />

                <div className="loginPrimary mb-2">
                  <Btn
                    type="API"
                    btntext="회원 가입"
                    onClick={handleSignUpBtnClick}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
