import {useSearchParams} from 'react-router-dom';

import {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Row, Table} from 'react-bootstrap';
import LeftMenu from '../../../components/admin/LeftMenu';
import Btn from "../../../components/admin/Btn";
import {EngNumCheck, setCommas} from "../../../function/common";
import {useQuery} from "@tanstack/react-query";
import {apiClient} from "../../../reactQuery/api";
import NewAdminPagination from "../../../components/admin/NewPagination";
import moment from "moment";


export default function StoreProduct(){
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    const [products, setProducts] = useState();

    const [deleted,setDeleted] = useState('false');
    const [storeId,setStoreId] = useState(undefined);
    const [storeName,setStoreName] = useState(undefined);

    const [searchParams,setSearchParams] = useSearchParams();

    let urlStoreId = searchParams.get('storeId');
    if(EngNumCheck(urlStoreId)){
        setStoreId(urlStoreId)
    }

    const { data: defaultUsersQueryData, refetch } = useQuery(
        ["products", page, storeId,deleted,storeName],
        () =>
            apiClient({
                url: "/products",
                params: {
                    page,
                    size,
                    ...(storeId && { storeId }),
                    ...(deleted && { deleted }),
                    ...(storeName && { storeName }),
                },
            }),
        {
            onSuccess: (res) => {},
            onError: (err) => alert(err.message),
            enabled: false,
            suspense: false,
            keepPreviousData: true,
            refetchOnMount: "always",
            refetchOnReconnect: "always",
            refetchOnWindowFocus: "always",
        }
    );

    useEffect(() => {
        refetch();
    }, [page]);

    useEffect(() => {
        if (defaultUsersQueryData?.data?.empty) {
            setPage(0);
        }

        if (defaultUsersQueryData?.data?.totalPages) {
            setTotalPages(defaultUsersQueryData.data.totalPages);
        } else {
            setTotalPages(1);
        }

        if (defaultUsersQueryData?.data?.totalElements) {
            setTotalElements(defaultUsersQueryData.data.totalElements);
        } else {
            setTotalElements(0);
        }

        if (defaultUsersQueryData?.data?.content?.length > 0) {
            setProducts(defaultUsersQueryData.data.content);
        } else {
            setProducts([]);
        }
    }, [defaultUsersQueryData]);

    const onClickSearch = () => {
        console.log(storeName)
        setPage(0);
        refetch();
    };



    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs ={2} className="LeftBg px-0">
                        <LeftMenu />
                    </Col>
                    <Col className="RightBg px-5">

                        <Row className='pb-3 pt-4 row'>
                            <Col>
                                <h1 className='fw-bold'>가맹점 상품리스트</h1>
                            </Col>
                        </Row>
                        
                        <Row className='py-1 align-items-center'>
                            {/* 2개형 */}
                            <Col xs={'auto'} className="px-3 py-1 ms-2 rounded-5 text-white" style={{"background" : "#FF5864"}}>
                                <h5 className='mb-0'>Total</h5>
                            </Col>
                            <Col xs={{ span:1 }} >
                                <h5 className='mb-0'>{totalElements}</h5>
                            </Col>
                        </Row>
                        <div className="ContentBox TableStyle mt-4">
                            <div style={{ maxWidth: "620px" }} className="mx-auto">
                                <Row className="p-1 align-items-center justify-content-center g-4">
                                    <Col xs={12}>
                                        <Row className="align-items-center Formbox">
                                            <Col xs={"auto"}>
                                                <h5 className="mb-0 fw-bold">가맹점명</h5>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    onChange={(e)=>{
                                                        setStoreName(e.target.value)
                                                    }}
                                                    type="text"
                                                    id="storeName"
                                                    aria-describedby="passwordHelpBlock"
                                                    autoComplete="off"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12}>
                                        <Row className="align-items-center Formbox">
                                            <Col xs={"auto"}>
                                                <h5 className="mb-0 fw-bold">가맹점id</h5>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    onChange={(e)=>{
                                                        setStoreId(e.target.value)
                                                    }}
                                                    type="text"
                                                    id="storeId"
                                                    aria-describedby="passwordHelpBlock"
                                                    autoComplete="off"
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12}>
                                        <Row className='align-items-left' >
                                            <Col xs={"auto"}>
                                                <h5 className="mb-0 fw-bold">삭제여부</h5>
                                            </Col>
                                            <Col xs={('auto')} className={deleted==undefined?'btn-dark':'btn-black'}>
                                                <Button onClick={() => setDeleted(undefined) } >전체보기</Button>
                                            </Col>
                                            <Col xs={('auto')} className={deleted=='false'?'btn-dark':'btn-black'}>
                                                <Button onClick={() => setDeleted('false')} >미삭제</Button>
                                            </Col>
                                            <Col xs={('auto')} className={deleted=='true'?'btn-dark':'btn-black'}>
                                                <Button onClick={() => setDeleted('true')} >삭제</Button>
                                            </Col>
                                        </Row>
                                    </Col>


                                    <Col xs={12} lg={"auto"}>
                                        <Row className="align-items-center justify-content-center filter-btnbox">
                                            <Col xs={"auto"}>
                                                <Btn
                                                    type="API"
                                                    btntext="조회 하기"
                                                    // onClick={() => inquire()}
                                                    onClick={() => onClickSearch()}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <div className='ContentBox TableStyle mt-4'>
                            <Table style={{"verticalAlign": "middle"}} className='mb-4'>
                                <thead className='text-center' style={{"verticalAlign": "middle"}}>
                                    <tr>
                                        <th style={{maxWidth:'50px'}}>
                                            product_id
                                        </th>
                                        <th style={{maxWidth:'50px'}}>
                                            가맹점명
                                        </th>
                                        <th>
                                            구분
                                        </th>
                                        <th>
                                           상품명
                                        </th>
                                        <th>
                                            액면가
                                        </th>
                                        <th>
                                            판매가
                                        </th>
                                        <th>
                                            횟수
                                        </th>
                                        <th>
                                            적립여부
                                        </th>
                                        <th>
                                            적립금액
                                        </th>

                                        {/*<th>*/}
                                        {/*    상품 이미지*/}
                                        {/*</th>*/}
                                        <th>삭제여부</th>
                                        <th>등록일</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {/* data 값으로 루프 */}
                                    {
                                        totalElements > 0
                                        ? products.map( (item, index) => (
                                            <tr key={item.productId}>
                                                <td style={{maxWidth:'50px'}}>
                                                    {item.productId}
                                                </td>
                                                <td style={{maxWidth:'50px'}}>
                                                    {item.storeName}
                                                </td>
                                                <td >
                                                    {item.productType?.description}
                                                </td>
                                                <td>
                                                    {item.name}                
                                                </td>
                                                <td>
                                                    {setCommas(item.price)}
                                                </td>
                                                <td>
                                                    {setCommas(item.storeSalePrice)}
                                                </td>
                                                <td>
                                                    {item.totalCount?item.totalCount:0}
                                                </td>
                                                <td>
                                                    {item.useReward?'O':'X'}
                                                </td>
                                                <td>
                                                    {item.rewardPrice?item.rewardPrice:0}
                                                </td>

                                                {/*<td style={{"width": "180px"}}>*/}
                                                {/*    <Image src={item.representImageUrl} style={{"maxWidth" : "150px","maxHeight" : "40px"}}/>*/}
                                                {/*</td>*/}
                                                <td >
                                                    {item.deleted==true?"O":"X"}
                                                </td>
                                                <td >
                                                    {moment(item.createdAt).format('yy-MM-DD HH:mm')}
                                                </td>
                                            </tr>
                                        ))
                                        : <tr>
                                            <td colSpan={8} style={{"padding" : "7rem 0", "opacity": "0.5"}}>상품이 없습니다.</td>
                                        </tr>                              
                                    }

                                </tbody>
                            </Table>
                            <NewAdminPagination
                                pageNationSize={10}
                                page={page}
                                setPage={setPage}
                                totalPages={totalPages}
                                setTotalPages={setTotalPages}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}