import {
  Chart,
  ArcElement,
  Legend,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
} from "chart.js";
import "./DashBoard.css";
import LeftMenu from "../../../components/admin/LeftMenu";

import { useState, useMemo, useEffect } from "react";
import Today from "../../../components/dashboard/Today";
import Week from "../../../components/dashboard/Week";
import Month from "../../../components/dashboard/Month";
import Year from "../../../components/dashboard/Year";
import { Container } from "react-bootstrap";
import getDateType from "../../../function/getDateType";
import adminApi from "../../../function/apiConfig";
import StoreMap from "./StoreMap";

const MOCK = ["오늘", "이번주", "이번달", "올해"];

const TAB_STATUS = {
  TODAY: "오늘",
  WEEK: "이번주",
  MONTH: "이번달",
  YEAR: "올해",
};

const tabConvert = (tab) => {
  if (tab === "오늘") {
    return "";
  } else if (tab === "이번주") {
    return "THIS_WEEK_FIRST";
  } else if (tab === "이번달") {
    return "THIS_MONTH_FIRST";
  } else if (tab === "올해") {
    return "THIS_YEAR_FIRST";
  } else {
    return "";
  }
};

Chart.register(
  ArcElement,
  Legend,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement
);

export default function DashBoard() {
  const [tabs, setTabs] = useState(TAB_STATUS.TODAY);
  const [dashboardData, setDashboardData] = useState({
    totalFacePrice: { amount: 0, comparisonRate: 0 },
    totalStoreSalesPrice: { amount: 0, comparisonRate: 0 },
    totalUsagePrice: { amount: 0, comparisonRate: 0 },
    totalRegisterMembership: { amount: 0, comparisonRate: 0 },
  });

  const handleTabClick = (text) => {
    setTabs(text);
  };

  const mapedMocks = useMemo(
    () =>
      MOCK.map((text) => (
        <li
          key={text}
          className={`dashBoard-tab__item ${
            tabs === text && "dashBoard-tab__item--active"
          }`}
          onClick={() => handleTabClick(text)}
        >
          {text}
        </li>
      )),
    [tabs]
  );

  useEffect(() => {
    adminApi
      .get("/main/dash-board", {
        params: {
          startDate: getDateType(tabConvert(tabs)),
          endDate: getDateType(),
        },
      })
      .then((res) => {
        setDashboardData(res);
      })
      .catch(() =>
        setDashboardData({
          totalFacePrice: { amount: 0, comparisonRate: 0 },
          totalStoreSalesPrice: { amount: 0, comparisonRate: 0 },
          totalUsagePrice: { amount: 0, comparisonRate: 0 },
          totalRegisterMembership: { amount: 0, comparisonRate: 0 },
        })
      );
  }, [tabs]);
  return (
    <>
      <Container fluid className="px-0">
        <div className="dashBoard-wrapper">
          <div className="LeftBg px-0">
            <LeftMenu />
          </div>
          <div className="dashBoard-content-wrapper px-5">
            <h1 className="fw-bold">대시보드</h1>
            <ul className="dashBoard-tab__list">{mapedMocks}</ul>
            <div className="dashBoard-tab-content__container">
              {tabs === TAB_STATUS.TODAY && (
                <Today dashboardData={dashboardData} />
              )}
              {tabs === TAB_STATUS.WEEK && (
                <Week dashboardData={dashboardData} />
              )}
              {tabs === TAB_STATUS.MONTH && (
                <Month dashboardData={dashboardData} />
              )}
              {tabs === TAB_STATUS.YEAR && (
                <Year dashboardData={dashboardData} />
              )}
            </div>
            <StoreMap />
          </div>
        </div>
      </Container>
    </>
  );
}
