import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";
import { setCommas } from "../../function/common";

const TableRow = ({
  storeName,
  name: productName,
  price: facePrice,
  storeSalePrice,
  onClickCheck = () => {},
  checked,
}) => {
  return (
    <tr className="board-row-except">
      <td>
        <Form.Check type="checkbox" checked={checked} onChange={onClickCheck} />
      </td>
      <td>{storeName ?? "-"}</td>
      <td>{productName ?? "-"}</td>
      <td>{facePrice ? setCommas(facePrice) + "원" : "-"}</td>
      <td>{storeSalePrice ? setCommas(storeSalePrice) + "원" : "-"}</td>
    </tr>
  );
};

const ProductList = ({ data, onClickCheck = () => {}, checked }) => {
  return data.map((row) => (
    <TableRow
      key={row.productId}
      {...row}
      onClickCheck={() => onClickCheck(row)}
      checked={checked(row.productId)}
    />
  ));
};

export default ProductList;
