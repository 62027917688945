import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../reactQuery/api";

const PAGE_SIZE = 10;

export default function useSettlements({
  page,
  showingStoreName,
  setTotalPage,
  setTotalElements,
}) {
  const { data: settlements } = useQuery(
    [showingStoreName, page],
    async () => {
      const { data } = await apiClient({
        url: "/settlements",
        method: "get",
        params: {
          startDate: "22-06-11",
          endDate: "30-10-12",
          storeName: showingStoreName,
          page,
          size: PAGE_SIZE,
        },
      });

      return data;
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const { totalPages, totalElements } = data;
        sessionStorage.setItem("page", page);
        sessionStorage.setItem("storeName", showingStoreName);
        setTotalPage(totalPages);
        setTotalElements(totalElements);
      },
      onError: () => {},
    }
  );

  return { settlements };
}
