import VerticalBarChart from "../chart/VerticalBarChart";
import LineChart from "../chart/LineChart";
import ContentBoard from "./ContentBoard";
import PieChart from "../chart/PieChart";
import HorizonBarChart from "../chart/HorizonBarChart";
import { getCurrentWeekLabes } from "../../function/date";

export default function Week({ dashboardData }) {
  return (
    <div>
      <ContentBoard
        summarys={[
          {
            count: dashboardData.totalFacePrice.amount,
            percentage: Math.round(
              dashboardData.totalFacePrice.comparisonRate * 100
            ),
          },
          {
            count: dashboardData.totalStoreSalesPrice.amount,
            percentage: Math.round(
              dashboardData.totalStoreSalesPrice.comparisonRate * 100
            ),
          },
          {
            count: dashboardData.totalUsagePrice.amount,
            percentage: Math.round(
              dashboardData.totalUsagePrice.comparisonRate * 100
            ),
          },
          {
            count: dashboardData.totalRegisterMembership.amount,
            percentage: Math.round(
              dashboardData.totalRegisterMembership.comparisonRate * 100
            ),
          },
        ]}
        timeUnit="week"
        // firstTitle="매출 그래프"
        // firstChart={
        //   <LineChart
        //     isSales
        //     label="매출액"
        //     labels={getCurrentWeekLabes()}
        //     datas={[9999999, 7777777, 8888888, 7777777, 999999, 0, 0]}
        //   />
        // }
        // secondTitle="재구매 추이"
        // secondChart={
        //   <LineChart
        //     label="재구매 수"
        //     labels={getCurrentWeekLabes()}
        //     datas={[10, 0, 30, 40, 50, 60, 70]}
        //   />
        // }
      />
      {/* <ContentBoard
        isSummary
        firstTitle="회원 통계 - 사용 운영체제"
        firstChart={
          <PieChart
            labels={["IOS 62%", "AOS 30%", "Etc. 8%"]}
            datas={[12, 19, 3]}
            label="인원 수"
          />
        }
        secondTitle="결제 수단"
        secondChart={
          <HorizonBarChart
            labels={["", "", ""]}
            datas={[10, 20, 30]}
            paymentCount={60}
            paymentInfo={[
              { payMethod: "계좌이체", count: 10 },
              { payMethod: "간편결제", count: 20 },
              { payMethod: "카드", count: 30 },
            ]}
          />
        }
        thirdTitle="회원 통계 - 성별, 연령대"
        thirdChart={
          <VerticalBarChart
            labels={[
              "0-12",
              "13-18",
              "19-24",
              "25-29",
              "30-34",
              "35-39",
              "40-44",
              "45-49",
              "50-54",
              "55-59",
              "60-",
            ]}
            firstData={[0, 0, 38, 10, 0, 0, 0, 0, 0, 0, 0]}
            firstLabel="여성"
            secondData={[0, 0, 10, 19, 0, 0, 0, 0, 0, 0, 0]}
            secondLabel="남성"
          />
        }
      /> */}
    </div>
  );
}
