import moment from "moment";

const Td = ({ children }) => {
  return (
    <td
      style={{
        wordBreak: "keep-all",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </td>
  );
};

export default function PhysicalCardDetailBoard({ data }) {
  const mappedBulkInfo = data.map((card) => {
    const {
      id = 0,
      number = "",
      status = {
        code: 0,
        name: "",
        description: "",
      },
      connectedAt = null,
      createdAt = "",
      storeId = 0,
      membershipId = null,
      membershipNumber = null,
      membershipName = null,
      couponId = null,
      couponNumber = null,
      couponProductType = {
        code: 0,
        description: "",
      },
      deleted = false,
      storeName = "",
    } = card;

    return (
      <tr key={id} className="board-row-except">
        <Td>{id}</Td>
        <Td>{number ?? "-"}</Td>
        <Td>{status.code ? status.name : "-"}</Td>
        <Td>{createdAt ? moment(createdAt).format("YY-MM-DD kk:mm") : "-"}</Td>
        <Td>
          {connectedAt ? moment(connectedAt).format("YY-MM-DD kk:mm") : "-"}
        </Td>
        <Td>{membershipId ?? "-"}</Td>
        <Td>{membershipNumber ?? "-"}</Td>
        <Td>{membershipName ?? "-"}</Td>
        <Td>{couponId ?? "-"}</Td>
        <Td>{couponNumber ?? "-"}</Td>
        <Td>{couponProductType?.code ? couponProductType.description : "-"}</Td>
        <Td>{deleted ? "O" : "X"}</Td>
      </tr>
    );
  });

  return mappedBulkInfo;
}
