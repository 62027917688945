import { useMutation, useQuery } from "@tanstack/react-query";
import { Fragment, Suspense, useEffect, useState } from "react";
import { apiClient } from "../../reactQuery/api";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import moment from "moment";
import { queryClient } from "../../reactQuery/queryClient";
import DetailPagination from "../admin/DetailPagination";

export const StoreBillingModal = ({ show, onClickHide, data, refetch }) => {
  const {
    id: storeId,
    storeName,
    storeBilling: prevBillingInfo = null,
  } = data || {};

  const initialStoreBilling = {
    storeBillingId: null,
    billingStartDate: "",
    billingPrice: "",
    billingType: "",
    billingSubjectType: "",
    storeGroupId: "",
    billingStatus: "",
  };

  const [storeBilling, setStoreBilling] = useState(initialStoreBilling);

  const { data: storeGroupOptions } = useQuery(
    ["getStoreGroupByStoreId", storeId],
    () =>
      apiClient({
        url: `/store-group/search/store/${storeId}`,
        method: "get",
      }).then((res) => res.data),
    {
      onError: (err) => {
        alert("에러가 발생했습니다. 개발팀에 문의해주세요." + err);
      },
      enabled: Boolean(storeId) && show,
      placeholderData: [],
    }
  );

  const billingInputs = [
    {
      label: "최초 청구일자",
      placeholder: "최초 청구 시작일",
      type: "billingStartDate",
      isRequired: true,
      inputType: "date",
    },
    {
      label: "청구 계약 금액",
      placeholder: "계약 금액",
      type: "billingPrice",
      isRequired: false,
      inputType: "number",
    },
    {
      label: "청구 타입",
      placeholder: "월/년 선택",
      type: "billingType",
      isRequired: true,
      inputType: "select",
      options: [
        {
          label: "해당 없음",
          value: "NONE",
          code: 1,
        },
        {
          label: "월 주기",
          value: "MONTHLY",
          code: 2,
        },
        {
          label: "연 주기",
          value: "YEARLY",
          code: 3,
        },
      ],
    },
    {
      label: "납입 주체",
      placeholder: "당사자/그룹 선택",
      type: "billingSubjectType",
      isRequired: true,
      inputType: "select",
      options: [
        {
          label: "해당 없음",
          value: "NONE",
          code: 1,
        },
        {
          label: "직접 납입",
          value: "OWN",
          code: 2,
        },
        ...(storeGroupOptions?.length > 0
          ? [
              {
                label: "그룹",
                value: "GROUP",
                code: 3,
              },
            ]
          : []),
      ],
    },
    {
      label: "대상 그룹",
      placeholder: "주체 대상 그룹 선택",
      type: "storeGroupId",
      isRequired: false,
      inputType: "select",
      options: storeGroupOptions.map((group) => ({
        value: group.storeGroupId,
        label: group.name,
      })),
    },
    {
      label: "청구 상태",
      placeholder: "청구 진행 상태",
      type: "billingStatus",
      isRequired: true,
      inputType: "select",
      options: [
        {
          label: "청구 해당없음",
          value: "NONE",
          code: 1,
        },
        {
          label: "청구 중",
          value: "ON",
          code: 2,
        },
        {
          label: "청구 정지",
          value: "OFF",
          code: 3,
        },
        {
          label: "청구 일시중지",
          value: "PAUSE",
          code: 4,
        },
      ],
    },
  ];

  const onChangeData = (target, value) => {
    setStoreBilling((prev) => ({
      ...prev,
      [target]: value,
    }));
  };

  // 빌링 정보 저장 관련 뮤테이션
  const saveMutation = useMutation(
    ({
      storeId,
      storeBillingId,
      billingStartDate,
      billingPrice,
      billingType,
      billingSubjectType,
      storeGroupId,
      billingStatus,
    }) =>
      apiClient({
        url: "/billing",
        method: storeBillingId ? "put" : "post",
        data: {
          ...(!storeBillingId && { storeId }),
          ...(storeBillingId && { storeBillingId }),
          billingStartDate,
          billingPrice,
          billingType,
          billingSubjectType,
          ...(billingSubjectType === "GROUP" && { storeGroupId }),
          billingStatus,
        },
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        alert("빌링 정보가 저장되었습니다.");
        refetch();
        setStoreBilling(initialStoreBilling);
        onClickHide();
      },
      onError: (err) => {
        alert("에러가 발생했습니다. 개발팀에 문의해주세요." + err);
      },
    }
  );

  const onClickSaveStoreBilling = () => {
    if (saveMutation.isLoading) return;
    const formData = {
      ...storeBilling,
      billingPrice: Number(storeBilling.billingPrice),
      storeId,
    };
    saveMutation.mutate(formData);
  };

  useEffect(() => {
    if (prevBillingInfo) {
      const billingTypeCode = prevBillingInfo.billingType?.code ?? null;
      const billingSubjectTypeCode =
        prevBillingInfo.billingSubjectType?.code ?? null;
      const billingStatusCode = prevBillingInfo.billingStatus?.code ?? null;
      setStoreBilling(prevBillingInfo);
      if (billingTypeCode) {
        setStoreBilling((prev) => ({
          ...prev,
          billingType:
            billingTypeCode === 1
              ? "NONE"
              : billingTypeCode === 2
              ? "MONTHLY"
              : "YEARLY",
        }));
      }
      if (billingSubjectTypeCode) {
        setStoreBilling((prev) => ({
          ...prev,
          billingSubjectType:
            billingSubjectTypeCode === 1
              ? "NONE"
              : billingSubjectTypeCode === 2
              ? "OWN"
              : "GROUP",
        }));
      }
      if (billingStatusCode) {
        setStoreBilling((prev) => ({
          ...prev,
          billingStatus:
            billingStatusCode === 1
              ? "NONE"
              : billingStatusCode === 2
              ? "ON"
              : billingStatusCode === 3
              ? "OFF"
              : "PAUSE",
        }));
      }
    }
  }, [prevBillingInfo]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setStoreBilling(initialStoreBilling);
        onClickHide();
      }}
    >
      <Modal.Header>{storeName} 빌링 정보 수정/등록</Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "0.8rem",
            fontWeight: "700",
          }}
          className="pb-2 mb-2"
        >
          {billingInputs.map(
            ({ label, placeholder, isRequired, inputType, options, type }) => {
              return type === "storeGroupId" &&
                storeBilling.billingSubjectType !== "GROUP" ? (
                <Fragment key={type}></Fragment>
              ) : (
                <Form.Group
                  key={type}
                  className="xs-12"
                  controlId="storeName"
                  style={{ width: "calc(50% - 0.4rem)" }}
                >
                  <Form.Label>
                    {" "}
                    {label} {isRequired && <span className="PointText">*</span>}
                  </Form.Label>
                  {inputType === "select" ? (
                    <Form.Select
                      value={storeBilling[type]}
                      onChange={(e) => onChangeData(type, e.target.value)}
                    >
                      {options.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          disabled={option.value === "UNDER_REVIEW"}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  ) : (
                    <Form.Control
                      type={inputType ?? "text"}
                      placeholder={placeholder}
                      value={storeBilling[type]}
                      onChange={(e) => onChangeData(type, e.target.value)}
                    />
                  )}
                </Form.Group>
              );
            }
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setStoreBilling(initialStoreBilling);
            onClickHide();
          }}
        >
          닫기
        </Button>
        <Button variant="primary" onClick={onClickSaveStoreBilling}>
          저장
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const StoreBillingHistoryModal = ({
  show,
  onClickHide,
  data: storeBillingData,
}) => {
  const { storeName, storeBilling = null } = storeBillingData || {};
  const storeBillingId = storeBilling?.storeBillingId;

  const [page, setPage] = useState(0);
  const tablePageSize = 5;

  const handlePageMove = (page) => {
    setPage(page);
  };

  const {
    data: { content: rows, totalPages },
  } = useQuery(
    ["billingHistory", storeBillingId, page],
    () =>
      apiClient({
        url: `/billing/${storeBillingId}/histories`,
        method: "get",
        params: {
          page,
          size: tablePageSize,
        },
      }).then((res) => res.data),
    {
      onError: (err) => {
        alert("에러가 발생했습니다. 개발팀에 문의해주세요." + err);
      },
      enabled: Boolean(storeBillingId),
      placeholderData: {
        content: [],
      },
    }
  );

  const requestStatusOptions = [
    {
      label: "청구 해당 없음",
      value: "NONE",
      type: "except",
      code: 1,
    },
    {
      label: "청구 필요",
      value: "NEED_REQUEST",
      type: null,
      code: 2,
    },
    {
      label: "청구되었음",
      value: "REQUESTED",
      type: "request",
      code: 3,
    },
    {
      label: "납부 완료",
      value: "COMPLETE",
      type: "complete",
      code: 4,
    },
  ];

  const updateStatusMutation = useMutation(
    ({ storeBillingId, storeBillingHistoryLogId, type }) =>
      apiClient({
        url: `/billing/${storeBillingId}/log/${storeBillingHistoryLogId}/${type}`,
        method: "put",
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        alert("청구 상태가 변경되었습니다.");
      },
      onError: (err) => {
        alert("에러가 발생했습니다. 개발팀에 문의해주세요." + err);
      },
    }
  );

  const onChangeRequestStatus = (value, storeBillingHistoryLogId) => {
    const selectValue = value;
    const selectedOption = requestStatusOptions.find(
      (option) => option.value === selectValue
    );
    const { label, type } = selectedOption;
    const confirm = window.confirm(
      `청구 상태를 [${label}] 상태로 변경하시겠습니까?`
    );
    if (updateStatusMutation.isLoading) return;
    if (confirm) {
      updateStatusMutation.mutate({
        storeBillingId,
        storeBillingHistoryLogId,
        type,
      });
    }
  };

  const tableColumns = [
    {
      header: "청구 상태",
      accessor: "requestStatus",
      Cell: ({ row, value }) => {
        if (value?.code) {
          const { storeBillingHistoryLogId } = row || {};
          return (
            <Form.Group className="xs-12" controlId="storeName">
              <Form.Select
                value={
                  requestStatusOptions.find(
                    (option) => option.code === value.code
                  ).value
                }
                onChange={(e) =>
                  onChangeRequestStatus(
                    e.target.value,
                    storeBillingHistoryLogId
                  )
                }
              >
                {requestStatusOptions.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    disabled={option.type === null}
                  >
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          );
        }
      },
    },
    {
      header: "청구 일자",
      accessor: "billingDate",
      Cell: ({ value }) => {
        if (value) {
          return moment(value).format("YYYY년MM월DD일");
        }
        return "-";
      },
    },
    {
      header: "청구 납부 일자",
      accessor: "billingCompleteDate",
      Cell: ({ value }) => {
        if (value) return value;
        return "-";
      },
    },
  ];

  const initialNewBillingHistoryLog = {
    billingDate: "",
    requestStatus: "",
  };
  const [newBillingHistoryLog, setNewBillingHistoryLog] = useState(
    initialNewBillingHistoryLog
  );

  const billingInputs = [
    {
      label: "청구일자",
      placeholder: "청구일",
      type: "billingDate",
      isRequired: true,
      inputType: "date",
    },
    {
      label: "청구 상태",
      placeholder: "청구 상태",
      type: "requestStatus",
      isRequired: true,
      inputType: "select",
      options: [
        {
          label: "청구 해당 없음",
          value: "NONE",
          code: 1,
        },
        {
          label: "청구 필요",
          value: "NEED_REQUEST",
          code: 2,
        },
        {
          label: "청구되었음",
          value: "REQUESTED",
          code: 3,
        },
        {
          label: "납부 완료",
          value: "COMPLETE",
          code: 4,
        },
      ],
    },
  ];

  const onChangeData = (target, value) => {
    setNewBillingHistoryLog((prev) => ({
      ...prev,
      [target]: value,
    }));
  };

  // 신규 빌링 내역 저장 관련 뮤테이션
  const addMutation = useMutation(
    ({ storeBillingId, billingDate, requestStatus }) =>
      apiClient({
        url: "/billing/manual",
        method: "post",
        data: {
          storeBillingId,
          billingDate,
          requestStatus,
        },
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        alert("빌링 정보가 저장되었습니다.");
        setNewBillingHistoryLog(initialNewBillingHistoryLog);
        queryClient.invalidateQueries({
          queryKey: ["billingHistory", storeBillingId],
        });
      },
      onError: (err) => {
        alert("에러가 발생했습니다. 개발팀에 문의해주세요." + err);
      },
    }
  );

  const onClickAddStoreBillingHistory = () => {
    if (addMutation.isLoading) return;
    addMutation.mutate({ ...newBillingHistoryLog, storeBillingId });
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setNewBillingHistoryLog(initialNewBillingHistoryLog);
        onClickHide();
      }}
    >
      <Modal.Header>{storeName} 빌링 내역</Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "0.8rem",
            fontWeight: "700",
            borderBottom: "1px solid #ff5864",
          }}
          className="pb-2 mb-2"
        >
          {billingInputs.map(
            ({ label, placeholder, isRequired, inputType, options, type }) => {
              return (
                <Form.Group
                  key={type}
                  className="xs-12"
                  controlId="storeName"
                  style={{ width: "calc(50% - 0.4rem)" }}
                >
                  <Form.Label>
                    {" "}
                    {label} {isRequired && <span className="PointText">*</span>}
                  </Form.Label>
                  {inputType === "select" ? (
                    <Form.Select
                      value={newBillingHistoryLog[type]}
                      onChange={(e) => onChangeData(type, e.target.value)}
                    >
                      {options.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                          disabled={option.value === "UNDER_REVIEW"}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  ) : (
                    <Form.Control
                      type={inputType ?? "text"}
                      placeholder={placeholder}
                      value={newBillingHistoryLog[type]}
                      onChange={(e) => onChangeData(type, e.target.value)}
                    />
                  )}
                </Form.Group>
              );
            }
          )}
          <Button
            variant="primary"
            style={{ marginLeft: "auto" }}
            onClick={onClickAddStoreBillingHistory}
          >
            추가
          </Button>
        </div>
        <div className="pt-2 mb-2">
          <div
            className="mb-2 text-center"
            style={{ fontSize: "1.2rem", fontWeight: "700" }}
          >
            빌링 내역
          </div>
          <StoreBillingHistoryTable
            columns={tableColumns}
            rows={rows}
            page={page}
            totalPage={totalPages}
            handlePageMove={handlePageMove}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setNewBillingHistoryLog(initialNewBillingHistoryLog);
            onClickHide();
          }}
        >
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const StoreBillingHistoryTable = ({
  rows = [],
  columns = [],
  page,
  totalPage,
  handlePageMove,
}) => {
  return (
    <>
      <Table style={{ verticalAlign: "middle" }} className="mb-4" responsive>
        <thead style={{ background: "#333", color: "#fff" }}>
          <tr>
            {columns.map(({ header, accessor }) => {
              return (
                <th key={accessor} className="board-row-except text-center">
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-center" style={{ position: "relative" }}>
          <Suspense
            fallback={
              <tr style={{ height: "250px" }}>
                <Spinner
                  as={"td"}
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    marginLeft: "-18px",
                  }}
                  animation="border"
                />
              </tr>
            }
          >
            {rows.map((history) => {
              return (
                <tr
                  key={history.storeBillingHistoryLogId}
                  className="board-row-except"
                >
                  {columns.map(({ accessor, Cell = null }) => {
                    const row = rows.find(
                      (row) =>
                        row.storeBillingHistoryLogId ===
                        history.storeBillingHistoryLogId
                    );
                    const renderValue = Cell
                      ? Cell({ row, value: row[accessor] })
                      : typeof row[accessor] === "object"
                      ? JSON.stringify(row[accessor])
                      : row[accessor];
                    return <td key={accessor}>{renderValue}</td>;
                  })}
                </tr>
              );
            })}
          </Suspense>
        </tbody>
      </Table>
      <DetailPagination
        currentPage={page}
        totalPage={totalPage}
        onPageMove={handlePageMove}
      />
    </>
  );
};
