import React, { useEffect, useState } from "react";
import CustomModal from "../common/CustomModal";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../reactQuery/api";
import DetailPagination from "../admin/DetailPagination";

const StoreSearchModal = ({
  onClickAction: onClickActionProp = () => {},
  onClickCancel: onClickCancelProp = () => {},
  setModal = () => {},
}) => {
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [searchType, setSearchType] = useState("storeName");
  const [selectedStore, setSelectedStore] = useState(null);

  const handlePageMove = (page = 0) => {
    setPage(page);
  };

  const onClickSelectStore = (row) => {
    if (selectedStore) {
      if (row.id === selectedStore.id) {
        setSelectedStore(null);
      } else {
        setSelectedStore(row);
      }
    } else {
      setSelectedStore(row);
    }
  };

  const resetSearchParams = () => {
    setKeyword("");
    setSearchType("storeName");
  };

  const onClickAction = () => {
    if (onClickActionProp) {
      onClickActionProp(selectedStore);
    }
    resetSearchParams();
  };

  const onClickCancel = () => {
    if (onClickCancelProp) {
      onClickCancelProp();
    }
    resetSearchParams();
  };

  const { refetch: storeSearch, data: storeSearchData } = useQuery(
    ["storeSearchModal", page],
    async () => {
      const res = await apiClient({
        url: `/stores`,
        params: {
          page,
          size: 8,
          storeStatus: "OPEN",
          storeAcceptStatus: "ACCEPTED",
          ...(searchType === "storeName" && { storeName: keyword }),
          ...(searchType === "ownerName" && { ownerName: keyword }),
        },
        method: "get",
      }).then((res) => res.data);
      return res;
    },
    {
      onSuccess: (res) => {
        setTotalPage(res.totalPages);
      },
      enabled: false,
    }
  );
  // console.log(storeSearchData);
  // console.log(page);

  useEffect(() => {
    storeSearch();
  }, []);

  useEffect(() => {
    if (page) {
      storeSearch();
    }
  }, [page]);

  return (
    <CustomModal
      textOfHeader="가맹점 검색"
      messageOfOk="선택"
      messageOfClose="취소"
      callbackOfOk={onClickAction}
      callbackOfClose={onClickCancel}
      setModal={setModal}
      textOfBody={
        <Container>
          <Col className="Formbox mx-0 my-3">
            <Row>
              <Col>
                <Row>
                  <Col className="d-flex align-items-center" xs={4}>
                    <Form.Check
                      label="사장님명 검색"
                      type={"switch"}
                      checked={searchType === "ownerName"}
                      onChange={() => {
                        if (searchType === "storeName") {
                          setSearchType("ownerName");
                        } else {
                          setSearchType("storeName");
                        }
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder={
                        searchType === "storeName" ? "상점 명" : "사장님 명"
                      }
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key.toLowerCase() === "enter") {
                          if (page > 0) {
                            setPage(0);
                          }
                          storeSearch();
                        }
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={3} style={{ width: "fit-content" }}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    if (page > 0) {
                      setPage(0);
                    }
                    storeSearch();
                  }}
                  style={{ height: "100%" }}
                  type="button"
                >
                  검색
                </Button>
              </Col>
            </Row>
          </Col>
          <Table
            style={{ verticalAlign: "middle" }}
            className="mb-4"
            responsive
          >
            <thead>
              <tr>
                <th
                  style={{
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  선택
                </th>
                <th
                  style={{
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  store ID
                </th>
                <th
                  style={{
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  가맹점 명
                </th>
                <th
                  style={{
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  대표자 명
                </th>
                <th
                  style={{
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  운영상태
                </th>
                <th
                  style={{
                    wordBreak: "keep-all",
                    whiteSpace: "nowrap",
                  }}
                >
                  생성일
                </th>
              </tr>
            </thead>
            <tbody>
              {/* data 값으로 루프 */}
              {storeSearchData?.content?.length > 0 ? (
                storeSearchData.content
                  .map((store) => ({ ...store, storeId: store?.id }))
                  .map((item, index) => (
                    <tr key={item.id}>
                      <td style={{ minWidth: "2rem" }}>
                        <Form.Check
                          type="radio"
                          checked={selectedStore?.id === item.id}
                          onChange={() => onClickSelectStore(item)}
                        />
                      </td>
                      <td
                        style={{
                          minWidth: "4rem",
                          wordBreak: "keep-all",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.id}
                      </td>
                      <td
                        style={{
                          minWidth: "5rem",
                          wordBreak: "keep-all",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.storeName}
                      </td>
                      <td
                        style={{
                          minWidth: "5rem",
                          wordBreak: "keep-all",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.ownerName}
                      </td>
                      <td
                        style={{
                          minWidth: "5rem",
                          wordBreak: "keep-all",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.status.description}
                      </td>
                      <td
                        style={{
                          minWidth: "5rem",
                          wordBreak: "keep-all",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.createdAt.replace("T", " ").substring(0, 10)}
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td
                    style={{ minWidth: "2rem", height: "22rem" }}
                    colSpan={6}
                    rowSpan={8}
                  >
                    <p style={{ textAlign: "center" }}>데이터가 없습니다.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <DetailPagination
            currentPage={page}
            totalPage={totalPage}
            onPageMove={handlePageMove}
          />
        </Container>
      }
    />
  );
};

export default StoreSearchModal;
