import adminApi from "../../function/apiConfig";
import {Button} from "react-bootstrap";

const Td = ({ children }) => {
  return (
    <td
      style={{
        wordBreak: "keep-all",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </td>
  );
};



export default function ProductCommerceBoard({
  data, refetch
}) {

    const completeToLink = (commerceId)=>{
        const _approve = confirm("연동 완료 상태로 바꾸겠습니까");
        if(!_approve){
            return null;
        }
        adminApi({
            url: "/commerce-products/"+commerceId,
            method: "post",
        })
            .then(function (response) {
                alert('상태가 변경되었습니다.');
                refetch()
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const stopToLink = (commerceId)=>{
        const _approve = confirm("연동 중지 상태로 바꾸겠습니까");
        if(!_approve){
            return null;
        }
        adminApi({
            url: "/commerce-products/"+commerceId,
            method: "delete",
        })
            .then(function (response) {
                alert('상태가 변경되었습니다.');
                refetch()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

  const mappedOrderInfo = data.map((store) => {
    const {
      commerceProductId,
      paymentChannel = {
        code: "",
        description: "",
        name: "",
      },
        linkStatus,
      product ={
          productId,
          name,
          description,
          price,
          storeSalePrice,
          productType:{
              code: "",
              description: "",
              name: "",
          },
          representImageUrl,
          status : {
              code: "",
              description: "",
              name: "",
          },
          storeName,
          expireDateAdd,
          totalCount,
          rewardPrice,
          useReward,
          createdAt

      }



    } = store;

    return (
      <tr key={commerceProductId} className="board-row-except">
        <Td>{commerceProductId}</Td>
        <Td>{paymentChannel?.name}</Td>
        <Td>{linkStatus?.description}</Td>
        <Td>{product.storeName}</Td>
        <Td>{product.productId}</Td>
        <Td>{product.name}</Td>
        <Td>{product.productType?.description}</Td>
          <Td><Button  onClick={()=> completeToLink(commerceProductId)}>연동완료</Button> </Td>
          <Td><Button  onClick={()=> stopToLink(commerceProductId)}>연동중지</Button> </Td>
      </tr>
    );
  });

  return mappedOrderInfo;
}