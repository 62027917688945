import { Suspense } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import LeftMenu from "../../../components/admin/LeftMenu";
import SuspenseSpinner from "../../../components/common/SuspenseSpinner";
import UserCouponDetailDesc from "../../../components/coupon/UserCouponDetailDesc";

export default function UserCouponDetail() {
  const { userId } = useParams();

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">유저 쿠폰 정보</h1>
              </Col>
            </Row>
            <Suspense fallback={<SuspenseSpinner />}>
              <UserCouponDetailDesc userId={userId} />
            </Suspense>
          </Col>
        </Row>
      </Container>
    </>
  );
}
