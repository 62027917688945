import HorizonBarChart from "../chart/HorizonBarChart";
import VerticalBarChart from "../chart/VerticalBarChart";
import PieChart from "../chart/PieChart";
import ContentBoard from "./ContentBoard";

export default function Today({ dashboardData }) {
  return (
    <div>
      <ContentBoard
        summarys={[
          {
            count: dashboardData.totalFacePrice.amount,
            percentage: Math.round(
              dashboardData.totalFacePrice.comparisonRate * 100
            ),
          },
          {
            count: dashboardData.totalStoreSalesPrice.amount,
            percentage: Math.round(
              dashboardData.totalStoreSalesPrice.comparisonRate * 100
            ),
          },
          {
            count: dashboardData.totalUsagePrice.amount,
            percentage: Math.round(
              dashboardData.totalUsagePrice.comparisonRate * 100
            ),
          },
          {
            count: dashboardData.totalRegisterMembership.amount,
            percentage: Math.round(
              dashboardData.totalRegisterMembership.comparisonRate * 100
            ),
          },
        ]}
        // firstTitle="회원 통계 - 사용 운영체제"
        // firstChart={
        //   <PieChart
        //     labels={[`IOS 10%`, `AOS 20%`, `Etc. 30%`]}
        //     datas={[10, 20, 30]}
        //     label="인원 수"
        //   />
        // }
        // secondTitle="결제 수단"
        // secondChart={
        //   <HorizonBarChart
        //     labels={["", "", ""]}
        //     datas={[10, 20, 30]}
        //     paymentCount={60}
        //     paymentInfo={[
        //       { payMethod: "계좌이체", count: 10 },
        //       { payMethod: "간편결제", count: 20 },
        //       { payMethod: "카드", count: 30 },
        //     ]}
        //   />
        // }
        // thirdTitle="회원 통계 - 성별, 연령대"
        // thirdChart={
        //   <VerticalBarChart
        //     labels={[
        //       "0-12",
        //       "13-18",
        //       "19-24",
        //       "25-29",
        //       "30-34",
        //       "35-39",
        //       "40-44",
        //       "45-49",
        //       "50-54",
        //       "55-59",
        //       "60-",
        //     ]}
        //     firstData={[0, 0, 38, 10, 0, 0, 0, 0, 0, 0, 0]}
        //     firstLabel={"여성"}
        //     secondData={[0, 0, 10, 19, 0, 0, 0, 0, 0, 0, 0]}
        //     secondLabel={"남성"}
        //   />
        // }
      />
    </div>
  );
}
