import { useMemo } from "react";
import { useEffect } from "react";
import { Pagination } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getPageByLocation,
  getPageParamsToURL,
  getPagination,
} from "../../function/pagination";

const PAGE_SIZE = 5;

export default function SimplePagination({ currentPage, totalPage, setPage }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setPage(getPageByLocation(location));
  }, [location, setPage]);

  const pagination = useMemo(() => {
    return getPagination(currentPage, totalPage, PAGE_SIZE);
  }, [currentPage, totalPage]);

  const handlePageMove = (page) => {
    if (page <= -1) {
      return;
    }

    if (page >= totalPage) {
      return;
    }

    if (page === getPageParamsToURL(location)) {
      return;
    }

    navigate(`?page=${page}`);
  };

  const mapedPagination = pagination.map((pageNum) => (
    <Pagination.Item
      key={pageNum}
      className={pageNum === currentPage + 1 ? "page on" : "page"}
      onClick={() => handlePageMove(pageNum - 1)}
    >
      {pageNum}
    </Pagination.Item>
  ));

  return (
    <>
      {totalPage ? (
        <Pagination className="align-items-center">
          <Pagination.First
            className="PaginationFirst"
            onClick={() => handlePageMove(0)}
          />
          <Pagination.Prev
            className="PaginationPrev"
            onClick={() => handlePageMove(currentPage - 1)}
          />
          {mapedPagination}
          <Pagination.Next
            className="PaginationNext"
            onClick={() => handlePageMove(currentPage + 1)}
          />
          <Pagination.Last
            className="PaginationLast"
            onClick={() => handlePageMove(totalPage - 1)}
          />
        </Pagination>
      ) : null}
    </>
  );
}
