import { useCallback } from "react";
import {
  getDayByDayNumber,
  getThisWeek,
  getTwoDigitTimeByOneDigitTime,
} from "../../function/date";
import "./SummaryBoard.css";

export default function SummaryBoard({ summarys, timeUnit }) {
  const [
    { count: paymentPriceCount, percentage: paymentPricePercentage },
    { count: newIncomeCount, percentage: newIncomePercentage },
    { count: orderCountCount, percentage: orderCountPercentage },
    { count: newSignupCount, percentage: newSignupPercentage },
  ] = summarys;

  const getTextByTimeUnit = useCallback(() => {
    if (timeUnit === "year") {
      return "전년 대비";
    } else if (timeUnit === "month") {
      return "전월 대비";
    } else if (timeUnit === "week") {
      return "전주 대비";
    }

    return "전일 대비";
  }, [timeUnit]);

  const getDateTextByTimeUnit = useCallback(() => {
    const date = new Date();

    const year = date.getFullYear();
    const month = getTwoDigitTimeByOneDigitTime(date.getMonth() + 1);
    const todayDate = getTwoDigitTimeByOneDigitTime(date.getDate());
    const day = date.getDay();

    if (timeUnit === "year") {
      return year;
    } else if (timeUnit === "month") {
      return `${year}-${month}`;
    } else if (timeUnit === "week") {
      return getThisWeek();
    }

    return `${year}-${month}-${todayDate} (${getDayByDayNumber(day)})`;
  }, [timeUnit]);

  const getComparisonTextBySummaryValue = (summaryValue) => {
    return summaryValue >= 0 ? "증가" : "감소";
  };

  const getComparisonClassNameBySummaryValue = (summaryValue) => {
    return summaryValue >= 0
      ? "summaryBoard-info-text--plus"
      : "summaryBoard-info-text--minus";
  };

  return (
    <>
      <div className="summaryBoard-date-container">
        {getDateTextByTimeUnit()}
      </div>
      <ul className="summaryBoard-info-list">
        <li className="summaryBoard-info-item">
          <dl>
            <dt className="summaryBoard-info-text">총 액면가액</dt>
            <dd className="summaryBoard-info-text">
              <span className="summaryBoard-info-text--strong">
                {paymentPriceCount.toLocaleString()}
              </span>{" "}
              원
            </dd>
          </dl>
          <strong className="summaryBoard-info-text">
            {getTextByTimeUnit()}{" "}
            <span
              className={getComparisonClassNameBySummaryValue(
                paymentPricePercentage
              )}
            >{`${paymentPricePercentage}%`}</span>{" "}
            {getComparisonTextBySummaryValue(paymentPricePercentage)}
          </strong>
        </li>
        <li className="summaryBoard-info-item">
          <dl>
            <dt className="summaryBoard-info-text">총 판매가액</dt>
            <dd className="summaryBoard-info-text">
              <span className="summaryBoard-info-text--strong">
                {newIncomeCount.toLocaleString()}
              </span>{" "}
              원
            </dd>
          </dl>
          <strong className="summaryBoard-info-text">
            {getTextByTimeUnit()}{" "}
            <span
              className={getComparisonClassNameBySummaryValue(
                newIncomePercentage
              )}
            >{`${newIncomePercentage}%`}</span>{" "}
            {getComparisonTextBySummaryValue(newIncomePercentage)}
          </strong>
        </li>
        <li className="summaryBoard-info-item">
          <dl>
            <dt className="summaryBoard-info-text">총 사용가액</dt>
            <dd className="summaryBoard-info-text">
              <span className="summaryBoard-info-text--strong">
                {orderCountCount.toLocaleString()}
              </span>{" "}
              건
            </dd>
          </dl>
          <strong className="summaryBoard-info-text">
            {getTextByTimeUnit()}{" "}
            <span
              className={getComparisonClassNameBySummaryValue(
                orderCountPercentage
              )}
            >{`${orderCountPercentage}%`}</span>{" "}
            {getComparisonTextBySummaryValue(orderCountPercentage)}
          </strong>
        </li>
        <li className="summaryBoard-info-item">
          <dl>
            <dt className="summaryBoard-info-text">신규 멤버십</dt>
            <dd className="summaryBoard-info-text">
              <span className="summaryBoard-info-text--strong">
                {newSignupCount.toLocaleString()}
              </span>{" "}
              명
            </dd>
          </dl>
          <strong className="summaryBoard-info-text">
            {getTextByTimeUnit()}{" "}
            <span
              className={getComparisonClassNameBySummaryValue(
                newSignupPercentage
              )}
            >{`${newSignupPercentage}%`}</span>{" "}
            {getComparisonTextBySummaryValue(newSignupPercentage)}
          </strong>
        </li>
      </ul>
    </>
  );
}
