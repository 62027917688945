import moment from "moment";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Td = ({ children }) => {
  return (
    <td
      style={{
        wordBreak: "keep-all",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </td>
  );
};

export default function PhysicalCardBoard({ data, onClickLink = () => {} }) {
  const navigate = useNavigate();
  const mappedCardInfo = data.map((card) => {
    const {
      id = 0,
      cardsCount = 0,
      remainCount = 0,
      name = "",
      description = "",
      cardImageUrl = "",
      storeId = 0,
      storeName = "",
      createdAt = "",
      deleted = true,
    } = card;

    return (
      <tr key={id} className="board-row-except">
        <Td>{id}</Td>
        <Td>{name ?? "-"}</Td>
        <Td>{description ?? "-"}</Td>
        <Td>{createdAt ? moment(createdAt).format("YY-MM-DD kk:mm") : "-"}</Td>
        <Td>
          {Boolean(storeName && storeId)
            ? storeName + " / " + storeId
            : "연동필요"}
        </Td>
        <Td>{cardsCount}</Td>
        <Td>{remainCount}</Td>
        <Td>{cardImageUrl ?? "-"}</Td>
        <Td>{deleted ? "O" : "X"}</Td>
        <Td>
          {storeId ? (
            <Button
              onClick={() =>
                navigate(`/admin/product/physical-card/detail/${storeId}/${id}`)
              }
            >
              상세보기
            </Button>
          ) : (
            <Button onClick={() => onClickLink(card)}>연동하기</Button>
          )}
        </Td>
      </tr>
    );
  });

  return mappedCardInfo;
}
