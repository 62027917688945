import useSimplePagination from "../../hooks/common/useSimplePagination";
import { queryKeys } from "../../reactQuery/constants";

export default function CouponPaymentBoard({
  setTotalPage,
  setTotalElements,
  page,
}) {
  const { data: couponPayments } = useSimplePagination(
    [queryKeys.couponPayments, String(page)],
    "/coupon-payments",
    page,
    setTotalPage,
    setTotalElements
  );

  const mapedCouponPayments = couponPayments.content.map((couponPayment) => {
    const {
      id,
      store,
      totalPrice,
      couponPrice,
      cashPhoneNumber,
      firstVisit,
      couponNumber,
      storeStaff,
      createdAt,
      cashReceiptPrice,
      paymentStatus,
      useAmount,
      priceInfo = {
        useAmount: "",
        storeSalesPriceOfUsed: "",
        storeSettlePriceOfUsed: "",
        feeOfUsed: "",
        balanceUsedAmount: "",
        rewardUsedAmount: "",
        usedCount: "",
      },
    } = couponPayment;

    return (
      <tr key={id} className="board-row-except">
        <td>{store.storeName}</td>
        <td>{paymentStatus?.description}</td>
        <td>{cashReceiptPrice}</td>
        <td>{cashPhoneNumber}</td>
        <td>{priceInfo?.useAmount}</td>
        <td>{priceInfo?.storeSalesPriceOfUsed}</td>
        <td>{priceInfo?.storeSettlePriceOfUsed}</td>
        <td>{priceInfo?.feeOfUsed}</td>
        <td>{priceInfo?.balanceUsedAmount}</td>
        <td>{priceInfo?.rewardUsedAmount}</td>
        <td>{priceInfo?.usedCount}</td>

        <td>{storeStaff?.customName}</td>
        <td>{createdAt?.replace("T", " ").substring(0, 16)}</td>
      </tr>
    );
  });

  return mapedCouponPayments;
}
