import { Spinner } from "react-bootstrap";

export default function SuspenseSpinner() {
  return (
    <Spinner
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        marginLeft: "-18px",
      }}
      animation="border"
    />
  );
}
