import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import { useNavigate } from "react-router-dom";
import TextEditor from "../../../components/admin/TextEditor";
import { apiClient } from "../../../reactQuery/api";
import { useMutation } from "@tanstack/react-query";
import { FaqType } from "./ManageFaq";

const CreateFaq = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");
  const [type, setType] = useState("STORE");
  const [faqType, setFaqType] = useState("");

  const createNoticeMutation = useMutation(
    async () =>
      await apiClient({
        url: `/notice`,
        method: "post",
        data: {
          title,
          contents,
          type,
        },
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        if (!res) return;
        alert("자주묻는 질문이 등록되었습니다.");
        navigate("/admin/notices", { replace: true });
      },
    }
  );

  const onClickSave = () => {
    if (createNoticeMutation.isLoading) return;
    if (!title) return alert("제목을 입력해주세요.");
    if (!contents) return alert("내용을 입력해주세요.");
    if (!type) return alert("노출 플랫폼을 선택해주세요.");
    if (!faqType) return alert("질문 타입을 선택해주세요.");
    createNoticeMutation.mutate();
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={2} className="LeftBg px-0">
          <LeftMenu />
        </Col>
        <Col className="RightBg px-5">
          <Row className="pb-3 pt-4 row">
            <Col>
              <h1 className="fw-bold">자주묻는 질문 추가</h1>
            </Col>
          </Row>
          <Row className="pb-3 pt-4 row">
            <Col>
              <h4 className="fw-medium">제목</h4>
              <Form.Group className="Formbox">
                <Form.Control
                  placeholder="질문 제목을 입력해주세요"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="pb-3 pt-4 row">
            <Col>
              <h4 className="fw-medium">
                노출 플랫폼 <span>(현재는 가맹점만 가능)</span>
              </h4>
              <Form.Group className="Formbox d-flex gap-3">
                <Form.Check
                  style={{
                    width: "fit-content",
                  }}
                  type="radio"
                >
                  <Form.Check.Input
                    type="radio"
                    style={{
                      fontSize: "14px",
                      marginRight: "8px",
                    }}
                    value={"USER"}
                    checked={type === "USER"}
                    disabled
                    onChange={() => setType("USER")}
                  />
                  <Form.Label>사용자</Form.Label>
                </Form.Check>
                <Form.Check
                  style={{
                    width: "fit-content",
                  }}
                  type="radio"
                >
                  <Form.Check.Input
                    type="radio"
                    style={{
                      fontSize: "14px",
                      marginRight: "8px",
                    }}
                    value={"STORE"}
                    checked={type === "STORE"}
                    onChange={() => setType("STORE")}
                  />
                  <Form.Label>가맹점</Form.Label>
                </Form.Check>
              </Form.Group>
            </Col>
          </Row>
          <Row className="pb-3 pt-4 row">
            <Col>
              <h4 className="fw-medium">질문 타입</h4>
              <Form.Group className="Formbox d-flex gap-3">
                {FaqType.map(({ label, value }) => (
                  <Form.Check
                    style={{
                      width: "fit-content",
                    }}
                    type="radio"
                  >
                    <Form.Check.Input
                      type="radio"
                      style={{
                        fontSize: "14px",
                        marginRight: "8px",
                      }}
                      value={value}
                      checked={faqType === value}
                      disabled
                      onChange={() => setFaqType(value)}
                    />
                    <Form.Label>{label}</Form.Label>
                  </Form.Check>
                ))}
              </Form.Group>
            </Col>
          </Row>
          <Row className="pb-3 pt-4 row">
            <Col>
              <h4 className="fw-medium">내용</h4>
              <Form.Group className="Formbox">
                <TextEditor value={contents} setValue={setContents} />
              </Form.Group>
            </Col>
          </Row>
          <Row
            className="py-5 pb-5 align-items-center justify-content-center"
            style={{ gap: "1.2rem" }}
          >
            <Col
              style={{
                flex: 0,
                flexShrink: 0,
                wordBreak: "keep-all",
                padding: 0,
              }}
            >
              <button
                type="button"
                className="btn btn-default"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(-1);
                }}
                style={{
                  padding: "4px 32px",
                }}
              >
                취소
              </button>
            </Col>
            <Col
              style={{
                flex: 0,
                flexShrink: 0,
                wordBreak: "keep-all",
                padding: 0,
              }}
            >
              <button
                type="submit"
                className="btn btn-default"
                style={{
                  padding: "4px 32px",
                  marginRight: "1rem",
                }}
                onClick={onClickSave}
              >
                저장
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateFaq;
