import { Suspense, useState } from "react";
import { Col, Container, Row, Spinner, Table } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import SimplePagination from "../../../components/admin/SimplePagination";
import CouponPaymentBoard from "../../../components/coupon/CouponPaymentBoard";

export default function CouponPayments() {
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">교환권 결제내역</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElements}</h5>
              </Col>
            </Row>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr>
                    <th>상호명</th>
                    <th>상태</th>
                    <th>현금영수증 금액</th>
                    <th>현금영수증 번호</th>
                    <th>총사용금액</th>
                    <th>매출금액</th>
                    <th>정산금액</th>
                    <th>수수료</th>
                    <th>금액권사용량</th>
                    <th>적립사용량</th>
                    <th>횟수권사용량</th>
                    <th>결제자</th>
                    <th>결제일자</th>
                  </tr>
                </thead>
                <tbody style={{ position: "relative" }}>
                  <Suspense
                    fallback={
                      <tr style={{ height: "250px" }}>
                        <Spinner
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            marginLeft: "-18px",
                          }}
                          animation="border"
                        />
                      </tr>
                    }
                  >
                    <CouponPaymentBoard
                      setTotalPage={setTotalPage}
                      setTotalElements={setTotalElements}
                      page={page}
                    />
                  </Suspense>
                </tbody>
              </Table>
              <SimplePagination
                currentPage={page}
                totalPage={totalPage}
                setPage={setPage}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
