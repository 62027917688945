import adminApi from "../../function/apiConfig";
import {Button, Form} from "react-bootstrap";
import moment from "moment";

const Td = ({ children }) => {
  return (
    <td
      style={{
        wordBreak: "keep-all",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </td>
  );
};



export default function ProductLogBoard({
  data, refetch,setCheckedIdSet, checkedIdSet
}) {

    const completeToLink = (commerceId)=>{
        const _approve = confirm("연동 완료 상태로 바꾸겠습니까");
        if(!_approve){
            return null;
        }
        adminApi({
            url: "/commerce-products/"+commerceId,
            method: "post",
        })
            .then(function (response) {
                alert('상태가 변경되었습니다.');
                refetch()
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const stopToLink = (commerceId)=>{
        const _approve = confirm("연동 중지 상태로 바꾸겠습니까");
        if(!_approve){
            return null;
        }
        adminApi({
            url: "/commerce-products/"+commerceId,
            method: "delete",
        })
            .then(function (response) {
                alert('상태가 변경되었습니다.');
                refetch()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

  const mappedOrderInfo = data.map((store) => {
    const {
        productLogId,
        productId,
        productLogType = {
        code: "",
        description: "",
        },
        productRow ={
              productId,
              name,
              description,
              price,
              storeSalePrice,
              productType:{
                  code: "",
                  description: "",
                  name: "",
              },
              representImageUrl,
              status : {
                  code: "",
                  description: "",
                  name: "",
              },
        },
        createdAt,
        checked,
        checkedAt,
        checkedAdmin={
            name:""
        },
        storeName



    } = store;

    return (
      <tr key={productLogId} className="board-row-except">
          <Td>
              <Form>
                  <div key={`default-${productLogId}`} className="">
                      <Form.Check // prettier-ignore
                          disabled={(checked == false)?false:true}
                          type={'checkbox'}
                          checked={checkedIdSet.has(productLogId)}
                          id={`default-${productLogId}`}
                          onChange={() => {
                              if(checkedIdSet.has(productLogId)){
                                  checkedIdSet.delete(productLogId)
                                  setCheckedIdSet(new Set(checkedIdSet));
                              }else{
                                  setCheckedIdSet(new Set(checkedIdSet.add(productLogId)));
                              }
                          }}
                      />
                  </div>
              </Form>
          </Td>
        <Td>{productLogId}</Td>
        <Td>{productId}</Td>
        <Td>{productLogType?.description}</Td>
        <Td>{storeName}</Td>
        <Td>{productRow.name}</Td>
        <Td>{productRow.productType?.description}</Td>
        <Td>{productRow.price}</Td>
        <Td>{productRow.storeSalePrice}</Td>
        <Td>{moment(createdAt).format('YY-MM-DD kk:mm')}</Td>
        <Td>{checked==true?"확인 완료":"미확인"}</Td>
        <Td>{checkedAdmin?.name}</Td>
        <Td>{checkedAt?moment(checkedAt).format('YY-MM-DD kk:mm'):""}</Td>
      </tr>
    );
  });

  return mappedOrderInfo;
}