import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import Btn from "../../../components/admin/Btn";
import LeftMenu from "../../../components/admin/LeftMenu";
import adminApi from "../../../function/apiConfig";
import NewAdminPagination from "../../../components/admin/NewPagination";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../reactQuery/api";
import Today from "../../../components/dashboard/Today";
import Week from "../../../components/dashboard/Week";
import Month from "../../../components/dashboard/Month";
import Year from "../../../components/dashboard/Year";
import DisplayList from "../../../components/store/DisplayList";

export default function DisplayBoard() {
    //TODO 페이지 이동시 2번 렌더링 하는것 잡아야함.
    //TODO state를 묶어야 될듯함..


    const [selectedTab, setSelectedTab] = useState('001');
    const [categories, setCategories] = useState([]);

    const { data: defaultCategoryQueryData, refetch } = useQuery(
        ['displayCategory'],
        () =>
            apiClient({
                url: "/store-display-categories",
            }),
        {
            onSuccess: (res) => {},
            onError: (err) => alert(err.message),
            enabled: false,
            suspense: false,
            keepPreviousData: true,
            refetchOnMount: "always",
            refetchOnReconnect: "always",
            refetchOnWindowFocus: "always",
        }
    );

    useEffect(() => {
        refetch();
    }, []);


    useEffect(() => {
        console.log(selectedTab)
    }, [selectedTab]);



    // useQuery의 onSuccess에서 처리하지 않고 useEffect 내에서 처리하는 이유는
    // Suspense 때문입니다!
    // 혹시 궁금하시다면, `https://velog.io/@kjwsx23/React-React-Query-%EC%99%80-setState`
    useEffect(() => {

        if (defaultCategoryQueryData?.data) {
            setCategories(defaultCategoryQueryData.data);
        } else {
            setCategories([]);
        }
    }, [defaultCategoryQueryData]);

    // if (isLoading) return <></>;

    return (
        <>
            <Container fluid className="px-0">
                <div className="dashBoard-wrapper">
                    <div className="LeftBg px-0">
                        <LeftMenu />
                    </div>
                    <div className="dashBoard-content-wrapper px-5">
                        <h1 className="fw-bold">가맹점 display</h1>
                        <ul className="dashBoard-tab__list">
                            { categories?.map((item,index) => (
                                <li
                                    key={item.categoryCode}
                                    className={`dashBoard-tab__item ${
                                        selectedTab === item.categoryCode && "dashBoard-tab__item--active"
                                    }`}
                                    onClick={() => setSelectedTab(item.categoryCode)}
                                >
                                    {item.categoryName}({item.categoryCode})
                                </li>

                            ))}

                        </ul>
                        <div className="dashBoard-tab-content__container">
                            <DisplayList categoryCode={selectedTab}/>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
}
