import React, { useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useMatch, useNavigate } from "react-router-dom";
import LeftMenu from "../../../components/admin/LeftMenu";
import KioskInfoForm from "../../../components/kiosk/KioskInfoForm";
import KioskProductsManagement from "../../../components/kiosk/KioskProductsManagement";
import KioskImageManagement from "../../../components/kiosk/KioskImageManagement";

const ManageKioskDetail = () => {
  const { params } = useMatch("/admin/kiosks/detail/:id");
  const navigate = useNavigate();
  // console.log(params.id);
  const [data, setData] = useState({
    managerId: "",
    kioskId: "",
    installLocation: "",
    installDate: "",
    installManager: "",
    kioskStatus: "CLOSE",
  });

  const onChangeData = (value, target) => {
    setData((prev) => ({ ...prev, [target]: value }));
  };

  const onClickSave = () => {
    console.log("save");
  };

  const infoTabs = [
    {
      header: "키오스크 정보",
      bodyComponent: (
        <KioskInfoForm data={data} onChangeData={onChangeData} isEdit />
      ),
    },
    {
      header: "키오스크 카테고리 / 상품 관리",
      bodyComponent: <KioskProductsManagement />,
    },
    {
      header: "키오스크 이미지 관리",
      bodyComponent: <KioskImageManagement />,
    },
  ];

  return (
    <Container fluid>
      <Row>
        <Col xs={2} className="LeftBg px-0">
          <LeftMenu />
        </Col>
        <Col className="RightBg px-5">
          <Row className="pb-3 pt-4 row">
            <Col>
              <h1 className="fw-bold">통합 키오스크 관리</h1>
            </Col>
          </Row>
          <Row className="pb-3 pt-4 row">
            <Col>
              <h2 className="fw-bold">키오스크 관리</h2>
            </Col>
          </Row>
          <Tabs>
            {infoTabs.map((info, index) => (
              <Tab eventKey={info.header} key={index} title={info.header}>
                {info.bodyComponent}
              </Tab>
            ))}
          </Tabs>
        </Col>
        <Row
          className="py-5 pb-5 align-items-center justify-content-center"
          style={{ gap: "1.2rem" }}
        >
          <Col
            style={{
              flex: 0,
              flexShrink: 0,
              wordBreak: "keep-all",
              padding: 0,
            }}
          >
            <button
              type="button"
              className="btn btn-default"
              onClick={(e) => {
                e.stopPropagation();
                navigate(-1);
              }}
              style={{
                padding: "4px 32px",
              }}
            >
              취소
            </button>
          </Col>
          <Col
            style={{
              flex: 0,
              flexShrink: 0,
              wordBreak: "keep-all",
              padding: 0,
            }}
          >
            <button
              type="submit"
              className="btn btn-default"
              style={{
                padding: "4px 32px",
                marginRight: "1rem",
              }}
              onClick={onClickSave}
            >
              저장
            </button>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default ManageKioskDetail;
