import { useNavigate, useSearchParams, useParams } from 'react-router-dom';

import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { getApiUrl, PrintConsole } from '../../../function/common';
import { Container, Row, Col, Table, Form } from 'react-bootstrap';
import LeftMenu from '../../../components/admin/LeftMenu';

export default function BoardDetail(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [Params] = useSearchParams();
    var Parameters = [];
    for (const entry of Params.entries()) {
        Parameters.push({ [entry[0]] : entry[1] });
    }
    PrintConsole(Parameters);

    const { id } = useParams();

    function getStoreDetail() {
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( getApiUrl('api')
            ).then(
                function(response){
                    if(response.status === 200){
                    
                    }else{
                        //error
                    }
                }
            )
            if (response) {
                return resolve(response);
            }
        });
    };

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

        getStoreDetail();

        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs ={2}>
                        <LeftMenu />
                    </Col>
                    <Col>
                        <p>id : {id}</p>
                        <Row className='p-1'>
                            {/* 2개형 */}
                            <Col xs={{ span:5 }}>
                                <Form.Group className="xs-12" controlId="storeName">
                                    <Form.Label>매장이름 <span className='PointText'>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="매장명을 입력하세요." value={data['storeName']} onChange={onChangeData}/>
                                </Form.Group>
                            </Col>
                            <Col xs={{ span:5 }}>
                                <Form.Group className="xs-12" controlId="storeName">
                                    <Form.Label>매장이름 <span className='PointText'>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="매장명을 입력하세요." value={data['storeName']} onChange={onChangeData}/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='p-1'>
                            {/* 3개형 */}
                            <Col xs={{ span:3 }}>
                                <Form.Group className="xs-12" controlId="storeName1">
                                    <Form.Label>매장이름 <span className='PointText'>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="매장명을 입력하세요." value={data['storeName']} onChange={onChangeData}/>
                                </Form.Group>
                            </Col>
                            <Col xs={{ span:3 }}>
                                <Form.Group className="xs-12" controlId="storeName2">
                                    <Form.Label>매장이름 <span className='PointText'>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="매장명을 입력하세요." value={data['storeName']} defaultValue={data['storeName']} onChange={onChangeData}/>
                                </Form.Group>
                            </Col>
                            <Col xs={{ span:3 }}>
                                <Form.Group className="xs-12" controlId="storeName3">
                                    <Form.Label>매장이름 <span className='PointText'>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="매장명을 입력하세요." value={data['storeName']} onChange={onChangeData}/>
                                </Form.Group>
                            </Col>
                            <Col xs={{ span:3 }}>
                                <Form.Group className="xs-12" controlId="storeName">
                                    <Form.Label>매장이름 <span className='PointText'>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="매장명을 입력하세요." value={data['storeName']} onChange={onChangeData}/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='p-1'>
                            {/* 1개형 */}
                            <Col xs={{ span:5 }}>
                                <Form.Group className="xs-12" controlId="storeName">
                                    <Form.Label>매장이름 <span className='PointText'>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="매장명을 입력하세요." value={data['storeName']} onChange={onChangeData}/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='p-1'>
                            <Col xs={{ span:5 }}>
                                <Form.Group className="xs-12" controlId="storeName">
                                    <Form.Label>매장이름 <span className='PointText'>*</span></Form.Label>
                                    <Form.Control type="text" placeholder="매장명을 입력하세요." value={data['storeName']} onChange={onChangeData}/>
                                </Form.Group>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
        </>
    )
}