import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import LeftMenu from "../../../../components/admin/LeftMenu";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../../../reactQuery/api";
import { useNavigate } from "react-router-dom";

const PhysicalCardCreate = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    quantity: "",
    cardName: "",
    cardDescription: "",
  });

  const onChangeData = (value, target) => {
    setData((prev) => ({ ...prev, [target]: value }));
  };

  const createBulkMutation = useMutation(
    () =>
      apiClient({
        url: "/cards",
        method: "post",
        data,
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        alert("생성이 완료되었습니다.");
        navigate(-1);
      },
      onError: (err) => {
        alert(err);
      },
    }
  );

  const onClickCreate = () => {
    if (createBulkMutation.isLoading) return;
    createBulkMutation.mutate();
  };

  return (
    <>
      <Container fluid>
        <Row style={{ flexWrap: "nowrap" }}>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">실물카드 생성</h1>
              </Col>
            </Row>
            <Row
              style={{
                background: "#fff",
                borderRadius: "8px",
                padding: "16px",
              }}
            >
              <Row className="p-1">
                <Col md={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="quantity">
                    <Form.Label>
                      {" "}
                      발급 수량 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={data.quantity}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                        onChangeData(e.target.value, "quantity");
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="cardName">
                    <Form.Label>
                      {" "}
                      카드 명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={data.cardName}
                      onChange={(e) => onChangeData(e.target.value, "cardName")}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 4 }} className="pb-5">
                  <Form.Group className="xs-12" controlId="cardDescription">
                    <Form.Label>
                      {" "}
                      카드 설명 <span className="PointText">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={data.cardDescription}
                      onChange={(e) =>
                        onChangeData(e.target.value, "cardDescription")
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 4 }} className="pb-5">
                  <Button onClick={onClickCreate}>생성하기</Button>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PhysicalCardCreate;
