import React, { useEffect, useState } from "react";
import NewAdminPagination from "../../../components/admin/NewPagination";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import Btn from "../../../components/admin/Btn";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../reactQuery/api";
import CustomModal from "../../../components/common/CustomModal";
import { queryClient } from "../../../reactQuery/queryClient";
import { stringify } from "qs";

const Inbounds = () => {
  const [page, setPage] = useState(0);
  const [size] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [usePhoneNumber, setUsePhoneNumber] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [memoModal, setMemoModal] = useState({
    show: false,
    memo: "",
    inboundId: 0,
    inboundName: "",
  });
  const [searchInboundStatuses, setSearchInboundStatuses] = useState([]);

  const onChangeInboundStatuses = (e) => {
    if (
      searchInboundStatuses.findIndex((status) => status === e.target.value) ===
      -1
    ) {
      setSearchInboundStatuses((prev) => [...prev, e.target.value]);
    } else {
      setSearchInboundStatuses((prev) =>
        prev.filter((status) => status !== e.target.value)
      );
    }
  };

  // 인바운드 목록 조회
  const { data, refetch } = useQuery(
    ["display", "inbound", page],
    () =>
      apiClient({
        url: "/inbound/search",
        params: {
          page,
          size,
          usePhoneNumber,
          ...(Boolean(searchString) && { searchString }),
          ...(searchInboundStatuses.length > 0 && {
            statuses: searchInboundStatuses,
          }),
        },
        method: "get",
        paramsSerializer: (params) => {
          return stringify(params, { arrayFormat: "repeat" });
        },
      }),
    {
      onError: (err) => alert(err.message),
      enabled: false,
      suspense: false,
      keepPreviousData: true,
      refetchOnMount: "always",
      refetchOnReconnect: "always",
      refetchOnWindowFocus: "always",
    }
  );

  //   메모 뮤테이션
  const setMemoMutation = useMutation(
    ({ inboundId, memo }) =>
      apiClient({
        url: `/inbound/update-memo`,
        data: {
          inboundId,
          memo,
        },
        method: "put",
      }).then((res) => res.data),
    {
      onSuccess: () => {
        alert("메모가 수정되었습니다.");
        refetch();
      },
      onError: (err) => {
        alert(err.message);
      },
      onSettled: () => {
        setMemoModal({
          show: false,
          memo: "",
          inboundId: 0,
          inboundName: "",
        });
      },
    }
  );

  // 고객 상태 업데이트 뮤테이션
  const updateStatusMutation = useMutation(
    ({ inboundId, status }) =>
      apiClient({
        url: "/inbound/update-status",
        method: "put",
        data: {
          inboundId,
          status,
        },
      }).then((res) => res.data),
    {
      onSuccess: () => {
        alert("진행 상태가 업데이트 되었습니다.");
        refetch();
      },
      onError: (err) => {
        alert(err.message);
      },
    }
  );

  // 견적서 전달 뮤테이션
  const sendQuotationMutation = useMutation(
    async ({ inboundId, isDelete }) => {
      console.log(inboundId, isDelete);
      const method = isDelete ? "delete" : "put";
      return await apiClient({
        url: `/inbound/sent-quotation/${inboundId}`,
        method,
      }).then((res) => res.data);
    },
    {
      onSuccess: () => {
        alert("견적서 전달 상태가 업데이트 되었습니다.");
        refetch();
      },
      onError: (err) => {
        alert(err.message);
      },
    }
  );

  // 제안서 전달 뮤테이션
  const sendProposalMutation = useMutation(
    async ({ inboundId, isDelete }) => {
      const method = isDelete ? "delete" : "put";
      return await apiClient({
        url: `/inbound/sent-proposal/${inboundId}`,
        method,
      }).then((res) => res.data);
    },
    {
      onSuccess: () => {
        alert("제안서 전달 상태가 업데이트 되었습니다.");
        refetch();
      },
      onError: (err) => {
        alert(err.message);
      },
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (data?.data?.empty) {
      setPage(0);
    }

    if (data?.data?.totalPages) {
      setTotalPages(data.data.totalPages);
    } else {
      setTotalPages(1);
    }

    if (data?.data?.totalElements) {
      setTotalElements(data.data.totalElements);
    } else {
      setTotalElements(0);
    }
  }, [data]);

  return (
    <Container fluid>
      <Row>
        <Col xs={2} className="LeftBg px-0">
          <LeftMenu />
        </Col>
        <Col className="RightBg px-5">
          <Row className="pb-3 pt-4 row">
            <Col>
              <h1 className="fw-bold">인바운드 발송 로그</h1>
            </Col>
          </Row>

          <Row className="py-1 align-items-center">
            {/* 2개형 */}
            <Col
              xs={"auto"}
              className="px-3 py-1 ms-2 rounded-5 text-white"
              style={{ background: "#FF5864" }}
            >
              <h5 className="mb-0">Total</h5>
            </Col>
            <Col xs={{ span: 1 }}>
              <h5 className="mb-0">{totalElements}</h5>
            </Col>
          </Row>
          <div className="ContentBox TableStyle mt-4">
            <div style={{ maxWidth: "620px" }} className="mx-auto">
              <Row className="p-1 align-items-center justify-content-center g-4">
                <Col xs={12} className="mb-4">
                  <Row className="align-items-center">
                    <Col xs={"auto"}>
                      <h5 className="mb-0 fw-bold">검색어 조건</h5>
                    </Col>
                    <Col>
                      <Form
                        className="form-radio"
                        onChange={(e) => {
                          setUsePhoneNumber(e.target.value);
                        }}
                      >
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`} className="radiobox">
                            <Form.Check
                              defaultChecked
                              inline
                              label="이름으로 검색"
                              name="Filter-1"
                              type={type}
                              id={`inline-${type}-1`}
                              value={false}
                            />
                            <Form.Check
                              inline
                              label="전화번호로 검색"
                              name="Filter-1"
                              type={type}
                              id={`inline-${type}-2`}
                              value={true}
                            />
                          </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="p-1 align-items-center justify-content-center">
                <Col xs={12} className="mb-3">
                  <Row className="align-items-center Formbox">
                    <Col xs={"auto"}>
                      <h5 className="mb-0 fw-bold">검색어</h5>
                    </Col>
                    <Col>
                      <Form.Control
                        onChange={(e) => setSearchString(e.target.value)}
                        type="text"
                        id="ownerName"
                        aria-describedby="passwordHelpBlock"
                        onKeyDown={(e) => {
                          if (e.key.toLowerCase() === "enter") {
                            refetch();
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mb-3">
                  <Row className="align-items-center Formbox">
                    <Col xs={"auto"}>
                      <h5 className="mb-0 fw-bold">진행 상황</h5>
                    </Col>
                    <Col
                      style={{
                        display: "flex",
                        gap: "16px",
                        flexWrap: "wrap",
                      }}
                    >
                      {inboundStatuses.map((status) => (
                        <Form.Group
                          key={status.value}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <Form.Check
                            value={status.value}
                            id={`formCheck${status.value}`}
                            checked={
                              searchInboundStatuses.findIndex(
                                (exist) => exist === status.value
                              ) !== -1
                            }
                            onChange={onChangeInboundStatuses}
                          />
                          <Form.Label
                            htmlFor={`formCheck${status.value}`}
                            style={{ marginBottom: 0 }}
                          >
                            {status.label}
                          </Form.Label>
                        </Form.Group>
                      ))}
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} lg={"auto"}>
                  <Row className="align-items-center justify-content-center filter-btnbox">
                    <Col xs={"auto"}>
                      <Btn
                        type="API"
                        btntext="조회 하기"
                        onClick={() => refetch()}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <div className="ContentBox TableStyle mt-4">
            <Table
              style={{ verticalAlign: "middle" }}
              className="mb-4"
              responsive
            >
              <thead className="text-center">
                <tr>
                  <th>발송 일시</th>
                  <th>이름</th>
                  <th>전화번호</th>
                  <th>발송횟수</th>
                  <th>진행 상태</th>
                  <th>견적서 전달</th>
                  <th>제안서 전달</th>
                  <th>연락 가능 시간</th>
                  <th>메모</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {/* data 값으로 루프 */}
                {totalElements > 0 ? (
                  data &&
                  data.data.content.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.createdAt.replace("T", " ").substring(0, 16)}
                      </td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.inboundName}
                      </td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.inboundPhoneNumber?.replace(
                          /^(\d{2,3})(\d{3,4})(\d{4})$/,
                          `$1-$2-$3`
                        )}
                      </td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.count}
                      </td>
                      <td
                        style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
                      >
                        <Form.Select
                          value={
                            inboundStatuses.find(
                              (status) => status.code === item.status?.code
                            ).value
                          }
                          onChange={(e) => {
                            if (updateStatusMutation.isLoading) return;
                            const result =
                              window.confirm("진행 상태를 변경하시겠습니까?");
                            if (!result) return;
                            updateStatusMutation.mutate({
                              inboundId: item.inboundId,
                              status: e.target.value,
                            });
                          }}
                          style={{ width: "fit-content" }}
                        >
                          {inboundStatuses.map((status) => (
                            <option key={status.value} value={status.value}>
                              {status.label}
                            </option>
                          ))}
                        </Form.Select>
                      </td>
                      <td
                        style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
                      >
                        <Form.Select
                          value={item.sentQuotation}
                          onChange={(e) => {
                            if (sendQuotationMutation.isLoading) return;
                            const result = window.confirm(
                              "견적서 전달 여부를 변경하시겠습니까?"
                            );
                            if (!result) return;
                            const isDelete =
                              e.target.value === "true" ? false : true;
                            sendQuotationMutation.mutate({
                              inboundId: item.inboundId,
                              isDelete,
                            });
                          }}
                          style={{ width: "fit-content" }}
                        >
                          <option value={"false"}>미전달</option>
                          <option value={"true"}>전달</option>
                        </Form.Select>
                        {item.sentQuotation &&
                          "(" +
                            item.sentQuotationAt
                              ?.replace("T", " ")
                              .substring(0, 16) +
                            ")"}
                      </td>
                      <td
                        style={{ wordBreak: "keep-all", whiteSpace: "nowrap" }}
                      >
                        <Form.Select
                          value={item.sentProposal}
                          onChange={(e) => {
                            if (sendProposalMutation.isLoading) return;
                            const result = window.confirm(
                              "제안서 전달 여부를 변경하시겠습니까?"
                            );
                            if (!result) return;
                            const isDelete =
                              e.target.value === "true" ? false : true;
                            sendProposalMutation.mutate({
                              inboundId: item.inboundId,
                              isDelete,
                            });
                          }}
                          style={{ width: "fit-content" }}
                        >
                          <option value={"false"}>미전달</option>
                          <option value={"true"}>전달</option>
                        </Form.Select>
                        {item.sentProposal &&
                          "(" +
                            item.sentProposalAt
                              ?.replace("T", " ")
                              .substring(0, 16) +
                            ")"}
                      </td>
                      <td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.businessTime}
                      </td>
                      <td>
                        <Button
                          onClick={() =>
                            setMemoModal({
                              show: true,
                              memo: item.memo,
                              inboundId: item.inboundId,
                              inboundName: item.inboundName,
                            })
                          }
                          style={{ wordBreak: "keep-all" }}
                        >
                          메모
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={9}
                      style={{ padding: "7rem 0", opacity: "0.5" }}
                    >
                      데이터가 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <NewAdminPagination
              pageNationSize={size}
              page={page}
              setPage={setPage}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
            />
          </div>
        </Col>
      </Row>
      {memoModal.show && (
        <CustomModal
          setModal={(bool) =>
            setMemoModal({
              show: bool,
              memo: "",
              inboundId: 0,
              inboundName: "",
            })
          }
          callbackOfClose={() =>
            setMemoModal({
              show: false,
              memo: "",
              inboundId: 0,
              inboundName: "",
            })
          }
          callbackOfOk={() => {
            if (memoModal.memo?.length === 0 || !memoModal.memo)
              return setMemoModal({
                show: false,
                memo: "",
                inboundId: 0,
                inboundName: "",
              });
            if (setMemoMutation.isLoading) return;
            const { inboundId, memo } = memoModal;
            setMemoMutation.mutate({ inboundId, memo });
          }}
          messageOfClose="취소"
          messageOfOk="수정하기"
          textOfHeader={`[인바운드] ${memoModal.inboundName}님`}
          textOfBody={
            <Form
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <Row>
                {/* <Form.Group>
                  <Form.Label>견적서</Form.Label>
                  <Form.Select>
                    <option value={true}>전달</option>
                    <option value={false}>미전달</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>제안서</Form.Label>
                  <Form.Select>
                    <option value={true}>전달</option>
                    <option value={false}>미전달</option>
                  </Form.Select>
                </Form.Group> */}
              </Row>
              {/* <Form.Group>
                <Form.Label>진행상황</Form.Label>
                <Form.Control
                  onChange={(e) =>
                    setMemoModal((prev) => ({ ...prev, memo: e.target.value }))
                  }
                  type="text"
                  value={""}
                  placeholder="메모할 내용을 입력해주세요"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>규모</Form.Label>
                <Form.Control
                  onChange={(e) =>
                    setMemoModal((prev) => ({ ...prev, memo: e.target.value }))
                  }
                  type="text"
                  value={""}
                  placeholder="메모할 내용을 입력해주세요"
                />
              </Form.Group> */}
              <Form.Group>
                <Form.Label>메모</Form.Label>
                <Form.Control
                  onChange={(e) =>
                    setMemoModal((prev) => ({ ...prev, memo: e.target.value }))
                  }
                  type="text"
                  value={memoModal.memo ?? ""}
                  placeholder="메모할 내용을 입력해주세요"
                  as={"textarea"}
                  style={{
                    resize: "none",
                    height: "40vh",
                    border: "1px solid #dddddd",
                    borderRadius: "4px",
                  }}
                />
              </Form.Group>
            </Form>
          }
        />
      )}
    </Container>
  );
};

export default Inbounds;

const inboundStatuses = [
  {
    label: "첫 인바운드",
    value: "FIRST_INBOUND",
    code: 1,
  },
  {
    label: "중복",
    value: "DUPLICATED",
    code: 2,
  },
  {
    label: "콜",
    value: "CALL",
    code: 3,
  },
  {
    label: "대기",
    value: "WAITING",
    code: 4,
  },
  {
    label: "협의 중",
    value: "DISCUSSION",
    code: 5,
  },
  {
    label: "계약 중",
    value: "CONTRACT",
    code: 6,
  },
  {
    label: "세팅 단계",
    value: "SETTING",
    code: 7,
  },
  {
    label: "완료",
    value: "COMPLETE",
    code: 8,
  },
];
