import { useMatch } from "react-router-dom";

import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import adminApi from "../../../../function/apiConfig";
import LeftMenu from "../../../../components/admin/LeftMenu";
import { useQuery } from "@tanstack/react-query";
import PhysicalCardDetailBoard from "../../../../components/product/physicalCard/PhysicalCardDetailBoard";
import NewAdminPagination from "../../../../components/admin/NewPagination";
import useExportExcel from "../../../../hooks/excel/useExportExcel";
import moment from "moment";

export default function PhysicalCardDetail() {
  const bulkId =
    useMatch("/admin/product/physical-card/detail/:storeId/:bulkId").params
      ?.bulkId ?? null;
  const storeId =
    useMatch("/admin/product/physical-card/detail/:storeId/:bulkId").params
      ?.storeId ?? null;
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const [data, setData] = useState([]);

  const { isLoading: storeInfoLoading, data: storeInfo } = useQuery(
    ["cardBulkDetail", storeId],
    async () =>
      await adminApi({
        url: `/stores/${storeId}`,
        method: "get",
        customOption: { errorPopup: false },
      }).then((res) => res),
    {
      enabled: Boolean(storeId),
    }
  );

  const {
    isLoading: bulkDataLoading,
    data: bulkData = { content: [] },
    refetch: bulkRefetch,
  } = useQuery(
    ["cardBulkDetail", bulkId],
    async () =>
      await adminApi({
        url: `/card-bulks/${bulkId}/cards`,
        method: "get",
        params: {
          page,
          size,
        },
        customOption: { errorPopup: false },
      }).then((res) => res),
    {
      enabled: Boolean(bulkId),
    }
  );

  const { exportExcel, exportLoading } = useExportExcel({
    exportTargetName: `${storeInfo.storeName.replace(
      /[*?:₩/[\]]/g,
      "_"
    )}_실물카드_목록`,
    apiUrl: `/card-bulks/${bulkId}/cards`,
    params: {
      size: totalElements,
    },
    dataFormatter: (data) =>
      data.content.map((col) => ({
        ...col,
        status: col.status?.code ? col.status.name : "-",
        createdAt: col.createdAt
          ? moment(col.createdAt).format("YY-MM-DD kk:mm")
          : "-",
        connectedAt: col.connectedAt
          ? moment(col.connectedAt).format("YY-MM-DD kk:mm")
          : "-",
        couponProductType: col.couponProductType?.code
          ? col.couponProductType.description
          : "-",
        deleted: col.deleted ? "O" : "X",
      })),
    sheetColumn: [
      { header: "카드 id", key: "id" },
      { header: "카드 번호", key: "number" },
      { header: "카드 상태", key: "status" },
      { header: "생성일시", key: "createdAt" },
      { header: "연동일시", key: "connectedAt" },
      { header: "멤버십 id", key: "membershipId" },
      { header: "멤버십 번호", key: "membershipNumber" },
      { header: "멤버십 회원명", key: "membershipName" },
      { header: "쿠폰 id", key: "couponId" },
      { header: "쿠폰 번호", key: "couponNumber" },
      { header: "쿠폰 타입", key: "couponProductType" },
      { header: "삭제 여부", key: "deleted" },
    ],
  });

  const onClickExportExcel = () => {
    if (exportLoading) return;
    exportExcel();
  };

  useEffect(() => {
    bulkRefetch();
  }, [page, size]);

  useEffect(() => {
    if (bulkData?.empty) {
      setPage(0);
    }

    if (bulkData?.totalPages) {
      setTotalPages(bulkData.totalPages);
    } else {
      setTotalPages(1);
    }

    if (bulkData?.totalElements) {
      setTotalElements(bulkData.totalElements);
    } else {
      setTotalElements(0);
    }

    if (bulkData?.content?.length > 0) {
      setData(bulkData.content);
    } else {
      setData([]);
    }
  }, [bulkData]);

  if (bulkDataLoading || storeInfoLoading) return <></>;

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">
                  {storeInfo.storeName} 실물카드 상세 정보
                </h1>
              </Col>
            </Row>

            <div className="ContentBox Formbox mt-4">
              <Row>
                <Col>
                  <h4 className="fw-bold">카드 리스트</h4>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <Button onClick={onClickExportExcel}>
                    전체 목록 엑셀 다운로드
                  </Button>
                  <span>테이블 사이즈</span>
                  <select
                    value={size}
                    onChange={(e) => setSize(e.target.value)}
                  >
                    {[10, 25, 50, 100, 500, 1000].map((num) => (
                      <option value={num} key={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row>
                <Col className="TableStyle mt-4 text-center">
                  <Table
                    style={{ verticalAlign: "middle" }}
                    className="mb-4"
                    responsive
                  >
                    <thead>
                      <tr>
                        <th>카드 id</th>
                        <th>카드 번호</th>
                        <th>카드 상태</th>
                        <th>생성일시</th>
                        <th>연동일시</th>
                        <th>멤버십 id</th>
                        <th>멤버십 번호</th>
                        <th>멤버십 회원명</th>
                        <th>쿠폰 id</th>
                        <th>쿠폰 번호</th>
                        <th>쿠폰 타입</th>
                        <th>삭제 여부</th>
                      </tr>
                    </thead>
                    <tbody>
                      <PhysicalCardDetailBoard data={bulkData.content} />
                    </tbody>
                  </Table>
                  <NewAdminPagination
                    pageNationSize={10}
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                    setTotalPages={setTotalPages}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
