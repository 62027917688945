import React, { Suspense, useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import DetailPagination from "../../../components/admin/DetailPagination";
import Btn from "../../../components/admin/Btn";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../reactQuery/api";
import StoreGroupBoard from "./StoreGroupBoard";
import CustomModal from "../../../components/common/CustomModal";
import { queryClient } from "../../../reactQuery/queryClient";
import axios from "axios";

const StoreGroup = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [name, setName] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);

  const [storeListModal, setStoreListModal] = useState({
    show: false,
    storeGroupId: 0,
    list: [],
    groupName: "",
  });
  const size = 10;

  const { data: storeGroups = { content: [] }, refetch: storeGroupFetch } =
    useQuery(
      ["getStoreGroupSearch", page, name, isDeleted],
      () =>
        apiClient({
          url: "/store-group/search",
          params: {
            page,
            size,
            isDeleted: isDeleted,
          },
        }).then((res) => res.data),
      {
        onSuccess: (res) => {
          if (!res) return;
          setTotalPage(res.totalPages);
        },
        enabled: false,
      }
    );

  // 발행권한 위임 상태 토글 뮤테이션
  const toggleDelegatePublishMutation = useMutation(
    ({ storeGroupId, storeGroupChainId, useGroupPublish, onSuccessCallBack }) =>
      apiClient({
        url: "/store-group/delegate-publish",
        method: "put",
        data: {
          storeGroupId,
          storeGroupChainId,
          useGroupPublish,
        },
      }).then((res) => ({ res: res.data, req: { onSuccessCallBack } })),
    {
      onSuccess: ({ res, req }) => {
        if (!res) return;
        queryClient.invalidateQueries({
          queryKey: ["getStoreGroupSearch", page, name, isDeleted],
        });
        if (req.onSuccessCallBack) {
          req.onSuccessCallBack();
        }
      },
      onError: (err) => {
        alert(err);
      },
    }
  );

  useEffect(() => {
    storeGroupFetch();
    if(isDeleted){
      setPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isDeleted]);

  const location = useLocation();

  const setPageStatus = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get("page") || "0", 10);
    const isDeleted = params.get("isDeleted") === "true";

    setPage(page);
    setIsDeleted(isDeleted);
  }, [setPage, size, setIsDeleted, location]);

  useEffect(() => {
    setPageStatus();
  }, [setPageStatus]);

  const handlePageMove = (page) => {
    storeGroupFetch();
    navigate(getNavigateStr(page));
    
  };

  const getNavigateStr = (page) => {
    return `?page=${page}&size=${size}&isDeleted=${isDeleted}`;
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">가맹점 그룹 관리</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                {/* <h5 className="mb-0">{totalElement}</h5> */}
              </Col>
              <Row className="p-1 justify-content-end">
                <Col xs={"auto"} className="btn-black">
                  <Btn
                    type="API"
                    btntext="신규 그룹 생성"
                    onClick={() => navigate("/admin/store/group/detail")}
                  />
                </Col>
              </Row>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "820px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">그룹 명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                          onKeyDown={(e) => {
                            if (e.key.toLowerCase() === "enter") {
                              storeGroupFetch();
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="align-items-center Formbox mt-4">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">삭제된 그룹 검색</h5>
                      </Col>
                      <Col>
                        <Form.Check
                          checked={isDeleted}
                          onChange={() => setIsDeleted(!isDeleted)}
                          type="checkbox"
                        />
                      </Col>
                    </Row>
                    <Row className="align-items-center Formbox mt-4">
                      <Col className="justify-content-center d-flex">
                        <Button onClick={() => storeGroupFetch()}>
                          조회하기
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4"
                responsive
              >
                <thead className="text-center">
                  <tr>
                    <th>group id</th>
                    <th>그룹명</th>
                    <th>그룹 설명</th>
                    <th>헤드가맹점 명</th>
                    {/* <th>소속 가맹점 수</th> */}
                    {/* <th>그룹 로고 URL</th> */}
                    <th>수수료율</th>
                    <th>전체멤버십열람</th>
                    <th>동기화 허용</th>
                    <th>그룹 상세</th>
                  </tr>
                </thead>

                <tbody className="text-center" style={{ position: "relative" }}>
                  <Suspense
                    fallback={
                      <tr style={{ height: "250px" }}>
                        <Spinner
                          as={"td"}
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            marginLeft: "-18px",
                          }}
                          animation="border"
                        />
                      </tr>
                    }
                  >
                    <StoreGroupBoard
                      data={storeGroups.content}
                      onClickStoreList={({
                        storeGroupId,
                        storeList,
                        groupName,
                      }) =>
                        setStoreListModal((prev) => ({
                          ...prev,
                          show: true,
                          storeGroupId,
                          list: storeList,
                          groupName,
                        }))
                      }
                      refetch={storeGroupFetch}
                    />
                  </Suspense>
                </tbody>
              </Table>
              <DetailPagination
                currentPage={page}
                totalPage={totalPage}
                onPageMove={handlePageMove}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {storeListModal.show && (
        <CustomModal
          textOfHeader={`${storeListModal.groupName} 내 가맹점 별 발행 권한 수정`}
          textOfBody={
            <Container>
              <Row
                style={{ background: "#000", color: "#fff", height: "2.6rem" }}
              >
                <Col
                  style={{
                    fontSize: "1.2rem",
                    textAlign: "center",
                    lineHeight: "2.6rem",
                  }}
                >
                  가맹점 명
                </Col>
                <Col
                  style={{
                    fontSize: "1.2rem",
                    textAlign: "center",
                    lineHeight: "2.6rem",
                  }}
                >
                  발행 권한 위임 상태
                </Col>
              </Row>
              <div
                style={{
                  height: "320px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {storeListModal.list.map((storeInfo) => {
                  const { store, storeGroupChainId, useGroupPublish } =
                    storeInfo;
                  return (
                    <Row key={storeGroupChainId}>
                      <Col
                        style={{
                          fontSize: "1.2rem",
                          textAlign: "center",
                          lineHeight: "2.6rem",
                        }}
                      >
                        {store.storeName}
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Form.Check
                          type="switch"
                          style={{ scale: "1.4" }}
                          checked={useGroupPublish}
                          onChange={() => {
                            if (toggleDelegatePublishMutation.isLoading) return;
                            toggleDelegatePublishMutation.mutate({
                              storeGroupId: storeListModal.storeGroupId,
                              storeGroupChainId,
                              useGroupPublish: !useGroupPublish,
                              onSuccessCallBack: () =>
                                setStoreListModal((prev) => ({
                                  ...prev,
                                  list: prev.list.map((store) =>
                                    store.storeGroupChainId ===
                                    storeGroupChainId
                                      ? {
                                          ...store,
                                          useGroupPublish:
                                            !store.useGroupPublish,
                                        }
                                      : store
                                  ),
                                })),
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </Container>
          }
          setModal={setStoreListModal}
        />
      )}
      {/* <CreateStoreGroupModal /> */}
    </>
  );
};

export default StoreGroup;
