import useSettlements from "../../hooks/settlement/useSettlements";
import { Form } from "react-bootstrap";

export default function SettlementBoard({
  page,
  showingStoreName,
  setTotalPage,
  setTotalElements,
  setCheckedIdSet,
  checkedIdSet,
}) {
  const { settlements } = useSettlements({
    page,
    showingStoreName,
    setTotalPage,
    setTotalElements,
  });

  const mapedSettlements = settlements.content.map((settlement) => {
    const {
      id,
      year,
      weekOfYear,
      settledAt,
      taxInvoiceIssuedAt,
      startDate,
      endDate,
      store,
      storeSalesPrice,
      fee,
      storeSettlePrice,
      platformFee,
      price,
      settleAgentStatus,
      scheduledSettledAt,
    } = settlement;

    return (
      <tr key={id} className="board-row-except">
        <td style={{ paddingLeft: "10px" }}>
          <Form>
            <div key={`default-${id}`} className="">
              <Form.Check // prettier-ignore
                disabled={
                  settleAgentStatus == undefined || settleAgentStatus?.code == 3
                    ? true
                    : false
                }
                type={"checkbox"}
                checked={checkedIdSet.has(id)}
                id={`default-${id}`}
                onChange={() => {
                  if (checkedIdSet.has(id)) {
                    checkedIdSet.delete(id);
                    setCheckedIdSet(new Set(checkedIdSet));
                  } else {
                    setCheckedIdSet(new Set(checkedIdSet.add(id)));
                  }
                }}
              />
            </div>
          </Form>
        </td>
        <td>{year}</td>
        <td>{weekOfYear}</td>
        <td>{settledAt ? settledAt : scheduledSettledAt}</td>
        <td>{settleAgentStatus?.description}</td>
        <td>{startDate}</td>
        <td>{endDate}</td>
        <td
          style={{
            maxWidth: "8rem",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {store.businessName}
        </td>
        <td
          style={{
            maxWidth: "8rem",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {store.storeName}
        </td>
        <td>{storeSalesPrice}</td>
        <td>{fee}</td>
        <td>{storeSettlePrice}</td>
        <td>{taxInvoiceIssuedAt}</td>
        {/*<td>{platformFee}</td>*/}
        {/*<td>{price}</td>*/}
      </tr>
    );
  });

  return mapedSettlements;
}
