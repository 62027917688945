import { Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Btn from "../../../components/admin/Btn";
import DetailPagination from "../../../components/admin/DetailPagination";
import CustomModal from "../../../components/common/CustomModal";
import { useLogoDownload } from "../../../hooks/store/useLogoDownload";
import { useQRDownload } from "../../../hooks/store/useQRDownload";
import ConnectStoreBoard from "../../../components/store/ConnectStoreBoard";
import { useConnectStores } from "../../../hooks/store/useConnectStores";
import adminApi from "../../../function/apiConfig";
import { isProduction } from "../../../function/common";

export default function StoreConnect() {
  const [modal, setModal] = useState({ showModal: false });
  const navigate = useNavigate();
  const location = useLocation();
  const [storeId, setStoreId] = useState();
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalElement, setTotalElement] = useState(0);

  const { mutateLogo } = useLogoDownload();
  const { mutateQR } = useQRDownload();

  const setPageStatus = useCallback(() => {
    const [_, page, storeStatus, storeAcceptStatus, storeName, ownerName] =
      location.search
        .replace(
          /\?page=|&storeStatus=|&storeAcceptStatus=|&storeName=|&ownerName=/g,
          " "
        )
        .split(" ");

    if (!page) {
      setPage(0);
      return;
    }

    setPage(page);
  }, [setPage, location]);

  useEffect(() => {
    setPageStatus();
  }, [setPageStatus]);

  const getNavigateStr = (page = 0) => {
    return `?page=${page}`;
  };

  const handleSearchBtnClick = () => {
    onConnect({
      storeUserId: isProduction() ? 243 : 81,
      storeId: parseInt(storeId),
    });
  };

  const handlePageMove = (page = 0) => {
    navigate(getNavigateStr(page));
  };

  const handleLogoDownBtnClick = (domain, logoUrl) => {
    mutateLogo({
      domain,
      logoUrl,
    });
  };

  const handleQRDownBtnClick = (domain) => {
    mutateQR(domain);
  };

  const handleInputKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }

    handleSearchBtnClick();
  };

  const { stores, refetch } = useConnectStores({ page });

  useEffect(() => {
    setTotalElement(stores.totalElements);
    setTotalPage(stores.totalPages);
  }, [setTotalElement, setTotalPage, stores]);

  const connectStore = ({ storeUserId, storeId }) => {
    adminApi
      .post("stores/connect/ceo", {
        storeUserId,
        storeId,
      })
      .then(() => {
        alert("연동 성공!");
        refetch();
      });
  };

  const onConnect = ({ storeUserId, storeId }) => {
    if (!storeUserId && !storeId) alert("알 수 없는 에러 : 변수 없음!");

    const confirm = window.confirm("해당 가맹점을 연동하시겠습니까?");
    if (!confirm) return;

    connectStore({ storeUserId, storeId });
  };

  const disconnectStore = ({ storeUserId, storeId }) => {
    adminApi
      .post("stores/disconnect", {
        storeUserId,
        storeId,
      })
      .then(() => {
        alert("해제 성공!");
        refetch();
      });
  };

  const onDisconnect = ({ storeUserId, storeId }) => {
    if (!storeUserId && !storeId) alert("알 수 없는 에러 : 변수 없음!");

    const confirm = window.confirm("해당 가맹점 연동을 해제하시겠습니까?");
    if (!confirm) return;

    disconnectStore({ storeUserId, storeId });
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">
                  가맹점 연동 목록{" "}
                  {isProduction() ? "(flexday2022)" : "(jyy9501)"}
                </h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElement}</h5>
              </Col>
              <Row className="p-1 justify-content-end">
                <Col xs={"auto"} className="btn-black">
                  {/* <Btn
                    type="API"
                    btntext="엑셀 다운"
                    onClick={handleExcelDownBtnClick}
                  /> */}
                </Col>
              </Row>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "820px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">가맹점 ID</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          defaultValue={storeId}
                          onChange={({ target }) => setStoreId(target.value)}
                          type="number"
                          id="storeId"
                          aria-describedby="passwordHelpBlock"
                          onKeyDown={handleInputKeyDown}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="p-1 align-items-center justify-content-center">
                  <Col xs={12} lg={"auto"}>
                    <Row className="align-items-center justify-content-center filter-btnbox">
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="연동 하기"
                          onClick={handleSearchBtnClick}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="ContentBox TableStyle mt-4">
              <Table style={{ verticalAlign: "middle" }} className="mb-4">
                <thead className="text-center">
                  <tr>
                    <th>store id</th>
                    <th>가맹점명</th>
                    <th>대표명</th>
                    <th>운영 상태</th>
                    <th>승인 상태</th>
                    <th>계좌 승인 상태</th>
                    <th>팝빌 연동</th>
                    <th>상품 목록</th>
                    <th>스토어 바로가기</th>
                    <th>개설일</th>
                    <th>연동 해제하기</th>
                  </tr>
                </thead>

                <tbody className="text-center" style={{ position: "relative" }}>
                  <Suspense
                    fallback={
                      <tr style={{ height: "250px" }}>
                        <Spinner
                          as={"td"}
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            marginLeft: "-18px",
                          }}
                          animation="border"
                        />
                      </tr>
                    }
                  >
                    <ConnectStoreBoard
                      onLogoDownBtnClick={handleLogoDownBtnClick}
                      onQRDownBtnClick={handleQRDownBtnClick}
                      stores={stores}
                      refetch={refetch}
                      onDisconnect={onDisconnect}
                    />
                  </Suspense>
                </tbody>
              </Table>
              <DetailPagination
                currentPage={page}
                totalPage={totalPage}
                onPageMove={handlePageMove}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {modal.showModal && (
        <CustomModal
          callbackOfOk={modal.callbackOfOk}
          textOfHeader={modal.textOfHeader}
          textOfBody={modal.textOfBody}
          setModal={setModal}
        />
      )}
    </>
  );
}
