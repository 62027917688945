import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import adminApi from "../../../function/apiConfig";
import { apiClient } from "../../../reactQuery/api";
import { useNavigate } from "react-router-dom";

const isValidLatLng = (latitude, longitude) => {
  if (!latitude || !longitude) return false;
  return (
    !isNaN(latitude) &&
    !isNaN(longitude) &&
    latitude >= -90 &&
    latitude <= 90 &&
    longitude >= -180 &&
    longitude <= 180
  );
};

const StoreMap = () => {
  const mapRef = useRef();
  let map;

  const { data: storeMapData } = useQuery(["storesMap"], () =>
    apiClient({
      url: "/stores/map",
    }).then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return [];
    })
  );

  function KakaoMapScript() {
    if (storeMapData.length === 0) return;
    //검색시 지도가 새로 생성되는 현상이 있어서 element 초기화
    const mapArea = document.getElementById("map-container");

    mapArea.innerHTML = "";

    const container = mapRef?.current;

    try {
      window.kakao.maps.load(() => {
        let markerPosition = [];
        let bounds = new window.kakao.maps.LatLngBounds();
        const geocoder = new window.kakao.maps.services.Geocoder();
        let currentInfowindow = null;
        const options = {
          center: new window.kakao.maps.LatLng(
            storeMapData[0].latitude,
            storeMapData[0].longitude
          ),
          level: storeMapData.length === 1 ? 3 : 13,
        };
        map = new window.kakao.maps.Map(container, options);

        const createMarker = (lat, lon, title, address, addressDetail) => {
          const position = new window.kakao.maps.LatLng(lat, lon);
          const marker = new window.kakao.maps.Marker({
            map,
            position,
            title,
          });

          // 마커 클릭 이벤트 추가
          window.kakao.maps.event.addListener(marker, "click", () => {
            if (currentInfowindow) {
              currentInfowindow.close();
            }

            /** infowindow에는 일반적인 function적용 불가; dom객체 접근해야함 */
            const infoContainer = document.createElement("div");
            infoContainer.className = "info-container";

            const storeNameDiv = document.createElement("div");
            storeNameDiv.className = "mb-5";
            storeNameDiv.innerHTML = `<span class="title-font">상점명 : </span><span class="content-font">${title}</span>`;

            let locationInfo = document.createElement("div");
            locationInfo.className = "mb-5";
            locationInfo.style = "width:max-content";
            locationInfo.innerHTML = `<span class="title-font" >위치 : </span><span style="margin-right:10px;" class="content-font">${address} ${addressDetail}</span>`;

            //   let moveDetailBtn = document.createElement("div");
            //   moveDetailBtn.style = "margin-top:0.5rem; ";
            //   moveDetailBtn.innerHTML = `<span class="content-font">상세보기</span>`;
            //   moveDetailBtn.onclick = () => alert(title);

            infoContainer.append(title, locationInfo);
            const content = infoContainer;

            const infowindow = new window.kakao.maps.InfoWindow({
              content,
            });

            infowindow.open(map, marker);
            currentInfowindow = infowindow;
          });

          markerPosition.push(position);
          bounds.extend(position);
          return marker;
        };

        storeMapData.forEach((el) => {
          const { latitude, longitude, storeName, storeAddress } = el;
          if (isValidLatLng(latitude, longitude)) {
            createMarker(
              latitude,
              longitude,
              storeName,
              storeAddress?.address,
              storeAddress?.addressDetail
            );
          } else if (storeAddress?.address) {
            geocoder.addressSearch(storeAddress.address, (result, status) => {
              if (status === window.kakao.maps.services.Status.OK) {
                const { x: longitude, y: latitude } = result[0];
                el.latitude = parseFloat(latitude);
                el.longitude = parseFloat(longitude);
                createMarker(
                  latitude,
                  longitude,
                  storeName,
                  storeAddress?.address,
                  storeAddress?.addressDetail
                );
              }
            });
          } else {
            console.error(`주소 변환 실패: ${storeAddress.address}`);
          }

          // const alertFun = () => {
          //   alert(el.storeName);
          // };

          // 지도 클릭 이벤트 추가 (인포윈도우 닫기)
          window.kakao.maps.event.addListener(map, "click", () => {
            if (currentInfowindow) {
              currentInfowindow.close();
              currentInfowindow = null;
            }
          });
        });
        for (let i = 0; i < markerPosition.length; i++) {
          // LatLngBounds 객체에 좌표를 추가합니다
          bounds.extend(markerPosition[i]);
        }

        // 범위 최적화
        if (markerPosition.length > 0) {
          map.setBounds(bounds);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (storeMapData?.length > 0) {
      KakaoMapScript();
    }
  }, [storeMapData, mapRef]);

  return (
    <div style={{ position: "relative" }}>
      <div id={"map-container"} ref={mapRef} />
      <div
        style={{
          position: "absolute",
          top: 0,
          zIndex: 10,
          padding: "0.2rem 0.8rem",
          background: "rgba(100, 100, 100, .8)",
          borderBottomRightRadius: "0.4rem",
          color: "#fff",
          fontWeight: "700",
          fontSize: "1.12rem",
        }}
      >
        총 운영중인 가맹점 : {storeMapData?.length ?? 0}개
      </div>
    </div>
  );
};

export default StoreMap;
