import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../reactQuery/api";

const downloadExcel = (response) => {
  const blob = new Blob([response.data]);
  const fileUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = fileUrl;
  link.style.display = "none";
  link.download = getFileName(response);

  document.body.appendChild(link);

  link.click();
  link.remove();
};

const getFileName = (res) => {
  const disposition = res.headers["content-disposition"];
  const fileName = decodeURI(
    disposition
      .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
      .replace(/['"]/g, "")
  );

  return fileName;
};

export function useExcelDownload(methodProp) {
  const method = methodProp ?? "post";
  const { mutate: mutateExcel } = useMutation(
    async ({ url, params }) =>
      await apiClient({
        url,
        params,
        method,
        responseType: "arraybuffer",
      }),
    {
      enabled: false,
      onSuccess: (res) => {
        downloadExcel(res);
      },
      onError: ({ res }) => {
        alert(res.data.message);
      },
    }
  );

  return { mutateExcel };
}
