import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../reactQuery/api";
import LeftMenu from "../../../components/admin/LeftMenu";
import TextEditor from "../../../components/admin/TextEditor";
import StoreSearchModal from "../../../components/store/StoreSearchModal";
import { doorSystemStatusConvert } from "../../../function/common";

const DoorSystemDetail = () => {
  const navigate = useNavigate();
  const match = useMatch("/admin/doorSystems/detail/:serverId");
  const serverId = match?.params?.serverId ?? null;
  const isEdit = Boolean(serverId);
  const [storeSearchModal, setStoreSearchModal] = useState({
    show: false,
    callback: () => {},
  });
  //   required
  const [store, setStore] = useState({ storeName: "", storeId: 0 });
  //   required
  const [serverName, setServerName] = useState("");
  //   optional
  const [serverDescription, setServerDescription] = useState("");
  const [status, setStatus] = useState("");

  // 수정일때 기존 정보 불러오기
  useQuery(
    ["doorSystemDetailQuery", store?.storeId],
    () =>
      apiClient({
        url: `/door-system/${serverId}`,
        method: "get",
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        const { store, serverDescription, serverName, status } = res;
        setStore(store ?? null);
        setServerName(serverName ?? "");
        setServerDescription(serverDescription ?? "");
        setStatus(doorSystemStatusConvert(status).value);
      },
      enabled: Boolean(serverId),
      staleTime: 0,
    }
  );

  const createDoorSystemMutation = useMutation(
    async () =>
      await apiClient({
        url: `/door-system`,
        method: "post",
        data: {
          storeId: store?.storeId,
          serverName,
          serverDescription,
        },
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        if (!res) return;
        alert("출입관제 서버가 생성되었습니다.");
        navigate("/admin/doorSystems", { replace: true });
      },
    }
  );

  const updateDoorSystemMutation = useMutation(
    async () =>
      await apiClient({
        url: "/door-system",
        method: "PUT",
        data: {
          serverId,
          serverName,
          serverDescription,
          status,
        },
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        if (!res) return;
        alert("출입관제 서버 정보가 업데이트되었습니다.");
        navigate("/admin/doorSystems", { replace: true });
      },
    }
  );

  const onClickSave = () => {
    if (
      createDoorSystemMutation.isLoading ||
      updateDoorSystemMutation.isLoading
    )
      return;
    if (!isEdit && !store?.storeId) return alert("가맹점을 지정해주세요.");
    if (!serverName) return alert("서버 명을 입력해주세요.");
    if (isEdit && !status) return alert("서버 상태를 입력해주세요.");
    if (isEdit) {
      return updateDoorSystemMutation.mutate();
    }
    createDoorSystemMutation.mutate();
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={2} className="LeftBg px-0">
          <LeftMenu />
        </Col>
        <Col className="RightBg px-5">
          <Row className="pb-3 pt-4 row">
            <Col>
              <h1 className="fw-bold">
                출입관제 서버 {isEdit ? "수정" : "추가"}
              </h1>
            </Col>
          </Row>
          <Row className="p-1">
            {/* 2개형 */}
            <Col md={{ span: 4 }} className="pb-4">
              <Form.Group className="xs-12" controlId="status">
                <Form.Label>
                  가맹점 <span className="PointText">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="가맹점 명"
                  value={store?.storeName || ""}
                  readOnly={true}
                />
              </Form.Group>
            </Col>
            {!isEdit && (
              <Col md={{ span: 4 }} className="pb-4">
                <Row>
                  <Button
                    onClick={() =>
                      setStoreSearchModal({
                        show: true,
                        callback: (store) => setStore(store),
                      })
                    }
                    type="button"
                  >
                    검색
                  </Button>
                </Row>
                <Row className="mt-2">
                  <Button
                    onClick={() => setStore({ storeId: 0, storeName: "" })}
                  >
                    초기화
                  </Button>
                </Row>
              </Col>
            )}
          </Row>
          <Row className="pb-3 pt-4 row">
            <Col>
              <h4 className="fw-medium">서버 명</h4>
              <Form.Group className="Formbox">
                <Form.Control
                  placeholder="서버 명을 입력해주세요"
                  value={serverName || ""}
                  onChange={(e) => setServerName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="pb-3 pt-4 row">
            <Col>
              <h4 className="fw-medium">설명</h4>
              <Form.Group className="Formbox">
                <Form.Control
                  as={"textarea"}
                  rows={5}
                  type="text"
                  placeholder="서버에 대한 설명을 가능한 상세하게 입력해주세요."
                  value={serverDescription || ""}
                  onChange={(e) => setServerDescription(e.target.value)}
                  style={{ resize: "none" }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row
            className="py-5 pb-5 align-items-center justify-content-center"
            style={{ gap: "1.2rem" }}
          >
            <Col
              style={{
                flex: 0,
                flexShrink: 0,
                wordBreak: "keep-all",
                padding: 0,
              }}
            >
              <button
                type="button"
                className="btn btn-default"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(-1);
                }}
                style={{
                  padding: "4px 32px",
                }}
              >
                취소
              </button>
            </Col>
            <Col
              style={{
                flex: 0,
                flexShrink: 0,
                wordBreak: "keep-all",
                padding: 0,
              }}
            >
              <button
                type="submit"
                className="btn btn-default"
                style={{
                  padding: "4px 32px",
                  marginRight: "1rem",
                }}
                onClick={onClickSave}
              >
                저장
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
      {storeSearchModal.show && (
        <StoreSearchModal
          onClickAction={(store) => storeSearchModal.callback(store)}
          //   onClickCancel={() => setStoreSearchModal(false)}
          setModal={setStoreSearchModal}
        />
      )}
    </Container>
  );
};

export default DoorSystemDetail;
