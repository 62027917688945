import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";

const TableRow = ({
  onClickManage = () => {},
  id,
  title,
  contents,
  type,
  tableType,
  onClickCheck = () => {},
  checked,
}) => {
  return (
    <tr className="board-row-except">
      {tableType === "delete" && (
        <td>
          <Form.Check
            type="checkbox"
            checked={checked}
            onChange={onClickCheck}
          />
        </td>
      )}
      <td>{id ?? "-"}</td>
      <td>{title ?? "-"}</td>
      <td>{contents ?? "-"}</td>
      <td>{type}</td>
      {tableType === "default" && (
        <td>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              e.stopPropagation();
              onClickManage();
            }}
            style={{ wordBreak: "keep-all" }}
          >
            관리
          </button>
        </td>
      )}
    </tr>
  );
};

const FaqBoard = ({
  data,
  onClickManage = () => {},
  tableType,
  onClickCheck = () => {},
  checked,
}) => {
  return data.map((row) => (
    <TableRow
      key={row.id}
      {...row}
      onClickManage={() => onClickManage(row.id)}
      tableType={tableType}
      onClickCheck={() => onClickCheck(row.id)}
      checked={checked(row.id)}
    />
  ));
};

export default FaqBoard;
