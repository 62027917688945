export function getDate(date) {
  const newDate = date ? new Date(date) : new Date();

  return newDate;
}

export function transformYYYYMMDD(date) {
  const newDate = date ? new Date(date) : new Date();
  const mmMonth = ("00" + (newDate.getMonth() + 1)).slice(-2);
  const ddDate = ("00" + newDate.getDate()).slice(-2);

  return `${newDate.getFullYear()}-${mmMonth}-${ddDate}`;
}

export const getTwoDigitTimeByOneDigitTime = (time) => {
  return ("00" + time).slice(-2);
};

export const getMonthDayLabel = (value = 0) => {
  const today = new Date();
  const baseDate = new Date(
    today.setDate(today.getDate() + 1 - today.getDay() + value)
  );

  return `${baseDate.getMonth() + 1}-${baseDate.getDate()}`;
};

export const getCurrentWeekLabes = () => {
  const today = new Date();
  const baseDate = new Date(
    today.setDate(today.getDate() + 1 - today.getDay())
  );

  return [
    [getMonthDayLabel(), `(${getDayByDayNumber(baseDate.getDay())})`],
    [getMonthDayLabel(1), `(${getDayByDayNumber(baseDate.getDay() + 1)})`],
    [getMonthDayLabel(2), `(${getDayByDayNumber(baseDate.getDay() + 2)})`],
    [getMonthDayLabel(3), `(${getDayByDayNumber(baseDate.getDay() + 3)})`],
    [getMonthDayLabel(4), `(${getDayByDayNumber(baseDate.getDay() + 4)})`],
    [getMonthDayLabel(5), `(${getDayByDayNumber(baseDate.getDay() + 5)})`],
    [getMonthDayLabel(6), `(${getDayByDayNumber(baseDate.getDay() + 6)})`],
  ];
};

export const getDayByDayNumber = (dayNumber) => {
  const restDayNumber = dayNumber % 7;
  const dayTexts = ["일", "월", "화", "수", "목", "금", "토"];

  return dayTexts[restDayNumber];
};

export const getThisWeek = () => {
  const today = new Date();
  const startOfWeek = new Date(
    today.setDate(today.getDate() + 1 - today.getDay())
  );
  const endOfWeek = new Date(
    today.setDate(today.getDate() + 1 - today.getDay() + 6)
  );

  const startYear = startOfWeek.getFullYear();
  const startMonth = getTwoDigitTimeByOneDigitTime(startOfWeek.getMonth() + 1);
  const startDate = getTwoDigitTimeByOneDigitTime(startOfWeek.getDate());
  const startDay = getDayByDayNumber(startOfWeek.getDay());

  const endYear = endOfWeek.getFullYear();
  const endMonth = getTwoDigitTimeByOneDigitTime(endOfWeek.getMonth() + 1);
  const endDate = getTwoDigitTimeByOneDigitTime(endOfWeek.getDate());
  const endDay = getDayByDayNumber(endOfWeek.getDay());

  return `${startYear}-${startMonth}-${startDate}(${startDay}) - ${endYear}-${endMonth}-${endDate}(${endDay})`;
};
