import { useNavigate, useParams } from "react-router-dom";

import LeftMenu from "../../../components/admin/LeftMenu";
import { Col, Container, Row } from "react-bootstrap";
import { Suspense } from "react";
import PaymentDetailDesc from "../../../components/payment/PaymentDetailDesc";
import SuspenseSpinner from "../../../components/common/SuspenseSpinner";

export default function PaymentDetail() {
  const { orderNumber } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">결제내역 상세정보</h1>
              </Col>
            </Row>
            <Suspense fallback={<SuspenseSpinner />}>
              <PaymentDetailDesc orderNumber={orderNumber} />
            </Suspense>
            <button
              type="button"
              className="btn btn-primary mt-4"
              onClick={() => {
                navigate(-1);
              }}
            >
              뒤로가기
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
