import { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import LeftMenu from "../../../../components/admin/LeftMenu";
import Btn from "../../../../components/admin/Btn";
import NewAdminPagination from "../../../../components/admin/NewPagination";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../../reactQuery/api";
import ProductChainBoard from "../../../../components/product/chain/ProductChainBoard";
import { queryKeys } from "../../../../reactQuery/constants";

export default function ProductChain() {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const [data, setData] = useState([]);

  const [sellChannel, setSellChannel] = useState(undefined);
  const [storeName, setStoreName] = useState(undefined);

  const {
    data: defaultQueryData,
    refetch,
    isLoading: defaultQueryLoading,
  } = useQuery(
    [queryKeys.productChain, page],
    () =>
      apiClient({
        url: "/chain-products",
        params: {
          page,
          size,
          ...(sellChannel && { sellChannel }),
          ...(storeName && { storeName }),
        },
      }),
    {
      onSuccess: (res) => {},
      onError: (err) => alert(err.message),
      enabled: false,
      suspense: false,
      keepPreviousData: true,
      refetchOnMount: "always",
      refetchOnReconnect: "always",
      refetchOnWindowFocus: "always",
    }
  );

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (defaultQueryData?.data?.empty) {
      setPage(0);
    }

    if (defaultQueryData?.data?.totalPages) {
      setTotalPages(defaultQueryData.data.totalPages);
    } else {
      setTotalPages(1);
    }

    if (defaultQueryData?.data?.totalElements) {
      setTotalElements(defaultQueryData.data.totalElements);
    } else {
      setTotalElements(0);
    }

    if (defaultQueryData?.data?.content?.length > 0) {
      setData(defaultQueryData.data.content);
    } else {
      setData([]);
    }
  }, [defaultQueryData]);

  const onClickSearch = () => {
    setPage(0);
    refetch();
  };

  if (defaultQueryLoading) return <></>;

  return (
    <>
      <Container fluid>
        <Row style={{ flexWrap: "nowrap" }}>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">연동 상품 목록</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElements}</h5>
              </Col>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "620px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12} className="my-3">
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">가맹점명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={(e) => {
                            setStoreName(e.target.value);
                          }}
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} className="my-3">
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">판매채널 </h5>
                      </Col>
                      <Col>
                        <Form
                          className="form-radio"
                          onChange={(e) => setSellChannel(e.target.value)}
                        >
                          {["radio"].map((type) => (
                            <div key={`inline-${type}`} className="radiobox">
                              <Form.Check
                                defaultChecked
                                inline
                                label="전체"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-1`}
                                value=""
                              />
                              <Form.Check
                                inline
                                label="선불카드"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-2`}
                                value="PREPAID_CARD"
                              />
                              <Form.Check
                                inline
                                label="키오스크"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-3`}
                                value="KIOSK"
                              />
                              <Form.Check
                                inline
                                label="E커머스"
                                name="Filter-1"
                                type={type}
                                id={`inline-${type}-4`}
                                value="E_COMMERCE "
                              />
                            </div>
                          ))}
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={"auto"}>
                    <Row className="align-items-center justify-content-center filter-btnbox">
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="조회 하기"
                          // onClick={() => inquire()}
                          onClick={() => onClickSearch()}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{
                  verticalAlign: "middle",
                }}
                className="mb-4 text-center"
                responsive
              >
                <thead>
                  <tr>
                    <th>chainId</th>
                    <th>판매채널</th>
                    <th>가맹점</th>
                    <th>productId</th>
                    <th>상품명</th>
                    <th>구분</th>
                    <th>
                      유효기간 <br />
                      추가일
                    </th>
                    <th>
                      횟수권 일때 <br /> 횟수
                    </th>
                    <th>적립사용여부</th>
                  </tr>
                </thead>
                <tbody className="text-center" style={{ position: "relative" }}>
                  <ProductChainBoard data={data} />
                </tbody>
              </Table>
              <NewAdminPagination
                pageNationSize={10}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
