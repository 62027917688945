import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";

const TableRow = ({
  onClickManage = () => {},
  managerId,
  kioskId,
  kioskLocation,
  installDate,
  installManager,
  kioskStatus,
  tableType,
  onClickCheck = () => {},
  checked,
}) => {
  const statusDisplay = kioskStatus
    ? kioskStatus === "OPEN"
      ? "운영중"
      : "운영안함"
    : "-";
  return (
    <tr className="board-row-except">
      {tableType === "delete" && (
        <td>
          <Form.Check
            type="checkbox"
            checked={checked}
            onChange={onClickCheck}
          />
        </td>
      )}
      <td>{managerId ?? "-"}</td>
      <td>{kioskId ?? "-"}</td>
      <td>{kioskLocation ?? "-"}</td>
      <td>{installDate ? moment(installDate).format("YYYY-MM-DD") : "-"}</td>
      <td>{installManager ?? "-"}</td>
      <td>{statusDisplay}</td>
      {tableType === "default" && (
        <td>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              e.stopPropagation();
              onClickManage();
            }}
            style={{ wordBreak: "keep-all" }}
          >
            관리
          </button>
        </td>
      )}
    </tr>
  );
};

const KioskBoard = ({
  data,
  onClickManage = () => {},
  tableType,
  onClickCheck = () => {},
  checked,
}) => {
  return data.map((row) => (
    <TableRow
      key={row.id}
      {...row}
      onClickManage={() => onClickManage(row.id)}
      tableType={tableType}
      onClickCheck={() => onClickCheck(row.id, row.kioskStatus)}
      checked={checked(row.id)}
    />
  ));
};

export default KioskBoard;
