import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {queryKeys} from "../../../reactQuery/constants";
import {apiClient} from "../../../reactQuery/api";
import {Col, Container, Form, Row, Table} from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import Btn from "../../../components/admin/Btn";
import NewAdminPagination from "../../../components/admin/NewPagination";
import ProductLogBoard from "../../../components/product/ProductLogBoard";
import adminApi from "../../../function/apiConfig";


export default function ProductLog() {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const [data, setData] = useState([]);

  const [checked, setChecked] = useState(undefined);
  const [storeName, setStoreName] = useState(undefined);
  const [productId, setProductId] = useState(undefined);
  const [checkedIdSet, setCheckedIdSet] = useState(new Set([]));

  const { data: defaultQueryData, refetch } = useQuery(
      [queryKeys.productLog, page],
      () =>
          apiClient({
            url: "/product-logs",
            params: {
              page,
              size,
              ...(checked && { checked }),
              ...(storeName && { storeName }),
              ...(productId && { productId }),
            },
          }),
      {
        onSuccess: (res) => {},
        onError: (err) => alert(err.message),
        enabled: false,
        suspense: false,
        keepPreviousData: true,
        refetchOnMount: "always",
        refetchOnReconnect: "always",
        refetchOnWindowFocus: "always",
      }
  );

  useEffect(() => {
    refetch();
  }, [page]);


  useEffect(() => {
    if (defaultQueryData?.data?.empty) {
      setPage(0);
    }

    if (defaultQueryData?.data?.totalPages) {
      setTotalPages(defaultQueryData.data.totalPages);
    } else {
      setTotalPages(1);
    }

    if (defaultQueryData?.data?.totalElements) {
      setTotalElements(defaultQueryData.data.totalElements);
    } else {
      setTotalElements(0);
    }

    if (defaultQueryData?.data?.content?.length > 0) {
      setData(defaultQueryData.data.content);
    } else {
      setData([]);
    }
  }, [defaultQueryData]);

  const onClickSearch = () => {
    setPage(0);
    refetch();
  };

  function checkConfirmAlert(){
    if(checkedIdSet.size==0){
      alert("확인 완료 처리건들을 선택해주세요");
    }else{
      let checked;

      checked = confirm('확인 완료 처리 하시겠습니까?');
      if (!checked) return;

      adminApi({
        url: "/product-logs",
        data: {
          logIds: Array.from(checkedIdSet),
        },
        method: "post",
      }).then(function (response) {
        let message = "완료되었습니다.\n";
        message += "요청 건수 :"+response.countOfRequest+"\n";
        message += "성공 건수 :"+response.countOfSuccess+"\n";
        alert(message)
        setCheckedIdSet(new Set())
        refetch()
      })
    }

  }

  return (
    <>
      <Container fluid>
        <Row style={{ flexWrap: "nowrap" }}>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">상품 변동 내역</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElements}</h5>
              </Col>
              <Row className="p-1 justify-content-end">

                <Col xs={"auto"} className="btn-black">
                  <Btn
                      type="API"
                      btntext="확인완료"
                      onClick={() => checkConfirmAlert()}
                  />
                </Col>
              </Row>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "620px" }} className="mx-auto">
              <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12} className="my-3">
                  <Row className="align-items-center Formbox">
                    <Col xs={"auto"}>
                      <h5 className="mb-0 fw-bold">가맹점명</h5>
                    </Col>
                    <Col>
                      <Form.Control
                          onChange={
                            (e) =>{setStoreName( e.target.value)}

                          }
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                          autoComplete="off"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="my-3">
                  <Row className="align-items-center Formbox">
                    <Col xs={"auto"}>
                      <h5 className="mb-0 fw-bold">상품 id</h5>
                    </Col>
                    <Col>
                      <Form.Control
                          onChange={
                            (e) =>{setProductId( e.target.value)}

                          }
                          type="text"
                          id="productId"
                          aria-describedby="passwordHelpBlock"
                          autoComplete="off"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="my-3">
                  <Row className="align-items-center">
                    <Col xs={"auto"}>
                      <h5 className="mb-0 fw-bold">확인 유무 </h5>
                    </Col>
                    <Col>
                      <Form
                          className="form-radio"
                          onChange={(e) =>
                              setChecked( e.target.value)
                          }
                      >
                        {["radio"].map((type) => (
                            <div key={`inline-${type}`} className="radiobox">
                              <Form.Check
                                  defaultChecked
                                  inline
                                  label="전체"
                                  name="Filter-1"
                                  type={type}
                                  id={`inline-link-${type}-1`}
                                  value=""
                              />
                              <Form.Check
                                  inline
                                  label="미확인"
                                  name="Filter-1"
                                  type={type}
                                  id={`inline-link-${type}-2`}
                                  value="false"
                              />
                              <Form.Check
                                  inline
                                  label="확인완료"
                                  name="Filter-1"
                                  type={type}
                                  id={`inline-link-${type}-3`}
                                  value="true"
                              />
                            </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} lg={"auto"}>
                  <Row className="align-items-center justify-content-center filter-btnbox">
                    <Col xs={"auto"}>
                      <Btn
                          type="API"
                          btntext="조회 하기"
                          // onClick={() => inquire()}
                          onClick={() => onClickSearch()}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              </div>
            </div>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{
                  verticalAlign: "middle",
                }}
                className="mb-4 text-center"
                responsive
              >
                <thead>
                  <tr>
                    <th>선택</th>
                    <th>productLogId</th>
                    <th>productId</th>
                    <th>구분</th>
                    <th>가맹점</th>
                    <th>상품명</th>
                    <th>종류</th>
                    <th>액면가</th>
                    <th>판매가</th>
                    <th>변경일시</th>
                    <th>확인여부</th>
                    <th>확인자</th>
                    <th>확인시간</th>
                  </tr>
                </thead>
                <tbody className="text-center" style={{ position: "relative" }}>
                  <ProductLogBoard data={data} refetch={refetch} checkedIdSet={checkedIdSet}
                                   setCheckedIdSet={setCheckedIdSet}/>
                </tbody>
              </Table>
              <NewAdminPagination
                  pageNationSize={10}
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
