import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../reactQuery/api";

const downloadExcel = (response) => {
  const blob = new Blob([response.data]);
  const fileUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = fileUrl;
  link.style.display = "none";
  link.download = getFileName(response);

  document.body.appendChild(link);

  link.click();
  link.remove();
};

const getFileName = (res) => {
  const disposition = res.headers["content-disposition"];
  const fileName = decodeURI(
    disposition
      .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
      .replace(/['"]/g, "")
  );

  return fileName;
};

export function useGetExcelDownload() {
  const { mutate: mutateGetExcel, isLoading: isGetExcelLoading } = useMutation(
    async ({ url, params }) =>
      await apiClient({
        url,
        params,
        method: "get",
        responseType: "arraybuffer",
      }),
    {
      enabled: false,
      onSuccess: (res) => {
        downloadExcel(res);
      },
      onError: async ({ response }) => {
        const blob = new Blob([response.data]);
        const { message } = JSON.parse(await blob.text());

        alert(message);
      },
    }
  );

  return { mutateGetExcel, isGetExcelLoading };
}
