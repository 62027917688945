import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../reactQuery/constants";
import { transformYYYYMMDD } from "../../function/date";
import { apiClient } from "../../reactQuery/api";

const PAGE_SIZE = 10;

export default function useSmartStores({
  showingDate,
  page,
  setTotalPage,
  setTotalElements,
  type,
}) {
  const { data: smartStores } = useQuery(
    [
      queryKeys.smartStore,
      transformYYYYMMDD(showingDate.start),
      transformYYYYMMDD(showingDate.end),
      String(page),
    ],
    async () => {
      const { data } = await apiClient({
        url: `/smart-store/${type ? type + "-" : ""}orders`,
        params: {
          startDate: transformYYYYMMDD(showingDate.start),
          endDate: transformYYYYMMDD(showingDate.end),
          page,
          size: PAGE_SIZE,
        },
        method: "get",
      });

      return data;
    },
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const { totalPages, totalElements } = data;
        setTotalPage(totalPages);
        setTotalElements(totalElements);
      },
    }
  );

  return { smartStores };
}
