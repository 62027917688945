
import ProductCommerceBoard from "../../../components/product/ProductCommerceBoard";
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {queryKeys} from "../../../reactQuery/constants";
import {apiClient} from "../../../reactQuery/api";
import {Col, Container, Row, Form, Table} from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import Btn from "../../../components/admin/Btn";
import NewAdminPagination from "../../../components/admin/NewPagination";


export default function ProductCommerce() {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const [data, setData] = useState([]);

  const [paymentChannel, setPaymentChannel] = useState(undefined);
  const [linkStatus, setLinkStatus] = useState(undefined);
  const [storeName, setStoreName] = useState(undefined);

  const { data: defaultQueryData, refetch } = useQuery(
      [queryKeys.productCommerce, page],
      () =>
          apiClient({
            url: "/commerce-products",
            params: {
              page,
              size,
              ...(paymentChannel && { paymentChannel }),
              ...(storeName && { storeName }),
              ...(linkStatus && { linkStatus }),
            },
          }),
      {
        onSuccess: (res) => {},
        onError: (err) => alert(err.message),
        enabled: false,
        suspense: false,
        keepPreviousData: true,
        refetchOnMount: "always",
        refetchOnReconnect: "always",
        refetchOnWindowFocus: "always",
      }
  );

  useEffect(() => {
    refetch();
  }, [page]);


  useEffect(() => {
    if (defaultQueryData?.data?.empty) {
      setPage(0);
    }

    if (defaultQueryData?.data?.totalPages) {
      setTotalPages(defaultQueryData.data.totalPages);
    } else {
      setTotalPages(1);
    }

    if (defaultQueryData?.data?.totalElements) {
      setTotalElements(defaultQueryData.data.totalElements);
    } else {
      setTotalElements(0);
    }

    if (defaultQueryData?.data?.content?.length > 0) {
      setData(defaultQueryData.data.content);
    } else {
      setData([]);
    }
  }, [defaultQueryData]);

  const onClickSearch = () => {
    setPage(0);
    refetch();
  };

  return (
    <>
      <Container fluid>
        <Row style={{ flexWrap: "nowrap" }}>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">커머스 연동 목록</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElements}</h5>
              </Col>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "620px" }} className="mx-auto">
              <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12} className="my-3">
                  <Row className="align-items-center Formbox">
                    <Col xs={"auto"}>
                      <h5 className="mb-0 fw-bold">가맹점명</h5>
                    </Col>
                    <Col>
                      <Form.Control
                          onChange={
                            (e) =>{setStoreName( e.target.value)}

                          }
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                          autoComplete="off"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="my-3">
                    <Row className="align-items-center">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">판매채널 </h5>
                      </Col>
                      <Col>
                        <Form
                            className="form-radio"
                            onChange={(e) =>
                                setPaymentChannel( e.target.value)
                            }
                        >
                          {["radio"].map((type) => (
                              <div key={`inline-${type}`} className="radiobox">
                                <Form.Check
                                    defaultChecked
                                    inline
                                    label="전체"
                                    name="Filter-1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    value=""
                                />
                                <Form.Check
                                    inline
                                    label="플렉스데이"
                                    name="Filter-1"
                                    type={type}
                                    id={`inline-${type}-2`}
                                    value="FLEX_DAY"
                                />
                                <Form.Check
                                    inline
                                    label="스마트스토어"
                                    name="Filter-1"
                                    type={type}
                                    id={`inline-${type}-3`}
                                    value="SMART_STORE"
                                />
                              </div>
                          ))}
                        </Form>
                      </Col>
                    </Row>
                </Col>
                <Col xs={12} className="my-3">
                  <Row className="align-items-center">
                    <Col xs={"auto"}>
                      <h5 className="mb-0 fw-bold">연동상태 </h5>
                    </Col>
                    <Col>
                      <Form
                          className="form-radio"
                          onChange={(e) =>
                              setLinkStatus( e.target.value)
                          }
                      >
                        {["radio"].map((type) => (
                            <div key={`inline-${type}`} className="radiobox">
                              <Form.Check
                                  defaultChecked
                                  inline
                                  label="전체"
                                  name="Filter-1"
                                  type={type}
                                  id={`inline-link-${type}-1`}
                                  value=""
                              />
                              <Form.Check
                                  inline
                                  label="연동진행중"
                                  name="Filter-1"
                                  type={type}
                                  id={`inline-link-${type}-2`}
                                  value="LINKING"
                              />
                              <Form.Check
                                  inline
                                  label="연동완료"
                                  name="Filter-1"
                                  type={type}
                                  id={`inline-link-${type}-3`}
                                  value="LINKED"
                              />
                              <Form.Check
                                  inline
                                  label="거절"
                                  name="Filter-1"
                                  type={type}
                                  id={`inline-link-${type}-3`}
                                  value="REJECTED"
                              />
                              <Form.Check
                                  inline
                                  label="연동중지"
                                  name="Filter-1"
                                  type={type}
                                  id={`inline-link-${type}-3`}
                                  value="STOPPED"
                              />
                            </div>
                        ))}
                      </Form>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} lg={"auto"}>
                  <Row className="align-items-center justify-content-center filter-btnbox">
                    <Col xs={"auto"}>
                      <Btn
                          type="API"
                          btntext="조회 하기"
                          // onClick={() => inquire()}
                          onClick={() => onClickSearch()}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              </div>
            </div>

            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{
                  verticalAlign: "middle",
                }}
                className="mb-4 text-center"
                responsive
              >
                <thead>
                  <tr>
                    <th>commerceId</th>
                    <th>연동 commerce</th>
                    <th>상태</th>
                    <th>가맹점</th>
                    <th>productId</th>
                    <th>상품명</th>
                    <th>구분</th>
                    <th>연동하기</th>
                    <th>중지하기</th>
                  </tr>
                </thead>
                <tbody className="text-center" style={{ position: "relative" }}>
                  <ProductCommerceBoard data={data} refetch={refetch}/>
                </tbody>
              </Table>
              <NewAdminPagination
                  pageNationSize={10}
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
