import { usePaymentDetail } from "../../hooks/payment/usePaymentDetail";
import { Col, Form, Row } from "react-bootstrap";

export default function PaymentDetailDesc({ orderNumber }) {
  // const { payment } = usePaymentDetail({ orderNumber });
  //   const {} = payment;

  return (
    <div className="ContentBox Formbox mt-4">
      <Row className="p-1">
        <Col xs={{ span: 4 }} className="pb-4">
          <Form.Group className="xs-12" controlId="storeName">
            <Form.Label>
              발행 매장 이름 <span className="PointText"> * </span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="매장명을 입력하세요."
              //   value={data.storeName}
              readOnly={true}
            />
          </Form.Group>
        </Col>
        <Col xs={{ span: 4 }} className="pb-4">
          <Form.Group className="xs-12" controlId="status">
            <Form.Label>
              쿠폰 상태 <span className="PointText">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="매장명을 입력하세요."
              //   value={data.status.description}
              readOnly={true}
            />
          </Form.Group>
        </Col>
        <Col xs={{ span: 4 }} className="pb-4">
          <Form.Group className="xs-12" controlId="acceptStatus">
            <Form.Label>
              상품 이름 <span className="PointText">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="매장명을 입력하세요."
              //   value={data.productName}
              readOnly={true}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="p-1">
        {/* 3개형 */}
        <Col xs={{ span: 6 }} className="pb-4">
          <Form.Group className="xs-12" controlId="storePhoneNumber">
            <Form.Label>
              발행가격 <span className="PointText">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="매장명을 입력하세요."
              //   value={data.priceInfo.storeSalesPrice}
              readOnly={true}
            />
          </Form.Group>
        </Col>
        <Col xs={{ span: 6 }} className="pb-4">
          <Form.Group className="xs-12" controlId="storeName">
            <Form.Label>
              결제가격 <span className="PointText">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="매장명을 입력하세요."
              //   value={data.price}
              readOnly={true}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="p-1">
        <Col xs={{ span: 4 }} className="pb-5">
          <Form.Group className="xs-12" controlId="acceptStatus">
            <Form.Label>
              발행일시 <span className="PointText">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="매장명을 입력하세요."
              //   value={data.issuedDate}
              readOnly={true}
            />
          </Form.Group>
        </Col>
        <Col xs={{ span: 4 }} className="pb-5">
          <Form.Group className="xs-12" controlId="ownerName">
            <Form.Label>
              구매자 <span className="PointText">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              //   value={data.buyerName}
              readOnly={true}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
}
