import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useCoupons } from "../../hooks/coupon/useCoupons";

export default function CouponBoard({
  isEnabled,
  setIsEnabled,
  setTotalElement,
  setTotalPage,
  searchState,
  page,
}) {
  const { coupons } = useCoupons(isEnabled, setIsEnabled, searchState, page);

  useEffect(() => {
    setTotalElement(coupons.content.length);
    setTotalPage(coupons.totalPages);
  }, [setTotalElement, setTotalPage, coupons]);

  const mapedCouponBoard = coupons.content.map((coupon) => {
    const {
      couponId,
      storeName,
      paymentChannel,
      status,
      buyerName,
      couponNumber,
      facePrice,
      price,
      productName,
      issuedDate,
        productType,
        balance,
        priceInfo={
          storeSalesPrice:"",
            storeSettlePrice:"",
            fee:""
        },
    } = coupon;

    return (
      <tr key={couponId} className="board-row-except">
        <td>
          <Link to={`/admin/coupon/coupons/${couponId}`}>{couponId}</Link>
        </td>
        <td>{couponNumber}</td>
        <td>{storeName}</td>
        <td>{paymentChannel?.name}</td>
        <td>{status.name}</td>
        <td>{productType?.description}</td>
        {/*<td>{buyerName}</td>*/}
        {/* <td>
          <Link to={"/admin/coupon/coupons/" + couponId}>{couponNumber}</Link>
        </td> */}
        <td>{facePrice}</td>
        <td>{balance}</td>
        <td>{priceInfo?.storeSalesPrice}</td>
        <td>{priceInfo?.storeSettlePrice}</td>
        <td>{priceInfo?.fee}</td>
        {/*<td>{price}</td>*/}
        <td>{productName}</td>
        <td>{issuedDate}</td>
      </tr>
    );
  });

  return mapedCouponBoard;
}
