import axios from "axios";

const adminApi = axios.create({ baseURL: process.env.REACT_APP_API });

adminApi.interceptors.request.use(
  function (config) {
    setContentType(config);
    setAccessToken(config);
    checkCustomConfig(config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

adminApi.interceptors.response.use(
  function (response) {
    if (response.config.customOption.fileDownLoad) {
      return response;
    }

    return response.data;
  },
  function (error) {
    errorPopup(error);
    return Promise.reject(error);
  }
);

const defaultCustomOption = { errorPopup: true, fileDownLoad: false };
function checkCustomConfig(config) {
  config.customOption = Object.assign(
    {},
    defaultCustomOption,
    config.customOption
  );
}

function setAccessToken(config) {
  config.headers["Authorization"] =
    "Bearer " + localStorage.getItem("accessToken");
}

function errorPopup(error) {
  if (error.config.customOption.errorPopup) {
    if (error.config.customOption.fileDownLoad) {
      const data = JSON.parse(
        new TextDecoder("utf-8").decode(error.response.data)
      );
      alert("[오류] :" + data.message);
    } else {
      console.log(error.response.data);
      if (error.response.data.errorDetails) {
        alert(
          "[오류] :" +
            error.response.data.message +
            "\n" +
            error.response.data.errorDetails
              .map((x) => "-field: " + x.field + " 이유: " + x.reason)
              .join("\n")
        );
      } else {
        alert("[오류] :" + error.response.data.message);
      }
    }
  }
}

function setContentType(config) {
  config.headers["Content-Type"] = "application/json;";
}

export default adminApi;
