import axios from "axios";

const serverUrl = "http://118.67.142.31:8090"; //Server URL 변경

let axiosInstance = axios.create({
  // headers: {
  // 	'Access-Control-Allow-Origin': '*',
  // 	'Access-Control-Allow-Headers': 'Content-Type',
  // 	'Content-Type': 'application/json',
  //     'access_token': cookies.get('access_token'), //토큰값 발급으로 변경
  // 	// 등등의 header
  // },
  baseURL: serverUrl, // baseUrl
});

export const alertError = (error) => {
  if (axios.isAxiosError(error)) {
    if (error?.message?.response?.data?.errorDetails?.[0]?.reason) {
      alert(error.message.response.data.errorDetails[0].reason);
      return;
    }
    if (error?.message?.response?.data?.message) {
      alert(error.message.response.data.message);
      return;
    }
    if (error?.response?.data?.message) {
      alert(error.response.data.message);
      return;
    }
    alert(error);
  } else {
    alert(error);
  }
};

export const getErrorMessage = (error) => {
  if (axios.isAxiosError(error)) {
    if (error?.response?.data?.errorDetails?.[0]?.reason) {
      return error.response.data.errorDetails[0].reason;
    }
    if (error?.message?.response?.data?.errorDetails?.[0]?.reason) {
      return error.message.response.data.errorDetails[0].reason;
    }
    if (error?.message?.response?.data?.message) {
      return error.message.response.data.message;
    }
    if (error?.response?.data?.message) {
      return error.response.data.message;
    }
    return "";
  } else {
    return "";
  }
};

export default axiosInstance;
