import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../reactQuery/api";

export function useStores({ searchState, page, onSuccessCallback }) {
  const {
    storeName,
    ownerName,
    storeStatus,
    storeAcceptStatus,
    billingType,
    billingSubjectType,
    billingStatus,
  } = searchState;

  const { data: stores, refetch } = useQuery(
    [
      "stores",
      storeName,
      ownerName,
      storeStatus,
      storeAcceptStatus,
      billingType,
      billingSubjectType,
      billingStatus,
      String(page),
    ],
    async () => {
      const { data } = await apiClient({
        url: "/stores",
        method: "get",
        params: {
          ...(storeName && { storeName }),
          ...(ownerName && { ownerName }),
          ...(storeStatus && { storeStatus }),
          ...(storeAcceptStatus && { storeAcceptStatus }),
          ...(billingType && { billingType }),
          ...(billingSubjectType && { billingSubjectType }),
          ...(billingStatus && { billingStatus }),
          page,
        },
      });

      return data;
    },
    {
      keepPreviousData: false,
      onSuccess: (res) => {
        if (onSuccessCallback) {
          onSuccessCallback(res);
        }
      },
    }
  );

  return { stores, refetch };
}
