import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import adminApi from "../../function/apiConfig";
import { isProduction } from "../../function/common";

export default function ConnectStoreBoard({ stores, refetch, onDisconnect }) {
  const acceptStore = (storeId, domain) => {
    adminApi
      .post("/stores/" + storeId + "/examine", {
        acceptStatus: "ACCEPTED",
        domain: domain,
      })
      .then(function () {
        alert(`승인에 성공 하였습니다.`);
        refetch();
      });
  };

  const acceptStoreSettlement = (storeId) => {
    adminApi
      .post("/stores/" + storeId + "/store-settlement", {
        approveStatus: "ACCEPTED",
      })
      .then(function () {
        alert(`승인에 성공 하였습니다.`);
        refetch();
      });
  };

  const linkPopbill = (storeId) => {
    adminApi.post("/stores/" + storeId + "/cash-receipt").then(function () {
      alert(`승인에 성공 하였습니다.`);
      refetch();
    });
  };

  const checkDomain = (domain) => {
    const regex = /^[a-zA-Z0-9]*$/;
    if (!regex.test(domain)) {
      return false;
    } else {
      return true;
    }
  };
  const clickApprove = (storeId) => {
    const _domain = prompt(
      "승인 후 사용할 가맹점 도메인을 입력해주세요 (영어 또는 숫자만) \n ex) 웨이브온 -> waveoncoffee"
    );
    if (!_domain || !checkDomain(_domain)) {
      return null;
    }
    acceptStore(storeId, _domain);
  };

  const clickLinkPopbill = (storeId) => {
    // eslint-disable-next-line no-restricted-globals
    const _confirm = confirm("연동 하시겠습니까?");
    if (!_confirm) {
      return null;
    }
    linkPopbill(storeId);
  };

  const clickSettle = (storeId) => {
    // eslint-disable-next-line no-restricted-globals
    const _confirm = confirm(
      "정산계좌 등록을 수락 하시겠습니까? \n수락하기전에 팝빌연동을 먼저 진행해주세요!!!"
    );
    if (!_confirm) {
      return null;
    }
    acceptStoreSettlement(storeId);
  };

  function renderStoreAcceptByStatus(acceptStatus, storeId) {
    if (acceptStatus.code === 1) {
      return (
        <td>
          <Button
            onClick={() => {
              clickApprove(storeId);
            }}
            variant="outline-secondary"
          >
            승인하기
          </Button>
        </td>
      );
    } else {
      return <td style={{ minWidth: "5rem" }}>{acceptStatus.description}</td>;
    }
  }

  function renderPopbillByStatus(isLinkedPopbill, storeId) {
    if (isLinkedPopbill) {
      return <td> 연동완료</td>;
    } else {
      return (
        <td>
          <Button
            onClick={() => {
              clickLinkPopbill(storeId);
            }}
            variant="outline-secondary"
          >
            연동하기
          </Button>
        </td>
      );
    }
  }

  function renderSettleByStatus(status, storeId) {
    if (status && status.code === 1) {
      return (
        <td>
          <Button
            onClick={() => {
              clickSettle(storeId);
            }}
            variant="outline-secondary"
          >
            수락 하기
          </Button>
        </td>
      );
    } else if (status) {
      return <td> {status.description}</td>;
    } else {
      return <td> 작성 중</td>;
    }
  }

  function renderDisconnectBtn(onDisconnect) {
    return (
      <td>
        <Button onClick={onDisconnect} variant="outline-secondary">
          해제 하기
        </Button>
      </td>
    );
  }

  const mapedStores = stores?.content.map((store) => {
    const {
      id,
      storeName,
      ownerName,
      status,
      acceptStatus,
      logoUrl,
      domain,
      createdAt,
      isLinkedPopbill,
      settleApproveStatus,
    } = store;

    return (
      <tr key={id} className="board-row-except">
        <td
          style={{
            maxWidth: "4rem",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {id}
        </td>
        <td
          style={{
            maxWidth: "4rem",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          <Link to={"/admin/store/stores/" + id}>{storeName}</Link>
        </td>
        <td
          style={{
            maxWidth: "4rem",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {ownerName}
        </td>
        <td>{status.name}</td>
        {renderStoreAcceptByStatus(acceptStatus, id)}
        {renderSettleByStatus(settleApproveStatus, id)}
        {renderPopbillByStatus(isLinkedPopbill, id)}
        <td>
          <a
            href={`${process.env.REACT_APP_USER_URL}admin/product/products?storeId=${id}`}
            target="_blank"
            rel="noreferrer"
          >
            바로가기
          </a>
        </td>
        <td style={{ maxWidth: "16rem", wordBreak: "break-all" }}>
          <a
            href={`${process.env.REACT_APP_USER_URL}user/${domain}`}
            target="_blank"
            rel="noreferrer"
          >
            바로가기
          </a>
        </td>
        <td>{createdAt.replace("T", " ").substring(0, 16)}</td>
        {renderDisconnectBtn(() => {
          onDisconnect({
            storeUserId: isProduction() ? 243 : 81,
            storeId: parseInt(id),
          });
        })}
      </tr>
    );
  });

  return mapedStores;
}
