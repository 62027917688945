import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../reactQuery/api";
import { isProduction } from "../../function/common";

export function useConnectStores({ page }) {
  const { data: stores, refetch } = useQuery(
    ["connect", "stores", String(page)],
    async () => {
      const { data } = await apiClient({
        url: `/stores/connect/${isProduction() ? 243 : 81}`,
        method: "get",
        params: {
          page,
        },
      });

      return data;
    },
    {
      keepPreviousData: false,
    }
  );

  return { stores, refetch };
}
