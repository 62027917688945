import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import LeftMenu from "../../../components/admin/LeftMenu";
import { useNavigate } from "react-router-dom";
import TextEditor from "../../../components/admin/TextEditor";
import { apiClient } from "../../../reactQuery/api";
import { useMutation } from "@tanstack/react-query";

const CreateNotice = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");
  const [type, setType] = useState("STORE");

  const uploadImageMutation = useMutation(
    ({ image }) =>
      apiClient({
        url: "/notice-img",
        method: "post",
        data: {
          image,
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        if (!res) return;
        return res;
      },
    }
  );

  const createNoticeMutation = useMutation(
    async ({ title, contents, type }) =>
      await apiClient({
        url: `/notice`,
        method: "post",
        data: {
          title,
          contents,
          type,
        },
      }).then((res) => res.data),
    {
      onSuccess: (res) => {
        if (!res) return;
        alert("공지사항이 등록되었습니다.");
        navigate("/admin/notices", { replace: true });
      },
    }
  );

  const base64toFile = (base_data, filename) => {
    var arr = base_data.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    const ext = mime.split("/")[1];

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], `${filename}.${ext}`, { type: mime });
  };

  const replaceImageSrcs = async (fullString) => {
    const regex = /<img[^>]+src="([^">]+)"/g; // 이미지 태그를 찾는 정규 표현식
    const matches = [...fullString.matchAll(regex)]; // 이미지 태그에서 src 속성을 찾음

    for (const match of matches) {
      const oldSrc = match[1]; // 이미지 태그의 현재 src
      let imgSrc = oldSrc;

      try {
        if (!oldSrc.startsWith("http")) {
          const res = await uploadImageMutation.mutateAsync({
            image: base64toFile(oldSrc, "uploadImage"),
          });
          imgSrc = res;
        } else {
          imgSrc = oldSrc;
        }
      } catch (err) {
        imgSrc = oldSrc;
      }

      fullString = fullString.replace(oldSrc, imgSrc);
    }

    return fullString;
  };

  const onClickSave = async () => {
    if (createNoticeMutation.isLoading || uploadImageMutation.isLoading) return;
    if (!title) return alert("제목을 입력해주세요.");
    if (!contents) return alert("내용을 입력해주세요.");
    if (!type) return alert("노출 플랫폼을 선택해주세요.");

    try {
      const imgSrcReplacedContents = await replaceImageSrcs(contents);
      createNoticeMutation.mutate({
        title,
        contents: imgSrcReplacedContents,
        type,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={2} className="LeftBg px-0">
          <LeftMenu />
        </Col>
        <Col className="RightBg px-5">
          <Row className="pb-3 pt-4 row">
            <Col>
              <h1 className="fw-bold">공지사항 추가</h1>
            </Col>
          </Row>
          <Row className="pb-3 pt-4 row">
            <Col>
              <h4 className="fw-medium">제목</h4>
              <Form.Group className="Formbox">
                <Form.Control
                  placeholder="공지사항 제목을 입력해주세요"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="pb-3 pt-4 row">
            <Col>
              <h4 className="fw-medium">
                노출 플랫폼 <span>(현재는 가맹점만 가능)</span>
              </h4>
              <Form.Group className="Formbox d-flex gap-3">
                <Form.Check
                  style={{
                    width: "fit-content",
                  }}
                  type="radio"
                >
                  <Form.Check.Input
                    type="radio"
                    style={{
                      fontSize: "14px",
                      marginRight: "8px",
                    }}
                    value={"USER"}
                    checked={type === "USER"}
                    disabled
                    onChange={() => setType("USER")}
                  />
                  <Form.Label>사용자</Form.Label>
                </Form.Check>
                <Form.Check
                  style={{
                    width: "fit-content",
                  }}
                  type="radio"
                >
                  <Form.Check.Input
                    type="radio"
                    style={{
                      fontSize: "14px",
                      marginRight: "8px",
                    }}
                    value={"STORE"}
                    checked={type === "STORE"}
                    onChange={() => setType("STORE")}
                  />
                  <Form.Label>가맹점</Form.Label>
                </Form.Check>
              </Form.Group>
            </Col>
          </Row>
          <Row className="pb-3 pt-4 row">
            <Col>
              <h4 className="fw-medium">내용</h4>
              <Form.Group className="Formbox">
                <TextEditor value={contents} setValue={setContents} />
              </Form.Group>
            </Col>
          </Row>
          <Row
            className="py-5 pb-5 align-items-center justify-content-center"
            style={{ gap: "1.2rem" }}
          >
            <Col
              style={{
                flex: 0,
                flexShrink: 0,
                wordBreak: "keep-all",
                padding: 0,
              }}
            >
              <button
                type="button"
                className="btn btn-default"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(-1);
                }}
                style={{
                  padding: "4px 32px",
                }}
              >
                취소
              </button>
            </Col>
            <Col
              style={{
                flex: 0,
                flexShrink: 0,
                wordBreak: "keep-all",
                padding: 0,
              }}
            >
              <button
                type="submit"
                className="btn btn-default"
                style={{
                  padding: "4px 32px",
                  marginRight: "1rem",
                }}
                onClick={onClickSave}
              >
                저장
              </button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateNotice;
