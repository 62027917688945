import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../reactQuery/api";

const PAGE_SIZE = 10;

export default function useSimplePagination(
  queryKeys,
  url,
  page,
  setTotalPage,
  setTotalElement
) {
  const { data } = useQuery(
    queryKeys,
    async () => {
      const { data } = await apiClient({
        url,
        method: "get",
        params: {
          page,
          size: PAGE_SIZE,
        },
      });

      return data;
    },
    {
      staleTime: 0,
      onSuccess: (data) => {
        const { totalElements, totalPages } = data;

        setTotalElement(totalElements);
        setTotalPage(totalPages);
      },
    }
  );

  return { data };
}
