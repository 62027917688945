export const queryKeys = {
  users: "users",
  couponLogs: "couponLogs",
  couponPayments: "couponPayments",
  userCouponDetail: "userCouponDetail",
  payments: "payments",
  paymentDetail: "paymentDetail",
  admin: "admin",
  couponDetail: "couponDetail",
  smartStore: "smartStore",
  smartStoreDetail: "smartStoreDetail",
  productChain:"productChain",
  productCommerce:"productCommerce",
  productLog:"productLog",
};
