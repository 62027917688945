import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import Btn from "../../../components/admin/Btn";
import LeftMenu from "../../../components/admin/LeftMenu";
import NewAdminPagination from "../../../components/admin/NewPagination";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../reactQuery/api";
import moment from "moment";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";

export default function PaymentSummary() {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(10);
  const [data, setData] = useState([]);

  const [orderNumber, setOrderNumber] = useState(undefined);
  const [storeName, setStoreName] = useState(undefined);
  const [paymentChannel, setPaymentChannel] = useState(undefined);
  const [paymentStatus, setPaymentStatus] = useState(undefined);
  const [membershipName, setMembershipName] = useState("");
  const [membershipPhoneNumber, setMembershipPhoneNumber] = useState("");
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });

  const { data: defaultUsersQueryData, refetch } = useQuery(
    ["payment-summaries", page],
    () =>
      apiClient({
        url: "/payment-summaries",
        params: {
          page,
          size,
          ...(orderNumber && { orderNumber }),
          ...(storeName && { storeName }),
          ...(paymentChannel && { paymentChannel }),
          ...(paymentStatus && { paymentStatus }),
          ...(Boolean(membershipName) && { membershipName }),
          ...(Boolean(membershipPhoneNumber) && { membershipPhoneNumber }),
          ...(Boolean(date.startDate) && {
            startDate: moment(date.startDate).format("YYYY-MM-DD"),
          }),
          ...(Boolean(date.endDate) && {
            endDate: moment(date.endDate).format("YYYY-MM-DD"),
          }),
        },
      }),
    {
      onSuccess: (res) => {},
      onError: (err) => alert(err.message),
      enabled: false,
      suspense: false,
      keepPreviousData: true,
      refetchOnMount: "always",
      refetchOnReconnect: "always",
      refetchOnWindowFocus: "always",
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // useQuery의 onSuccess에서 처리하지 않고 useEffect 내에서 처리하는 이유는
  // Suspense 때문입니다!
  // 혹시 궁금하시다면, `https://velog.io/@kjwsx23/React-React-Query-%EC%99%80-setState`
  useEffect(() => {
    if (defaultUsersQueryData?.data?.empty) {
      setPage(0);
    }

    if (defaultUsersQueryData?.data?.totalPages) {
      setTotalPages(defaultUsersQueryData.data.totalPages);
    } else {
      setTotalPages(1);
    }

    if (defaultUsersQueryData?.data?.totalElements) {
      setTotalElements(defaultUsersQueryData.data.totalElements);
    } else {
      setTotalElements(0);
    }

    if (defaultUsersQueryData?.data?.content?.length > 0) {
      setData(defaultUsersQueryData.data.content);
    } else {
      setData([]);
    }
  }, [defaultUsersQueryData]);

  function renderCanceledAtByStatus(code, item) {
    return (
      <td>
        {item.canceledAt && moment(item.canceledAt).format("YY-MM-DD kk:mm")}
      </td>
    );
    // if(code==1){
    //   return <td><Button onClick={ () => { alert("확인")}} variant="outline-secondary" >
    //     취소하기
    //   </Button></td>
    // }else{
    //   return <td>{item.canceledAt && moment(item.canceledAt).format('YY-MM-DD kk:mm') }</td>
    // }
  }

  const onClickSearch = () => {
    setPage(0);
    refetch();
  };
  // if (isLoading) return <></>;

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={2} className="LeftBg px-0">
            <LeftMenu />
          </Col>
          <Col className="RightBg px-5">
            <Row className="pb-3 pt-4 row">
              <Col>
                <h1 className="fw-bold">통합 결제 내역</h1>
              </Col>
            </Row>

            <Row className="py-1 align-items-center">
              {/* 2개형 */}
              <Col
                xs={"auto"}
                className="px-3 py-1 ms-2 rounded-5 text-white"
                style={{ background: "#FF5864" }}
              >
                <h5 className="mb-0">Total</h5>
              </Col>
              <Col xs={{ span: 1 }}>
                <h5 className="mb-0">{totalElements}</h5>
              </Col>
            </Row>
            <div className="ContentBox TableStyle mt-4">
              <div style={{ maxWidth: "620px" }} className="mx-auto">
                <Row className="p-1 align-items-center justify-content-center g-4">
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">주문번호</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={(e) => {
                            setOrderNumber(e.target.value);
                          }}
                          type="text"
                          id="orderNumber"
                          aria-describedby="passwordHelpBlock"
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">가맹점명</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={(e) => {
                            setStoreName(e.target.value);
                          }}
                          type="text"
                          id="storeName"
                          aria-describedby="passwordHelpBlock"
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">회원이름</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={(e) => {
                            setMembershipName(e.target.value);
                          }}
                          type="text"
                          id="membershipName"
                          aria-describedby="passwordHelpBlock"
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="align-items-center Formbox">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">전화번호</h5>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            setMembershipPhoneNumber(e.target.value);
                          }}
                          type="text"
                          id="membershipPhoneNumber"
                          aria-describedby="passwordHelpBlock"
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="align-items-left">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">판매채널</h5>
                      </Col>
                      <Col
                        xs={"auto"}
                        className={
                          paymentChannel === undefined
                            ? "btn-dark"
                            : "btn-black"
                        }
                      >
                        <Button onClick={() => setPaymentChannel(undefined)}>
                          전체보기
                        </Button>
                      </Col>
                      <Col
                        xs={"auto"}
                        className={
                          paymentChannel === "FLEX_DAY"
                            ? "btn-dark"
                            : "btn-black"
                        }
                      >
                        <Button onClick={() => setPaymentChannel("FLEX_DAY")}>
                          플렉스데이
                        </Button>
                      </Col>
                      <Col
                        xs={"auto"}
                        className={
                          paymentChannel === "SMART_STORE"
                            ? "btn-dark"
                            : "btn-black"
                        }
                      >
                        <Button
                          onClick={() => setPaymentChannel("SMART_STORE")}
                        >
                          스마트스토어
                        </Button>
                      </Col>
                      <Col
                        xs={"auto"}
                        className={
                          paymentChannel === "KIOSK" ? "btn-dark" : "btn-black"
                        }
                      >
                        <Button onClick={() => setPaymentChannel("KIOSK")}>
                          키오스크
                        </Button>
                      </Col>
                      <Col
                        xs={"auto"}
                        className={
                          paymentChannel === "MEMBERSHIP"
                            ? "btn-dark"
                            : "btn-black"
                        }
                      >
                        <Button onClick={() => setPaymentChannel("MEMBERSHIP")}>
                          멤버십
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <Row className="align-items-left">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold">결제상태</h5>
                      </Col>
                      <Col
                        xs={"auto"}
                        className={
                          paymentStatus === undefined ? "btn-dark" : "btn-black"
                        }
                      >
                        <Button onClick={() => setPaymentStatus(undefined)}>
                          전체보기
                        </Button>
                      </Col>
                      <Col
                        xs={"auto"}
                        className={
                          paymentStatus === "PAID" ? "btn-dark" : "btn-black"
                        }
                      >
                        <Button onClick={() => setPaymentStatus("PAID")}>
                          결제완료
                        </Button>
                      </Col>
                      <Col
                        xs={"auto"}
                        className={
                          paymentStatus === "CANCELED"
                            ? "btn-dark"
                            : "btn-black"
                        }
                      >
                        <Button onClick={() => setPaymentStatus("CANCELED")}>
                          결제취소
                        </Button>
                      </Col>
                      <Col
                        xs={"auto"}
                        className={
                          paymentStatus === "ERROR" ? "btn-dark" : "btn-black"
                        }
                      >
                        <Button onClick={() => setPaymentStatus("ERROR")}>
                          에러
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={"auto"}>
                    <Row className="p-1 align-items-center justify-content-center mb-4">
                      <Col xs={"auto"}>
                        <h5 className="mb-0 fw-bold"> 발행일</h5>
                      </Col>
                      <Col xs={"auto"}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={date.startDate}
                          onChange={(date) => {
                            setDate((prev) => ({ ...prev, startDate: date }));
                          }}
                        />
                      </Col>
                      <Col xs={"auto"}>
                        <h5 className="mb-0"> ~ </h5>
                      </Col>
                      <Col xs={"auto"}>
                        <DatePicker
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          selected={date.endDate}
                          onChange={(date) =>
                            setDate((prev) => ({
                              ...prev,
                              endDate: date,
                            }))
                          }
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} lg={"auto"}>
                    <Row className="align-items-center justify-content-center filter-btnbox">
                      <Col xs={"auto"}>
                        <Btn
                          type="API"
                          btntext="조회 하기"
                          // onClick={() => inquire()}
                          onClick={() => onClickSearch()}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="ContentBox TableStyle mt-4">
              <Table
                style={{ verticalAlign: "middle" }}
                className="mb-4 text-center"
              >
                <thead>
                  <tr>
                    <th>id</th>
                    <th>가맹점명</th>
                    <th>판매채널</th>
                    <th>상태</th>
                    <th>E-쿠폰</th>
                    <th>주문명</th>
                    <th>주문자명</th>
                    <th>전화번호</th>
                    <th>주문번호</th>
                    <th>결제가격</th>
                    <th>수량</th>
                    <th>결제일시</th>
                    <th>취소일시</th>
                  </tr>
                </thead>
                <tbody>
                  {/* data 값으로 루프 */}
                  {data.map((item, index) => (
                    <tr key={item.summaryId}>
                      <td>{item.summaryId}</td>
                      <td>{item.storeName}</td>
                      <td>{item.paymentChannel.name}</td>
                      <td>{item.paymentStatus.description}</td>
                      <td>{item.productType?.description}</td>
                      <td>{item.orderName}</td>
                      <td>{item.membershipName ?? "-"}</td>
                      <td>{item.membershipPhoneNumber ?? "-"}</td>
                      <td>{item.orderNumber ?? "-"}</td>
                      <td>
                        {item.paymentPrice
                          ? item.paymentPrice.toLocaleString()
                          : "-"}
                      </td>
                      <td>{item.quantity}</td>
                      <td>{moment(item.paidAt).format("YY-MM-DD kk:mm")}</td>
                      {renderCanceledAtByStatus(item.paymentStatus.code, item)}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <NewAdminPagination
                pageNationSize={10}
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
