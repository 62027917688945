export function storeReducer(state, action) {
  switch (action.type) {
    case "changed_storeName":
      return {
        ...state,
        storeName: action.storeName,
      };
    case "changed_ownerName":
      return {
        ...state,
        ownerName: action.ownerName,
      };
    case "changed_status":
      return {
        ...state,
        storeStatus: action.storeStatus,
      };
    case "changed_acceptStatus":
      return {
        ...state,
        storeAcceptStatus: action.storeAcceptStatus,
      };
    case "changed_billingType":
      return {
        ...state,
        billingType: action.billingType,
      };
    case "changed_billingSubjectType":
      return {
        ...state,
        billingSubjectType: action.billingSubjectType,
      };
    case "changed_billingStatus":
      return {
        ...state,
        billingStatus: action.billingStatus,
      };
    default:
      throw Error(`Unknown action type: ${action.type}`);
  }
}
