import moment from "moment";
import React from "react";
import { Form } from "react-bootstrap";

const TableRow = ({
  onClickManage = () => {},
  id,
  title,
  contents,
  type,
  tableType,
  onClickCheck = () => {},
  checked,
  createdAt,
  updatedAt,
  viewCount,
}) => {
  return (
    <tr className="board-row-except">
      {tableType === "delete" && (
        <td>
          <Form.Check
            type="checkbox"
            checked={checked}
            onChange={onClickCheck}
          />
        </td>
      )}
      <td>{id ?? "-"}</td>
      <td
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          wordBreak: "keep-all",
          maxWidth: "160px",
        }}
      >
        {title ?? "-"}
      </td>
      <td
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          wordBreak: "keep-all",
          maxWidth: "280px",
        }}
      >
        {contents
          ? contents.replace(/<img[^>]*>/g, "[이미지]").replace(/<[^>]*>/g, "")
          : "-"}
      </td>
      <td>{type ?? "가맹점"}</td>
      <td>{createdAt ? moment(createdAt).format("YYYY.MM.DD HH:mm") : "-"}</td>
      <td>{updatedAt ? moment(updatedAt).format("YYYY.MM.DD HH:mm") : "-"}</td>
      <td>{viewCount ? viewCount : "-"}</td>
      {tableType === "default" && (
        <td>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              e.stopPropagation();
              onClickManage();
            }}
            style={{ wordBreak: "keep-all" }}
          >
            관리
          </button>
        </td>
      )}
    </tr>
  );
};

const NoticeBoard = ({
  data,
  onClickManage = () => {},
  tableType,
  onClickCheck = () => {},
  checked,
}) => {
  return data.map((row) => (
    <TableRow
      key={row.id}
      {...row}
      onClickManage={() => onClickManage(row.id)}
      tableType={tableType}
      onClickCheck={() => onClickCheck(row.id)}
      checked={checked(row.id)}
    />
  ));
};

export default NoticeBoard;
