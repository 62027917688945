import React, { useEffect, useState } from "react";
import RegisterModal from "./RegisterModal";
import { Button, Col, Row } from "react-bootstrap";

const TEMP_CATEGORY_LIST = Array.from({ length: 3 }, (_, i) => ({
  id: i,
  categoryName: "카테고리명" + i,
  description: "카테고리 설명" + i,
  countOfProduct: 0,
  priority: i,
  products: Array.from({ length: 2 }, (_, prdIndex) => ({
    productId: i + "0" + prdIndex,
  })),
}));

const KioskProductsManagement = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [categories, setCategories] = useState([]);

  const onClickSave = ({ categoryName, products }) => {
    console.log(categoryName, products);
  };

  useEffect(() => {
    setCategories(TEMP_CATEGORY_LIST);
  }, []);

  return (
    <div>
      <Col style={{ display: "flex", gap: "1rem" }}>
        <Button onClick={() => setShowRegisterModal(true)}>
          카테고리 등록
        </Button>
        <Button>카테고리 순서 편집</Button>
      </Col>
      <Col>
        {categories.map((category) => (
          <div>{category.categoryName}</div>
        ))}
      </Col>
      <RegisterModal
        show={showRegisterModal}
        closeModal={() => setShowRegisterModal(false)}
        onClickSave={onClickSave}
      />
    </div>
  );
};

export default KioskProductsManagement;
