import { useMemo, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize";
Quill.register("modules/ImageResize", ImageResize);

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote"],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ size: ["small", "large", "huge"] }],
  ["link", "image"],
];

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "align",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "background",
  "color",
  "link",
  "image",
  "video",
  "width",
];

const getBase64Url = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const TextEditor = ({
  setValue,
  value,
  customStyle,
  containerMarginBottom,
  multipleImage,
  minHeight = "50rem",
}) => {
  const quillRef = useRef();

  const modules = useMemo(
    () => ({
      toolbar: {
        container: toolbarOptions,
        handlers: {
          image: () => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");

            if (multipleImage) {
              input.setAttribute("multiple", true);
            }

            input.classList.add("ql-image");
            input.click();
            input.onchange = async () => {
              const files = input.files ? input.files : null;
              const quillObj = quillRef?.current?.getEditor();
              const range = quillObj?.getSelection();
              // const loadedImageLength = quillObj.editor.delta.ops
              //   .map((item) => item.insert.image)
              //   .filter((item) => item !== undefined).length;
              // let limit = 15;
              // if (loadedImageLength !== 0) {
              //   limit = 14 - loadedImageLength;
              // }
              if (files) {
                // let _files = Object.values(files);
                // if (files.length > limit) {
                //   alert("이미지는 최대 15개까지 등록 가능합니다.");
                //   _files = Object.values(files).slice(0, limit);
                // }

                for (let i = 0; i < files.length; i++) {
                  const file = files[i];
                  if (!file) continue;
                  const fileUrl = await getBase64Url(file);
                  quillObj.insertEmbed(range.index, "image", fileUrl);
                  quillObj.setSelection(range.index + 1);
                  // uploadImageMutation.mutate({
                  //   file,
                  //   callBack: (res) => {
                  //     quillObj.insertEmbed(range.index, "image", res);
                  //     quillObj.setSelection(range.index + 1);
                  //   },
                  // });
                  //   uploadToS3(file, Date.now() + "-" + file.name).then((url) => {
                  //     quillObj.insertEmbed(range.index, "image", url);
                  //     quillObj.setSelection(range.index + 1);
                  //   });
                }
              }
            };
          },
        },
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
      },
    }),
    [multipleImage]
  );

  return (
    <ReactQuill
      ref={quillRef}
      value={value}
      theme="snow"
      onChange={(e) => {
        if (e === "<p><br></p>") {
          setValue("");
          return;
        }
        setValue(e);
      }}
      modules={modules}
      formats={formats}
      style={{
        minHeight: `calc(${minHeight} - 4.2rem)`,
        height: `calc(${minHeight} - 4.2rem)`,
        marginLeft: 0,
        ...customStyle,
      }}
    />
  );
};

export default TextEditor;
