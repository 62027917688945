export function couponReducer(state, action) {
  switch (action.type) {
    case "changed_storeName":
      return {
        ...state,
        storeName: action.storeName,
      };
    case "changed_productName":
      return {
        ...state,
        productName: action.productName,
      };
    case "changed_status":
      return {
        ...state,
        status: action.status,
      };
    case "changed_startDate":
      return {
        ...state,
        issueDateStart: action.startDate,
      };
    case "changed_endDate":
      return {
        ...state,
        issueDateEnd: action.endDate,
      };
    case "changed_couponNumber":
      return {
        ...state,
        couponNumber: action.couponNumber,
      };
    default:
      return Error(`Unknown action type: ${action.type}`);
  }
}
